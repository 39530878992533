import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Autocomplete, Box, TextField } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Styled Components
const InputWrapper = styled(Box)({
	paddingTop: '5px',
	marginBottom: (props) =>
		props.customerror === 'false' ? '1rem !important' : '0px !important',

	'@media (max-width: 768px)': {
		marginBottom: (props) =>
			props.customerror === 'false' ? '1rem !important' : '0px !important',
	},
});

const CustomAutocomplete = styled(Autocomplete)({
	backgroundColor: '#fff',
	fontSize: '1rem',

	'& label.Mui-focused custom-input-label': {
		color: 'var(--secondary)',
		backgroundColor: '#fff',
		paddingRight: 5,
	},

	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'var(--secondary)',
			borderWidth: 2,
			borderRadius: 10,
			transition: '0.3s ease',
		},
		'&:hover fieldset': {
			borderColor: 'var(--secondary)',
		},
		'&.Mui-focused fieldset': {
			borderColor: 'var(--secondary)',
		},
	},

	'& . MuiListItemText-secondary': {
		display: 'none !important',
	},
});

const ErrorText = styled(Box)({
	fontWeight: '400',
	fontSize: '0.75rem',
	lineHeight: '1.66',
	letterSpacing: '0.03333em',
	textAlign: 'left',
	marginRight: '14px',
	marginBottom: (props) => (props.customerror === 'false' ? '0.45rem' : '0px'),
	marginLeft: '14px',
	color: 'var(--error)',
});

const CustomSelectInputSearch = (props) => {
	// Props destructuring
	const {
		error,
		errorText,
		handleChange,
		id,
		isDisabled,
		options,
		size,
		title,
		value,
	} = props;

	// Component functions
	const randomNumber = () => {
		return Math.floor(Math.random() * 100) + 1;
	};

	const getOptionLabel = (option) => (option ? option.label || '' : '');

	const selectedOption =
		options.find((option) => getOptionLabel(option) === value) || null;

	return (
		<InputWrapper customerror={error.toString()}>
			<CustomAutocomplete
				id={`${id}-${randomNumber()}`}
				options={options}
				customerror={error.toString()}
				onChange={(e, value) => value && handleChange(value)}
				autoHighlight
				getOptionLabel={getOptionLabel}
				value={selectedOption}
				disabled={isDisabled}
				renderOption={(props, option) => (
					<Box component='li' {...props} key={option.id}>
						{option.label}
					</Box>
				)}
				renderInput={(params) => (
					<>
						<TextField
							{...params}
							label={title}
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password',
							}}
							size={size}
							error={error}
						/>
					</>
				)}
			/>

			{error && (
				<ErrorText customerror={error.toString()}>{errorText}</ErrorText>
			)}
		</InputWrapper>
	);
};

CustomSelectInputSearch.defaultProps = {
	size: 'small',
	id: 'custom-select-input-search',
	error: false,
	errorText: '',
};

CustomSelectInputSearch.propTypes = {
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	id: PropTypes.string,
	isDisabled: PropTypes.bool,
	value: PropTypes.string,
	size: PropTypes.string,
};

export default CustomSelectInputSearch;
