import React from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// Local Components Imports
import AlertDialog from './AlertDialog';

// Redux Actions Imports
import { changeInnerRoute } from '../store/actions/innerRouterActions';
import { resetNoCountryConfiguration } from '../store/actions/transactionsActions';

const NoCountryConfiguration = (props) => {
	// Props destructuring
	const {
		changeInnerRoute,
		openAlertDialog,
		handleAlertClose,
		resetNoCountryConfiguration,
	} = props;

	const handleClose = () => {
		handleAlertClose();
		resetNoCountryConfiguration();
	};

	return (
		<AlertDialog
			dilogType={'transactions'}
			openDialog={openAlertDialog}
			onClose={handleClose}
			title={'Datos de usuario'}
			dialogInfo={
				'No contamos con operaciones en tu país de residencia, por favor actualiza tus datos para poder realizar operaciones.'
			}
			hasTwoBtns={false}
			primaryBtnTitle={'Actualizar datos'}
			primaryBtnIcon={'faCheck'}
			dilogIcon='faUserClock'
			handleConfirmation={() => {
				handleClose();
				changeInnerRoute('shippingAddress');
			}}
		/>
	);
};

NoCountryConfiguration.propTypes = {
	changeInnerRoute: PropTypes.func.isRequired,
	handleAlertClose: PropTypes.func.isRequired,
	resetNoCountryConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		resetNoCountryConfiguration: () => dispatch(resetNoCountryConfiguration()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NoCountryConfiguration);
