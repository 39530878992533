import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// Notifier
import { useSnackbar } from 'notistack';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomDialog from '../../../../../layout/CustomDialog';
import TwoBtnsGrid from '../../../../../shared/TwoBtnsGrid';
import SendMoneyStepOne from './SendMoneyStepOne';
import SendMoneyStepTwo from './SendMoneyStepTwo';
import SendMoneyStepThree from './SendMoneyStepThree';

// Component validation imports
import SendMoneyStepOneValidation from './Validation/SendMoneyStepOneValidation';
import SendMoneyStepTwoValidation from './Validation/SendMoneyStepTwoValidation';

// Redux Actions Imports
import {
	changeInnerRoute,
	clearInnerRoute,
} from '../../../../../store/actions/innerRouterActions';
import {
	chargeSendMoneyToCard,
	cleanRequestMoneyData,
	getRequestMoneyData,
	getUserAccount,
	getUserByEmail,
	getUserByPhoneNumber,
	getUserTransactions,
	sendMoneyIn,
	updateUserBalance,
} from '../../../../../store/actions/transactionsActions';
import SendMoneyStepFour from './SendMoneyStepFour';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
	minHeight: '12rem',
});

const SendMoney = (props) => {
	// Props destructuring
	const {
		balance,
		changeInnerRoute,
		cleanRequestMoneyData,
		clearInnerRoute,
		chargeSendMoneyToCard,
		configurationByCountry,
		getRequestMoneyData,
		getUserAccount,
		getUserByEmail,
		getUserByPhoneNumber,
		getUserToSendMoneyData,
		getUserToSendMoneyDataStatus,
		getUserTransactions,
		openDialog,
		requestMoneyData,
		requestMoneyDataCleaned,
		requestMoneyTokenOut,
		sendMoneyIn,
		setOpenDialog,
		updateUserBalance,
		userProfile,
	} = props;

	// Component state
	const [activeStep, setActiveStep] = useState(0);
	const [transactionData, setTransactionData] = useState({
		userIdTo: '',
		amount: '',
		comment: '',
	});
	const [aditionalData, setAditionalData] = useState({
		commission: '% 0.00',
		commissionValue: 0,
		commissionAmount: '$ 0.00',
		total: '$ 0.00',
	});
	const [searchUserBy, setSearchUserBy] = useState('phoneNumber');
	const [searchData, setSearchData] = useState({
		phoneNumber: '593',
	});
	const [searchByEmail, setSearchByEmail] = useState({
		email: '',
	});
	const [maxSend, setMaxSend] = useState(0);
	const [minSend, setMinSend] = useState(0);
	const [commissionConfig, setCommissionConfig] = useState(1);
	const [paymentMethod, setPaymentMethod] = useState('myBalance');
	const [savedCardDetails, setSavedCardDetails] = useState(null);

	const [errors, setErrors] = useState({});

	const totalSteps = [0, 1, 2, 3];

	// Component hooks
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (openDialog && configurationByCountry?.commissionDirection) {
			setMaxSend(configurationByCountry?.maxSend);
			setMinSend(configurationByCountry?.minSend);
			setCommissionConfig(configurationByCountry?.commissionDirection);

			setAditionalData({
				...aditionalData,
				commission: `% ${configurationByCountry?.percentTransfer?.toFixed(2)}`,
				commissionValue: configurationByCountry?.percentTransfer / 100,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationByCountry, openDialog, requestMoneyData]);

	useEffect(() => {
		if (requestMoneyTokenOut && !requestMoneyDataCleaned) {
			getRequestMoneyData({ qrToken: requestMoneyTokenOut });
		}
	}, [
		getRequestMoneyData,
		requestMoneyDataCleaned,
		requestMoneyTokenOut,
		setOpenDialog,
	]);

	useEffect(() => {
		if (Object.keys(requestMoneyData).length > 0) {
			setOpenDialog(true);
			setActiveStep(1);
		}
	}, [requestMoneyData, setOpenDialog]);

	useEffect(() => {
		if (getUserToSendMoneyDataStatus) {
			if (activeStep === 0) {
				setTransactionData({
					...transactionData,
					userIdTo: getUserToSendMoneyData.id.toString(),
				});
			}

			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		getUserToSendMoneyDataStatus,
		setTransactionData,
		getUserToSendMoneyData,
	]);

	const sendMoneySteps = [
		{
			title: 'Enviar dinero',
			content: (
				<Wrapper>
					<SendMoneyStepOne
						activeStep={activeStep}
						errors={errors}
						searchData={searchData}
						setErrors={setErrors}
						setSearchData={setSearchData}
						searchByEmail={searchByEmail}
						searchUserBy={searchUserBy}
						setSearchByEmail={setSearchByEmail}
						setSearchUserBy={setSearchUserBy}
						totalSteps={totalSteps}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Detalles del envío',
			content: (
				<Wrapper>
					<SendMoneyStepTwo
						activeStep={activeStep}
						aditionalData={aditionalData}
						commissionConfig={commissionConfig}
						errors={errors}
						getUserToSendMoneyData={getUserToSendMoneyData}
						requestMoneyData={requestMoneyData}
						searchData={searchData}
						searchByEmail={searchByEmail}
						searchUserBy={searchUserBy}
						setAditionalData={setAditionalData}
						setTransactionData={setTransactionData}
						totalSteps={totalSteps}
						transactionData={transactionData}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Método de envío',
			content: (
				<Wrapper>
					<SendMoneyStepThree
						activeStep={activeStep}
						amount={transactionData.amount}
						requestMoneyData={requestMoneyData}
						errors={errors}
						handleClose={handleClose}
						setPaymentMethod={setPaymentMethod}
						setSavedCardDetails={setSavedCardDetails}
						totalSteps={totalSteps}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Confirmación de envío',
			content: (
				<Wrapper>
					<SendMoneyStepFour activeStep={activeStep} totalSteps={totalSteps} />
				</Wrapper>
			),
		},
	];

	// Component functions
	const resetState = () => {
		setSearchData({
			phoneNumber: '593',
		});
		setSearchByEmail({
			email: '',
		});
		setSearchUserBy('phoneNumber');
		setTransactionData({
			userIdTo: '',
			amount: '',
			comment: '',
		});
		setAditionalData({
			commission: '% 0.00',
			commissionValue: 0,
			commissionAmount: '$ 0.00',
			total: '$ 0.00',
		});
		setActiveStep(0);
		setErrors({});
		setPaymentMethod('myBalance');
		setSavedCardDetails(null);
	};

	function handleClose() {
		cleanRequestMoneyData();
		setOpenDialog(false);
		clearInnerRoute();
		changeInnerRoute('account');
		resetState();

		if (activeStep !== 0) {
			updateUserBalance();
			getUserTransactions();
			getUserAccount();
		}
	}

	const handleNext = () => {
		if (activeStep === 0) {
			if (searchUserBy === 'phoneNumber') {
				if (
					Object.keys(
						SendMoneyStepOneValidation(
							userProfile.phoneNumber,
							searchUserBy,
							searchData
						)
					).length === 0
				) {
					setErrors({});

					getUserByPhoneNumber(searchData);

					return;
				}

				setErrors(
					SendMoneyStepOneValidation(
						userProfile.phoneNumber,
						searchUserBy,
						searchData
					)
				);
			} else if (searchUserBy === 'email') {
				if (
					Object.keys(
						SendMoneyStepOneValidation(
							userProfile.email,
							searchUserBy,
							searchByEmail
						)
					).length === 0
				) {
					setErrors({});

					getUserByEmail(searchByEmail);

					return;
				}

				setErrors(
					SendMoneyStepOneValidation(
						userProfile.email,
						searchUserBy,
						searchByEmail
					)
				);
			}
		} else if (activeStep === 1) {
			if (Object.keys(requestMoneyData).length !== 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else if (
				Object.keys(
					SendMoneyStepTwoValidation(maxSend, minSend, transactionData)
				).length === 0
			) {
				setErrors({});

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}

			setErrors(SendMoneyStepTwoValidation(maxSend, minSend, transactionData));

			return;
		} else if (activeStep === 2) {
			const baseData = {
				...transactionData,
				commission: aditionalData.commissionValue * 100,
				commissionAmount: parseFloat(
					aditionalData.commissionAmount.replace('$ ', '')
				),
				totalValue: parseFloat(aditionalData.total.replace('$ ', '')),
				userIdTo: requestMoneyData.userIdTo || transactionData.userIdTo,
				comment: requestMoneyData.comment || transactionData.comment,
			};

			if (paymentMethod === 'myBalance') {
				// if (transactionData.amount < balance) {
				// 	enqueueSnackbar('Tu saldo es insuficiente', {
				// 		key: new Date().getTime() + Math.random(),
				// 		variant: 'error',
				// 	});

				// 	return;
				// }

				sendMoneyIn({ ...baseData, isCard: false });
			} else if (savedCardDetails !== null) {
				chargeSendMoneyToCard({
					...baseData,
					isCard: true,
					customer: userProfile.customerId,
					paymentMethodId: savedCardDetails?.id,
					paymentMethod:
						paymentMethod === 'myBalance' ? 'userBalance' : 'userCards',
				});
			} else {
				setErrors({
					card: 'Seleccione una tarjeta',
				});
			}
		} else if (activeStep === totalSteps.length - 1) {
			handleClose();
		}
	};

	const resetPaymentMetod = () => {
		setPaymentMethod('myBalance');
		setSavedCardDetails(null);
	};

	const handleBack = () => {
		activeStep !== 0
			? setActiveStep((prevActiveStep) => prevActiveStep - 1)
			: activeStep === 2
			? resetPaymentMetod()
			: handleClose();
	};

	const setBtnText = () => {
		if (activeStep === 0) {
			return 'Buscar';
		} else if (activeStep === 1) {
			return 'Continuar';
		} else if (activeStep === 2) {
			return 'Enviar';
		} else if (activeStep === 3) {
			return 'Aceptar';
		}
	};

	const content = sendMoneySteps[activeStep];

	return (
		<CustomDialog
			openDialog={openDialog}
			content={content}
			minHeight={'12rem'}
			onClose={handleClose}
			buttons={
				<TwoBtnsGrid
					leftBtnText={activeStep === 0 ? 'Cancelar' : 'Atrás'}
					isLeftBtnDisabled={Boolean(activeStep === 3)}
					leftLoadingText={'Cancelar'}
					leftBtnAction={handleBack}
					rightBtnText={setBtnText()}
					rightBtnAction={handleNext}
					rightBtnType={'button'}
					leftBtnType={'button'}
					paddingY={'0.5rem'}
					btnsFullWidth={'true'}
				/>
			}
		/>
	);
};

SendMoney.propTypes = {
	balance: PropTypes.number.isRequired,
	cleanRequestMoneyData: PropTypes.func.isRequired,
	clearInnerRoute: PropTypes.func.isRequired,
	configurationByCountry: PropTypes.object,
	chargeSendMoneyToCard: PropTypes.func.isRequired,
	getRequestMoneyData: PropTypes.func.isRequired,
	getUserAccount: PropTypes.func.isRequired,
	getUserByEmail: PropTypes.func.isRequired,
	getUserByPhoneNumber: PropTypes.func.isRequired,
	getUserToSendMoneyData: PropTypes.object.isRequired,
	getUserToSendMoneyDataStatus: PropTypes.bool.isRequired,
	getUserTransactions: PropTypes.func.isRequired,
	openDialog: PropTypes.bool,
	requestMoneyData: PropTypes.object,
	requestMoneyDataCleaned: PropTypes.bool,
	requestMoneyTokenOut: PropTypes.string,
	sendMoneyIn: PropTypes.func.isRequired,
	setOpenDialog: PropTypes.func,
	updateUserBalance: PropTypes.func.isRequired,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { userProfile } = state.user;
	const {
		balance,
		configurationByCountry,
		getUserToSendMoneyData,
		getUserToSendMoneyDataStatus,
		requestMoneyData,
		requestMoneyDataCleaned,
		requestMoneyTokenOut,
	} = state.transactions;

	return {
		balance,
		configurationByCountry,
		getUserToSendMoneyData,
		getUserToSendMoneyDataStatus,
		requestMoneyData,
		requestMoneyDataCleaned,
		requestMoneyTokenOut,
		userProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		chargeSendMoneyToCard: (data) => dispatch(chargeSendMoneyToCard(data)),
		cleanRequestMoneyData: () => dispatch(cleanRequestMoneyData()),
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		getRequestMoneyData: (token) => dispatch(getRequestMoneyData(token)),
		getUserTransactions: () => dispatch(getUserTransactions()),
		getUserAccount: () => dispatch(getUserAccount()),
		getUserByEmail: (searchData) => dispatch(getUserByEmail(searchData)),
		getUserByPhoneNumber: (searchData) =>
			dispatch(getUserByPhoneNumber(searchData)),
		sendMoneyIn: (transactionData) => dispatch(sendMoneyIn(transactionData)),
		updateUserBalance: () => dispatch(updateUserBalance()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMoney);
