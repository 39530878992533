import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomDialog from '../../../../../layout/CustomDialog';
import TwoBtnsGrid from '../../../../../shared/TwoBtnsGrid';
import DepositMoneyStepOne from './DepositMoneyStepOne';
import DepositMoneyStepThree from './DepositMoneyStepTwo';
import DepositValidationStepOne from './Validation/DepositValidationStepOne';
import StripeStepTwo from './StripeStepTwo';

// Redux Actions Imports
import {
	changeInnerRoute,
	clearInnerRoute,
} from '../../../../../store/actions/innerRouterActions';
import {
	updateUserBalance,
	getUserAccount,
	getUserTransactions,
} from '../../../../../store/actions/transactionsActions';
import { stripeDepositSavedCard } from '../../../../../store/actions/stripeActions';
import { showHideAddAccountForm } from '../../../../../store/actions/bankAccountsActions';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
	minHeight: '12rem',
});

const DepositMoney = (props) => {
	// Props destructuring
	const {
		changeInnerRoute,
		clearInnerRoute,
		configurationByCountry,
		getUserAccount,
		getUserTransactions,
		openDialog,
		setOpenDialog,
		showAddAccountForm,
		showHideAddAccountForm,
		stripeDepositSavedCard,
		updateUserBalance,
		userProfile,
	} = props;

	// Component state
	const [activeStep, setActiveStep] = useState(0);
	const [transactionData, setTransactionData] = useState({
		userId: '',
		amount: '',
		description: '',
		shippingFirstName: '',
		shippingLastName: '',
		shippingEmail: '',
		shippingPhone: '',
		shippingAddress: '',
		shippingZIP: '',
		shippingCity: '',
		shippingState: '',
		shippingCountry: '',
		residenceCountryId: '',
	});
	const [aditionalData, setAditionalData] = useState({
		commission: '% 0.00',
		commissionValue: 0,
		commissionAmount: '$ 0.00',
		total: '$ 0.00',
	});
	const [maxDeposit, setMaxDeposit] = useState(0);
	const [minDeposit, setMinDeposit] = useState(0);
	const [commissionConfig, setCommissionConfig] = useState(1);
	const [savedCardDetails, setSavedCardDetails] = useState(null);

	const [errors, setErrors] = useState({});

	// Component hooks
	useEffect(() => {
		if (openDialog && configurationByCountry?.percentDeposit) {
			setMaxDeposit(configurationByCountry.maxDeposit);
			setMinDeposit(configurationByCountry.minDeposit);
			setCommissionConfig(configurationByCountry.commissionDirection);

			setAditionalData({
				...aditionalData,
				commission: `% ${configurationByCountry.percentDeposit.toFixed(2)}`,
				commissionValue: configurationByCountry?.percentDeposit / 100,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationByCountry, openDialog]);

	useEffect(() => {
		if (showAddAccountForm) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showAddAccountForm]);

	// Component functions
	const resetState = () => {
		setTransactionData({
			userId: '',
			amount: '',
			description: '',
			shippingFirstName: '',
			shippingLastName: '',
			shippingEmail: '',
			shippingPhone: '',
			shippingAddress: '',
			shippingZIP: '',
			shippingCity: '',
			shippingState: '',
			residenceCountry: '',
			residenceCountryId: 0,
		});
		setAditionalData({
			commission: '% 0.00',
			commissionValue: 0,
			commissionAmount: '$ 0.00',
			total: '$ 0.00',
		});
		setErrors({});
		setActiveStep(0);
	};

	function handleClose() {
		setOpenDialog(false);
		clearInnerRoute();
		changeInnerRoute('account');

		resetState();
		setSavedCardDetails(null);

		if (activeStep === 2) {
			updateUserBalance();
			getUserAccount();
			getUserTransactions();
		}
	}

	const totalSteps = [0, 1, 2];

	const depositSteps = [
		{
			title: 'Depositar dinero',
			content: (
				<Wrapper>
					<DepositMoneyStepOne
						activeStep={activeStep}
						aditionalData={aditionalData}
						commissionConfig={commissionConfig}
						totalSteps={totalSteps}
						transactionData={transactionData}
						setAditionalData={setAditionalData}
						setTransactionData={setTransactionData}
						errors={errors}
						setErrors={setErrors}
						setActiveStep={setActiveStep}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Datos de pago',
			content: (
				<Wrapper>
					<StripeStepTwo
						activeStep={activeStep}
						handleClose={handleClose}
						setActiveStep={setActiveStep}
						setSavedCardDetails={setSavedCardDetails}
						showHideAddAccountForm={showHideAddAccountForm}
						totalSteps={totalSteps}
						transactionData={transactionData}
						userProfile={userProfile}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Confirmación de depósito',
			content: (
				<Wrapper>
					<DepositMoneyStepThree
						activeStep={activeStep}
						totalSteps={totalSteps}
					/>
				</Wrapper>
			),
		},
	];

	const handleNext = () => {
		setErrors({});

		if (activeStep === 0) {
			if (
				Object.keys(
					DepositValidationStepOne(maxDeposit, minDeposit, transactionData)
				).length === 0
			) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}

			setErrors(
				DepositValidationStepOne(maxDeposit, minDeposit, transactionData)
			);
		} else if (activeStep === 1) {
			const paymentInfo = {
				accountId: userProfile.accountId,
				amount: transactionData.amount,
				currency: 'usd',
				customer: savedCardDetails.customer,
				paymentMethod: savedCardDetails.id,
				description: transactionData.description,
				userId: transactionData.userId,
			};

			stripeDepositSavedCard(paymentInfo);
		} else if (activeStep === 2) {
			handleClose();
		}
	};

	const handleBack = () => {
		activeStep !== 0
			? setActiveStep((prevActiveStep) => prevActiveStep - 1)
			: handleClose();
	};

	const content = depositSteps[activeStep];

	return (
		<CustomDialog
			openDialog={openDialog}
			content={content}
			minHeight={'10rem'}
			onClose={handleClose}
			buttons={
				<TwoBtnsGrid
					leftBtnText={activeStep === 0 ? 'Cancelar' : 'Atras'}
					isLeftBtnDisabled={Boolean(activeStep === 2)}
					leftLoadingText={'Cancelar'}
					leftBtnAction={handleBack}
					rightBtnText={
						activeStep === 0 || activeStep === 1 ? 'Siguiente' : 'Aceptar'
					}
					rightBtnAction={handleNext}
					rightBtnType={'button'}
					leftBtnType={'button'}
					paddingY={'0.5rem'}
					btnsFullWidth={'true'}
				/>
			}
		/>
	);
};

DepositMoney.defaultProps = {
	configurationByCountry: {},
};

DepositMoney.propTypes = {
	changeInnerRoute: PropTypes.func.isRequired,
	clearInnerRoute: PropTypes.func.isRequired,
	configurationByCountry: PropTypes.object,
	getUserAccount: PropTypes.func.isRequired,
	getUserTransactions: PropTypes.func.isRequired,
	openDialog: PropTypes.bool,
	setOpenDialog: PropTypes.func,
	showAddAccountForm: PropTypes.bool.isRequired,
	showHideAddAccountForm: PropTypes.func.isRequired,
	stripeDepositSavedCard: PropTypes.func.isRequired,
	updateUserBalance: PropTypes.func.isRequired,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { configurationByCountry } = state.transactions;
	const { userProfile } = state.user;
	const { showAddAccountForm } = state.bankAccounts;

	return {
		configurationByCountry,
		showAddAccountForm,
		userProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		getUserAccount: () => dispatch(getUserAccount()),
		getUserTransactions: () => dispatch(getUserTransactions()),
		updateUserBalance: () => dispatch(updateUserBalance()),
		showHideAddAccountForm: () => dispatch(showHideAddAccountForm()),
		stripeDepositSavedCard: (paymentInfo) =>
			dispatch(stripeDepositSavedCard(paymentInfo)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositMoney);
