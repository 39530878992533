import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box, Dialog, Slide, Typography } from '@mui/material';

// QR Scanner imports
import { QrReader } from 'react-qr-reader';

// Styled Components imports
import styled from 'styled-components';

// Local components imports
import MenuBar from '../../../../../layout/MenuBar';

// Redux Actions Imports
import { backToPreviousInnerRoute } from '../../../../../store/actions/innerRouterActions';
import { getRequestMoneyToken } from '../../../../../store/actions/transactionsActions';
import { enqueueSnackbar } from '../../../../../store/actions/notificationActions';
import LayoutWrapper from '../../../../../layout/LayoutWrapper';

// Styled Components
const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0.5rem !important',
	textAlign: 'center',
});

const QRScannerWrapper = styled(Box)({
	position: 'relative',
});

const TargetScanner = styled(Box)({
	width: '250px',
	height: '250px',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundImage: 'url(/img/qr-scanner-target.png)',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const QRScanner = (props) => {
	// Props destructuring
	const {
		actualRoute,
		backToPreviousInnerRoute,
		getRequestMoneyToken,
		openDialog,
		requestMoneyData,
		setOpenDialog,
	} = props;

	// Component state
	const [qrDecoded, setQRDecoded] = useState('');

	// Component hooks
	useEffect(() => {
		if (actualRoute !== 'qrScanner') {
			setOpenDialog(false);
		}
	}, [actualRoute, setOpenDialog]);

	useEffect(() => {
		getRequestMoneyToken(qrDecoded);

		if (Object.keys(requestMoneyData).length > 0) {
			setOpenDialog(false);
			setQRDecoded('');
		} else {
			enqueueSnackbar({
				message: 'El código QR no es válido',
				options: {
					key: new Date().getTime() + Math.random(),
					variant: 'error',
				},
			});
		}
	}, [qrDecoded, getRequestMoneyToken, requestMoneyData, setOpenDialog]);

	// Component functions
	const handleClose = () => {
		setOpenDialog(false);
		backToPreviousInnerRoute();
	};

	return (
		<Dialog
			fullScreen
			open={openDialog}
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<MenuBar pageTitle='Utransfer' />
			<LayoutWrapper>
				<SectionTitle variant='body2' sx={{ px: 2, pt: 1, pb: 3 }}>
					Escanea el QR de la solicitud de pago
				</SectionTitle>
				<QRScannerWrapper>
					<QrReader
						constraints={{ facingMode: 'environment' }}
						onResult={(result, error) => {
							setQRDecoded(result?.text);
						}}
						style={{ width: '100%' }}
						containerStyle={{ width: '100%' }}
					/>
					<TargetScanner />
				</QRScannerWrapper>
				<SectionTitle variant='body2' sx={{ px: 2, pt: 3 }}>
					Se te redirigirá para realizar la confirmación
				</SectionTitle>
			</LayoutWrapper>
		</Dialog>
	);
};

QRScanner.propTypes = {
	actualRoute: PropTypes.string.isRequired,
	backToPreviousInnerRoute: PropTypes.func.isRequired,
	getRequestMoneyToken: PropTypes.func.isRequired,
	openDialog: PropTypes.bool.isRequired,
	requestMoneyData: PropTypes.object,
	setOpenDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	const { requestMoneyData } = state.transactions;

	return {
		requestMoneyData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		backToPreviousInnerRoute: () => dispatch(backToPreviousInnerRoute()),
		getRequestMoneyToken: (data) => dispatch(getRequestMoneyToken(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QRScanner);
