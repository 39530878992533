const initState = {
	innerRoute: [],
};

const innerRouterReducer = (state = initState, action) => {
	switch (action.type) {
		case 'CHANGE_ROUTE':
			return {
				...state,
				innerRoute: [...state.innerRoute, action.payload],
			};
		case 'BACK_TO_PREVIOUS_ROUTE':
			return {
				...state,
				innerRoute: state.innerRoute.slice(0, -1),
			};
		case 'CLEAR_ROUTE':
		case 'SIGNOUT':
			return {
				...state,
				innerRoute: [],
			};
		default:
			return state;
	}
};

export default innerRouterReducer;
