import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local components imports
import UserCardsPayment from './Stripe/UserCardsPayment';
import CustomBtn from '../../../../../layout/CustomBtn';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	minHeight: '12rem',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	width: '100%',
});

const StripeStepTwo = (props) => {
	// Props destructuring
	const {
		activeStep,
		handleClose,
		showHideAddAccountForm,
		setActiveStep,
		setSavedCardDetails,
		totalSteps,
		userProfile,
	} = props;

	// Component functions
	const handleAddBankAccount = () => {
		showHideAddAccountForm();
	};

	return (
		<>
			{!userProfile?.accountId ? (
				<>
					<SectionTitle variant='body2' sx={{ mb: 0.5 }}>
						Para poder hacer depósitos, debes registrar una cuenta bancaria
					</SectionTitle>
					<Wrapper>
						<CustomBtn
							btnText='Agregar cuenta'
							btnColor='var(--secondary)'
							btnType='button'
							width={'200px'}
							startIcon='faAdd'
							onClick={handleAddBankAccount}
							marginTop={'1rem'}
							btnHoverColor={'var(--secondary)'}
						/>
					</Wrapper>
				</>
			) : (
				<>
					<SectionTitle variant='body2' sx={{ mb: 0.5 }}>
						Elije una de tus tarjetas
					</SectionTitle>
					<Wrapper>
						<UserCardsPayment
							activeStep={activeStep}
							handleClose={handleClose}
							setActiveStep={setActiveStep}
							setSavedCardDetails={setSavedCardDetails}
							totalSteps={totalSteps}
						/>
					</Wrapper>
				</>
			)}
		</>
	);
};

StripeStepTwo.propTypes = {
	activeStep: PropTypes.number.isRequired,
	handleClose: PropTypes.func.isRequired,
	showHideAddAccountForm: PropTypes.func.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	setSavedCardDetails: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	userProfile: PropTypes.object.isRequired,
};

export default StripeStepTwo;
