import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Backdrop } from '@mui/material';

// Local Components Imports
import Loading from '../shared/Loading';

// Styled Components

const ActionLoading = (props) => {
	// Props destructuring
	const { loadingText, open, textColor } = props;

	return (
		<Backdrop
			sx={{
				color: '#fff !important',
				zIndex: 9999,
			}}
			open={open}
		>
			<Loading loadingText={loadingText} textColor={textColor} />
		</Backdrop>
	);
};

ActionLoading.propTypes = {
	loadingText: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	textColor: PropTypes.string,
};

export default ActionLoading;
