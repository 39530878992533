import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

// Local Imports
import RouterWrapper from './RouterWrapper';
import PageLoading from '../shared/PageLoading';

// Loadable Imports
// const User = loadable(() => import('../pages/User'));
const History = loadable(() => import('../pages/History/History'));
const InactiveUser = loadable(() =>
	import('../pages/InactiveUser/InactiveUser')
);
const Home = loadable(() => import('../pages/Home/Home'));
const Login = loadable(() => import('../pages/Login/Login'));
const MyAccount = loadable(() => import('../pages/Account/MyAccount'));
const NotFound = loadable(() => import('../pages/NotFound/NotFound'));
const ResetPassword = loadable(() =>
	import('../pages/ResetPassword/ResetPassword')
);
const Signup = loadable(() => import('../pages/Signup/Signup'));
const SessionExpired = loadable(() =>
	import('../pages/SessionExpired/SessionExpired')
);
const TwoSessions = loadable(() => import('../pages/TwoSessions/TwoSessions'));
const Wallet = loadable(() => import('../pages/Wallet/Wallet'));
const Welcome = loadable(() => import('../pages/Welcome/Welcome'));
const Privacy = loadable(() => import('../pages/PrivacyAndContact/Privacy'));

const Router = () => {
	return (
		<RouterWrapper>
			<Suspense fallback={<PageLoading />}>
				<Routes>
					<Route path='/inicio' element={<Home />} />
					<Route exact path='/' element={<Navigate replace to='/inicio' />} />
					<Route path='/cuenta/:rmt?' element={<MyAccount />} />
					<Route path='/billetera' element={<Wallet />} />
					<Route path='/bienvenida/:rmt?' element={<Welcome />} />
					<Route path='/historial' element={<History />} />
					<Route path='/ingresar' element={<Login />} />
					<Route path='/politica-de-privacidad' element={<Privacy />} />
					<Route path='/registrarse' element={<Signup />} />
					<Route path='/restablecer-contrasena' element={<ResetPassword />} />
					<Route path='/sesion-expirada' element={<SessionExpired />} />
					<Route path='/sesion-otro-dispositivo' element={<TwoSessions />} />
					<Route path='/usuario-inactivo' element={<InactiveUser />} />

					{/* Not found route */}
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Suspense>
		</RouterWrapper>
	);
};

export default Router;
