import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';
import StepperIndicator from '../../../../../../shared/StepperIndicator';
import UserCards from '../../MyCards/Components/UserCards';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: '17rem',
	overflowY: 'scroll',
	padding: '0.25rem 0 1rem 0rem',
});

const UserCardsPayment = (props) => {
	// Props destructuring
	const {
		activeStep,
		handleClose,
		nextStep,
		setActiveStep,
		setSavedCardDetails,
		totalSteps,
	} = props;

	useEffect(() => {
		if (nextStep) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [nextStep, setActiveStep]);

	return (
		<>
			<Wrapper>
				<UserCards
					isEdit={false}
					setSavedCardDetails={setSavedCardDetails}
					isPayment={true}
					closeModal={handleClose}
				/>
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={72}
			/>
		</>
	);
};

UserCardsPayment.propTypes = {
	activeStep: PropTypes.number.isRequired,
	handleClose: PropTypes.func.isRequired,
	nextStep: PropTypes.bool.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	setSavedCardDetails: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	const { nextStep } = state.stripe;

	return {
		nextStep,
	};
};

export default connect(mapStateToProps)(UserCardsPayment);
