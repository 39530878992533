export const textTruncator = (text, maxLength = 53, mayus = true) => {
	let lowercaseText = '';

	if (text) {
		lowercaseText = text.toLowerCase(); // Convert text to lowercase.
	}

	const textToTruncate = mayus
		? lowercaseText.replace(/\b\w/g, (match) => match.toUpperCase()) // Convert the first letter of each word to uppercase.
		: lowercaseText;

	if (lowercaseText.length <= maxLength) {
		return textToTruncate; // If the text length is within the limit, return the original text.
	} else {
		const truncatedText = textToTruncate.slice(0, maxLength - 3) + '...'; // Remove excess characters and add ellipsis.

		return truncatedText;
	}
};
