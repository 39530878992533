import PropTypes from 'prop-types';

const DepositValidationStepOne = (maxDeposit, minDeposit, values = {}) => {
	let errors = {};

	// Amount validation
	if (values.amount === '') {
		errors.amount = 'El monto es requerido';
	} else if (parseFloat(values.amount) < minDeposit) {
		errors.amount = `El monto mínimo es de $ ${minDeposit?.toFixed(2)}`;
	} else if (parseFloat(values.amount) > maxDeposit) {
		errors.amount = `El monto máximo es de $ ${maxDeposit?.toFixed(2)}`;
	}

	// Description validation
	if (values.description === '') {
		errors.description = 'La descripción es requerida';
	} else if (
		values.description?.length > 0 &&
		values.description?.length <= 5
	) {
		errors.description = 'La descripción es muy corta';
	} else if (values.description?.length > 100) {
		errors.description = 'La descripción es muy larga';
	}

	return errors;
};

DepositValidationStepOne.propTypes = {
	maxDeposit: PropTypes.number.isRequired,
	minDeposit: PropTypes.number.isRequired,
	values: PropTypes.object.isRequired,
};

export default DepositValidationStepOne;
