import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import CustomIcon from './CustomIcon';
import CustomBtn from '../layout/CustomBtn';

// Styled Components
const CustomDialog = styled(Dialog)({
	'.MuiPaper-root': {
		borderRadius: '15px',
		maxWidth: '350px !important',
		minWidth: '350px !important',
	},
});

const CustomDialogTitle = styled(DialogTitle)({
	backgroundColor: 'var(--primary) !important',
	color: '#fff !important',
	paddingBottom: '0.5rem !important',
	display: 'flex !important',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '32px !important',
});

const ConfirmationWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});

const ConfirmationText = styled(Typography)({
	fontSize: '1.25rem',
	fontWeight: 'bold',
	margin: '0.5rem !important',
	marginTop: '1rem !important',
	textAlign: 'center',
});

const AlertDialog = (props) => {
	// Props destructuring
	const {
		dialogInfo,
		dilogType,
		dilogIcon,
		handleConfirmation,
		hasTwoBtns,
		openDialog,
		onClose,
		primaryBtnIcon,
		primaryBtnTitle,
		title,
	} = props;

	return (
		<CustomDialog
			open={openDialog}
			aria-labelledby={`${dilogType}-dialog-title`}
			aria-describedby={`${dilogType}-dialog-description`}
		>
			<CustomDialogTitle id={`${dilogType}-dialog-title`}>
				{title}
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{ width: '20px', height: '20px', p: 2 }}
				>
					<CustomIcon iconName='faXmark' iconColor='#fff' iconSize={'1.2rem'} />
				</IconButton>
			</CustomDialogTitle>
			<DialogContent dividers>
				<ConfirmationWrapper>
					<CustomIcon
						iconName={dilogIcon}
						iconColor='var(--primary)'
						iconSize={'3rem'}
					/>
					<ConfirmationText variant='body1'>{dialogInfo}</ConfirmationText>
				</ConfirmationWrapper>
			</DialogContent>
			<DialogActions>
				{hasTwoBtns && (
					<CustomBtn
						btnText={'Cancelar'}
						btnType={'button'}
						btnHoverColor={'transparent'}
						btnTextHoverColor={'var(--secondary)'}
						btnLoadingText={'Cancelar'}
						fontSize={'1rem'}
						onClick={onClose}
						variant={'outlined'}
						btnColor={'transparent'}
						startIcon={'faXmark'}
						marginTop={'0px'}
						width='200px'
					/>
				)}
				<CustomBtn
					btnType={'button'}
					btnText={primaryBtnTitle}
					onClick={handleConfirmation}
					width={'200px'}
					fontSize={'1rem'}
					marginTop={'0px'}
					startIcon={primaryBtnIcon}
				/>
			</DialogActions>
		</CustomDialog>
	);
};

AlertDialog.defaultProps = {
	dilogIcon: 'faQuestionCircle',
	hasTwoBtns: true,
	primaryBtnIcon: 'faCircleCheck',
};

AlertDialog.propTypes = {
	handleConfirmation: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	openDialog: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	dialogInfo: PropTypes.string.isRequired,
	dilogType: PropTypes.string.isRequired,
	dilogIcon: PropTypes.string.isRequired,
	primaryBtnTitle: PropTypes.string.isRequired,
	hasTwoBtns: PropTypes.bool,
	primaryBtnIcon: PropTypes.string,
};

export default AlertDialog;
