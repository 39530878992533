import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import StepperIndicator from '../../../../../shared/StepperIndicator';
import Logo from '../../../../../shared/Logo';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
	paddingBottom: '0.5rem',
});

const WithdrawMoneyStepTwo = (props) => {
	// Props destructuring
	const { activeStep, totalSteps, withdrawValidationCode } = props;

	return (
		<>
			<Wrapper>
				<Logo vertical logoHeight={'130px'} />
				<Typography variant='h4' align='center' sx={{ mb: 1, mt: 2 }}>
					{withdrawValidationCode}
				</Typography>
				<Typography variant='body1' align='center' sx={{ mb: 2 }}>
					Este es tu código de validación.
				</Typography>
				<Typography variant='body1' align='center' sx={{ px: 1 }}>
					Al dar click en el botón "Siguiente" tu transacción será procesada.
				</Typography>
				<Box sx={{ height: 20 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

WithdrawMoneyStepTwo.propTypes = {
	activeStep: PropTypes.number.isRequired,
	totalSteps: PropTypes.array.isRequired,
	withdrawValidationCode: PropTypes.string.isRequired,
};

export default WithdrawMoneyStepTwo;
