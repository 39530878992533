import PropTypes from 'prop-types';

const SendMoneyStepOneValidation = (
	userSelfInfo,
	searchUserBy,
	values = {}
) => {
	let errors = {};

	if (searchUserBy === 'phoneNumber') {
		// Phone number validation
		if (!values.phoneNumber.trim()) {
			errors.phoneNumber = 'El número telefónico es requerido';
		}

		if (values.phoneNumber.length > 0 && values.phoneNumber.length <= 8) {
			errors.phoneNumber = 'El número telefónico es muy corto';
		}

		if (values.phoneNumber.length >= 20) {
			errors.phoneNumber = 'El número telefónico es incorrecto';
		}

		if (values.phoneNumber === userSelfInfo) {
			errors.phoneNumber = 'El numero debe ser diferente al tuyo';
		}
	} else if (searchUserBy === 'email') {
		// Email validation
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!values.email) {
			errors.email = 'El email es requerido';
		} else if (!emailPattern.test(values.email)) {
			errors.email = 'El email debe ser válido';
		} else if (values.email === userSelfInfo) {
			errors.email = 'El email debe ser diferente al tuyo';
		}
	}

	return errors;
};

SendMoneyStepOneValidation.propTypes = {
	values: PropTypes.object.isRequired,
	userNumber: PropTypes.string.isRequired,
};

export default SendMoneyStepOneValidation;
