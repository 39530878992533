import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomBtn from '../../../../../layout/CustomBtn';
import StepperIndicator from '../../../../../shared/StepperIndicator';
import PhoneInput from 'react-phone-input-2';
import CustomInput from '../../../../../layout/CustomInput';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	overflow: 'auto',
	maxHeight: 'calc(100vh / 2)',
	minHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
});

const ErrorText = styled(Box)({
	fontWeight: '400',
	fontSize: '0.75rem',
	lineHeight: '1.66',
	letterSpacing: '0.03333em',
	textAlign: 'left',
	marginRight: '14px',
	marginLeft: '14px',
	color: 'var(--error)',
});

const BtnContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});

const SendMoneyStepOne = (props) => {
	// Props destructuring
	const {
		activeStep,
		errors,
		totalSteps,
		searchData,
		setSearchData,
		searchByEmail,
		searchUserBy,
		setErrors,
		setSearchByEmail,
		setSearchUserBy,
	} = props;

	// Component functions
	const handleChange = (val) => {
		if (searchUserBy === 'phoneNumber') {
			setSearchData({
				...searchData,
				phoneNumber: val,
			});
		} else {
			setSearchByEmail({
				email: val.target.value,
			});
		}
	};

	const handleSearchUserBy = () => {
		setErrors({});

		if (searchUserBy === 'phoneNumber') {
			setSearchData({
				phoneNumber: '',
			});
			setSearchUserBy('email');
		} else {
			setSearchByEmail({
				email: '',
			});
			setSearchUserBy('phoneNumber');
		}
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2'>¿Quién recibe el pago?</SectionTitle>
				{searchUserBy === 'phoneNumber' ? (
					<Box sx={{ mt: 1, mb: errors.phoneNumber ? '0px' : '19px' }}>
						<PhoneInput
							placeholder='Digite el número del destinatario'
							value={searchData.phoneNumber}
							country={searchData.countryPhoneCode}
							onChange={handleChange}
							enableSearch
							inputStyle={{
								height: '40px',
								width: '100%',
								backgroundColor: '#fff',
								color: 'rgba(0,0,0,0.7) !important',
								borderColor: errors.phoneNumber
									? 'var(--error)'
									: 'var(--secondary)',
								borderWidth: 2,
								borderRadius: 10,
								transition: '0.3s ease',
							}}
							buttonStyle={{
								borderColor: errors.phoneNumber
									? 'var(--error)'
									: 'var(--secondary)',
								borderWidth: 2,
								borderRadius: '10px 0px 0px 10px',
								zIndex: 9999,
							}}
							dropdownStyle={{
								maxHeight: 'calc(100vh / 3.5)',
							}}
							searchStyle={{
								width: '85%',
							}}
							searchPlaceholder='Buscar país'
							isValid={(value, country) => {
								if (value.match(/12345/)) {
									return 'Invalid value: ' + value + ', ' + country.name;
								} else if (value.match(/1234/)) {
									return false;
								} else {
									return true;
								}
							}}
						/>
						{errors.phoneNumber && <ErrorText>{errors.phoneNumber}</ErrorText>}
					</Box>
				) : (
					<CustomInput
						type='email'
						autoComplete='email'
						inputHintText='Correo electrónico'
						name='email'
						value={searchByEmail.email}
						onChange={handleChange}
						inputSize='small'
						error={Boolean(errors.email)}
						errorText={errors.email}
					/>
				)}
				<BtnContainer sx={{ mb: 2 }}>
					<CustomBtn
						btnText={
							searchUserBy === 'phoneNumber'
								? 'Buscar por email'
								: 'Buscar por teléfono'
						}
						btnType={'button'}
						btnHoverColor='transparent'
						btnTextHoverColor='var(--secondary)'
						btnLoadingText={
							searchUserBy === 'phoneNumber'
								? 'Buscar por email'
								: 'Buscar por teléfono'
						}
						fontSize='1.2rem'
						onClick={handleSearchUserBy}
						variant='outlined'
						btnColor='transparent'
						width={'230px'}
					/>
				</BtnContainer>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

SendMoneyStepOne.propTypes = {
	activeStep: PropTypes.number.isRequired,
	errors: PropTypes.object.isRequired,
	searchData: PropTypes.object.isRequired,
	setErrors: PropTypes.func.isRequired,
	setSearchData: PropTypes.func.isRequired,
	searchByEmail: PropTypes.object.isRequired,
	searchUserBy: PropTypes.string.isRequired,
	setSearchByEmail: PropTypes.func.isRequired,
	setSearchUserBy: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
};

export default SendMoneyStepOne;
