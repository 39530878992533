const initState = {
	loadingOptions: false,
	documentTypes: [],
	countries: [],
};

const appOptionsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_OPTIONS_START':
			return {
				...state,
				loadingOptions: true,
			};
		case 'GET_DOCUMENT_TYPES_SUCCESS':
			return {
				...state,
				loadingOptions: false,
				documentTypes: action.payload,
			};
		case 'GET_COUNTRIES_SUCCESS':
			return {
				...state,
				loadingOptions: false,
				countries: action.payload,
			};
		case 'GET_DOCUMENT_TYPES_ERROR':
		case 'GET_COUNTRIES_ERROR':
			return {
				...state,
				loadingOptions: false,
			};
		case 'SIGNOUT':
			return {
				...state,
				documentTypes: [],
				countries: [],
			};
		default:
			return state;
	}
};

export default appOptionsReducer;
