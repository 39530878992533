import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import {
	Backdrop,
	BottomNavigationAction,
	Box,
	SpeedDial,
	SpeedDialAction,
} from '@mui/material';

// Styled Components imports
import { styled } from 'styled-components';

// Redux actions imports
import {
	backToPreviousInnerRoute,
	changeInnerRoute,
	clearInnerRoute,
} from '../store/actions/innerRouterActions';

// Local components imports
import CustomIcon from '../shared/CustomIcon';
import AllTransactionsComponent from '../pages/Account/InnerRoutes/UtransferServices/AllTransactionsComponent';

// Styled components
const BottomNavBtn = styled(BottomNavigationAction)({
	'&.MuiButtonBase-root': {
		backgroundColor: (props) =>
			props.navroute === props.activeroute ? '#fff' : 'transparent',
		width: '3.5rem',
		height: '3.5rem',
		maxWidth: '3.5rem',
		padding: '0px',
		minWidth: '3.5rem',
		margin: '0.5rem',
		borderRadius: '0.8rem',

		'@media (max-width: 320px)': {
			width: '3rem',
			height: '3rem',
			maxWidth: '3rem',
			minWidth: '3rem',
			margin: '0.3rem',
		},

		'@media (min-width: 321px) and (max-width: 360px)': {
			width: '3.2rem',
			height: '3.2rem',
			maxWidth: '3.2rem',
			minWidth: '3.2rem',
			margin: '0.3rem',
		},
	},

	'& .MuiBottomNavigationAction-root, svg': {
		color: (props) =>
			props.navroute === props.activeroute ? 'var(--secondary)' : '#fff',
	},

	'& .MuiBottomNavigationAction-label, .MuiBottomNavigationAction-label': {
		marginTop: '0.25rem',
		color: (props) =>
			props.navroute === props.activeroute ? 'var(--secondary)' : '#fff',

		'@media (max-width: 320px)': {
			fontSize: '0.6rem',
		},
	},
});

const CentralNavBtnContainer = styled(Box)({
	background:
		'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,#ffffff 45%,#ffffff 50%,#ffffff 100%)',
	margin: '-40px 0.5rem 0px 0.5rem',
	width: '5rem',
	height: '5rem',
	borderRadius: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',

	'@media (max-width: 320px)': {
		margin: '-35px 0.5rem 0px 0.5rem',
		width: '4.25rem',
		height: '4.25rem',
	},

	'@media (min-width: 321px) and (max-width: 360px)': {
		width: '4.5rem',
		height: '4.5rem',
	},
});

const actions = [
	{
		icon: 'faQrcode',
		name: 'Escanear QR',
		dialogName: 'qrScanner',
	},
	{
		icon: 'faHandHoldingDollar',
		name: 'Retirar',
		dialogName: 'withdrawMoney',
	},
	{
		icon: 'faMoneyCheckDollar',
		name: 'Solicitar pago',
		dialogName: 'requestMoney',
	},
	{
		icon: 'faMoneyBillTrendUp',
		name: 'Enviar',
		dialogName: 'sendMoney',
	},
];

const BottomNav = (props) => {
	// Props Destructuring
	const {
		activeRoute,
		backToPreviousInnerRoute,
		changeInnerRoute,
		clearInnerRoute,
		index,
		innerRoute,
		navRoute,
		navIcon,
		navTitle,
	} = props;

	// Component state
	const [open, setOpen] = useState(false);

	// Component route
	const actualRoute = innerRoute[innerRoute.length - 1];

	// Component Hooks
	const navigate = useNavigate();

	// Component Functions
	const handleOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

	const handleDialogOpen = (dialogName) => {
		handleClose();
		changeInnerRoute(dialogName);
	};

	const changeRoute = () => {
		if (innerRoute.length > 1) {
			backToPreviousInnerRoute();
			navigate(navRoute);
		} else {
			clearInnerRoute();
			navigate(navRoute);
		}
	};

	const NavButton = (
		<BottomNavBtn
			label={index !== 2 && navTitle}
			icon={<CustomIcon iconName={navIcon} iconSize='1.25rem' />}
			showLabel
			onClick={changeRoute}
			activeroute={activeRoute}
			navroute={navRoute.slice(1)}
		/>
	);

	const CentralButton = (
		<CentralNavBtnContainer>
			<Backdrop open={open} />
			<SpeedDial
				ariaLabel='Transactions options'
				sx={{ position: 'absolute', bottom: 8 }}
				icon={<CustomIcon iconName={navIcon} iconSize='2rem' />}
				FabProps={{
					sx: {
						color: '#fff',
						width: '4rem',
						height: '4rem',
						'@media (max-width: 360px)': {
							width: '3.5rem',
							height: '3.5rem',
						},
					},
				}}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
			>
				{actions.slice().map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={<CustomIcon iconName={action.icon} iconSize='1.2rem' />}
						tooltipTitle={action.name}
						tooltipOpen={open}
						onClick={() => handleDialogOpen(action.dialogName)}
					/>
				))}
			</SpeedDial>
			<AllTransactionsComponent actualRoute={actualRoute} />
		</CentralNavBtnContainer>
	);

	return index !== 2 ? NavButton : CentralButton;
};

BottomNav.propTypes = {
	activeRoute: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	innerRoute: PropTypes.array.isRequired,
	navRoute: PropTypes.string.isRequired,
	navIcon: PropTypes.node.isRequired,
	navTitle: PropTypes.string.isRequired,
	changeInnerRoute: PropTypes.func.isRequired,
	clearInnerRoute: PropTypes.func.isRequired,
	backToPreviousInnerRoute: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	const { innerRoute } = state.innerRouter;

	return {
		innerRoute,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		backToPreviousInnerRoute: () => dispatch(backToPreviousInnerRoute()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
