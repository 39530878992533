import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local imports
import CustomSelectInputSearch from '../../../../../../layout/CustomSelectInputSearch';
import CustomInput from '../../../../../../layout/CustomInput';
import StepperIndicator from '../../../../../../shared/StepperIndicator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0.5rem !important',
});

const AddBankAccountStripe = (props) => {
	// Props destructuring
	const {accountTypes, countries,
		activeStep, bankData, errors, setBankData, totalSteps } = props;

	// Component State
	const [countriesList, setCountriesList] = useState([]);
	const [accountTypesModified, setAccountTypesModified] = useState([]);

	// Component hooks
	useEffect(() => {
		setCountriesList(
			countries.map((country) => ({
				...country,
				label: country.name,
				iso: country.iso2,
				id: country.id,
			}))
		);
	}, []);

	useEffect(() => {
		if (bankData.bankCountry !== '') {
			setAccountTypesModified(
				accountTypes
					.filter((accountType) => accountType.data.abbreviation !== 'UTR')
					.map((accountType) => ({
						label: accountType.label,
						id: accountType.id,
					}))
			);
		}
	}, [accountTypes, bankData]);

	// Compoent Functions
	const handleBankCountryChange = (value) => {
		setBankData({
			...bankData,
			bankCountry: value.label,
			bankCountryCode: value.iso,
			bankCountryId: value.id,
		});
	};

	const handleAccountTypeChange = (value) => {
		setBankData({
			...bankData,
			typeAccount: value.id,
			typeAccountText: value.label,
		});
	};
	const handleChange = (e) => {
		console.log(e)
		setBankData({
			...bankData,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2' sx={{ mb: 1.5 }}>
					Cuenta de otros bancos
				</SectionTitle>

				<CustomSelectInputSearch
					options={countriesList}
					title={'Selecciona un país'}
					handleChange={handleBankCountryChange}
					value={bankData.bankCountry}
					error={Boolean(errors.bankCountry)}
					errorText={errors.bankCountry}
				/>
				<CustomInput
					name='routingNumber'
					type='text'
					inputHintText='Número de enrutamiento (SWIFT)'
					value={bankData.routingNumber}
					onChange={handleChange}
					inputSize='small'
					error={Boolean(errors.routingNumber)}
					errorText={errors.routingNumber}
					marginTop={'5px'}
					isNumber
				/>
				<CustomInput
					name='accountNumber'
					type='text'
					inputHintText='Número de cuenta'
					value={bankData.accountNumber}
					onChange={handleChange}
					inputSize='small'
					error={Boolean(errors.accountNumber)}
					errorText={errors.accountNumber}
					marginTop={'5px'}
					isNumber
				/>
				<CustomSelectInputSearch
					options={accountTypesModified}
					title={'Tipo de cuenta'}
					handleChange={handleAccountTypeChange}
					value={bankData.typeAccountText}
					isDisabled={Boolean(bankData.bankCountry === '')}
					error={Boolean(errors.typeAccountText)}
					errorText={errors.typeAccountText}
				/>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={72}
			/>
		</>
	);
};

AddBankAccountStripe.propTypes = {
	activeStep: PropTypes.number.isRequired,
	bankData: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	setBankData: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
};

export default AddBankAccountStripe;
