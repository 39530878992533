import { enqueueSnackbar } from './notificationActions';

// Axios interceptor import
import AxiosInterceptorRequest from '../../fuctions/AxiosInterceptor';
import { saveCards } from './transactionsActions';

const stripeApiUrl = process.env.REACT_APP_BACKEND_URL;

// Stripe send deposit to backend
export const sendStripeDeposit = (paymentData) => {
	return (dispatch) => {
		console.log(paymentData);

		const sendStripeDepositConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashin/insertPaymentStripe',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: paymentData,
		};

		AxiosInterceptorRequest(sendStripeDepositConfig)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log('Error: ', err);
			});
	};
};

// Stripe desposit
export const stripeDeposit = (stripe, elements, userId) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		stripe
			.confirmPayment({
				elements,
				confirmParams: {
					return_url: 'https://leonardomedina.com.ec',
				},
				redirect: 'if_required',
			})
			.then((res) => {
				console.log(res);

				if (
					res?.error?.type === 'validation_error' ||
					res?.error?.type === 'invalid_request_error' ||
					res?.error?.type === 'card_error'
				) {
					dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

					dispatch(
						enqueueSnackbar({
							message:
								res?.error?.message ||
								'El pago ha fallado. Inténtalo nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				dispatch(
					enqueueSnackbar({
						message: 'Transacción exitosa',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);

				const paymentInfo = {
					amount: res.paymentIntent.amount / 100,
					currency: res.paymentIntent.currency,
					payment_method: res.paymentIntent.payment_method,
					description: res.paymentIntent.description,
					userId,
				};

				dispatch(sendStripeDeposit(paymentInfo));

				dispatch({ type: 'STRIPE_TRANSACTION_DEPOSIT_MONEY_SUCCESS' });
				dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'El pago ha fallado. Inténtalo nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			});
	};
};

export const stripeDepositSavedCard = (paymentInfo) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		console.log(paymentInfo);

		fetch(`${stripeApiUrl}/stripe/v1/chargeSavedCard`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				accountId: paymentInfo.accountId,
				amount: paymentInfo.amount * 100,
				currency: paymentInfo.currency,
				customer: paymentInfo.customer,
				paymentMethod: paymentInfo.paymentMethod,
				description: paymentInfo.description,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);

				const { error, message } = data;

				if (error) {
					const erroMsg =
						message.code === 'amount_too_small'
							? 'El monto mínimo es $0.50. Inténtalo nuevamente.'
							: 'Se ha producido un error. Inténtalo nuevamente.';

					dispatch(
						enqueueSnackbar({
							message: erroMsg,
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

					return;
				}

				dispatch(sendStripeDeposit(paymentInfo));

				dispatch(
					enqueueSnackbar({
						message: 'Transacción exitosa',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_DEPOSIT_MONEY_SUCCESS' });

				dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'El pago ha fallado. Inténtalo nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			});
	};
};

// Save customId on BDD
export const saveCustomerId = (customerId) => {
	return (dispatch) => {
		const saveCustomerConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'user/addStripeParams',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: {
				customerId,
			},
		};

		AxiosInterceptorRequest(saveCustomerConfig)
			.then((res) => {
				console.log(res);

				dispatch({
					type: 'UPDATE_USER_SUCCESS',
					payload: res.data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

// Stripe add card
export const stripeAddCardFirstime = (user, paymentMethod) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		// Fetch create customer
		fetch(`${stripeApiUrl}/stripe/v1/createCustomer`, {
			// fetch(`${stripeApiUrl}/stripe/v1/payment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				paymentMethod,
				name: user.name,
				email: user.email,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log(res);

				if (res?.error) {
					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténtalo nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				dispatch(saveCustomerId(res?.message?.id));

				dispatch(
					enqueueSnackbar({
						message: 'Tarjeta agregada exitosamente',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_CREACTE_USER_SUCCESS' });
				dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'Se ha producido un error. Inténtalo nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			});
	};
};

export const attatchPaymentMethod = (customerId, paymentMethod) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/attachPaymentMethods`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				payment_method: paymentMethod,
				customer: customerId,
			}),
		})
			.then((res) => {
				console.log(res);

				if (res?.error) {
					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténtalo nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				dispatch(
					enqueueSnackbar({
						message: 'Tarjeta agregada exitosamente',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_CARD_ADD_SUCCESS' });
				dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'Se ha producido un error. Inténtalo nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			});
	};
};

// Stripe create payment method
export const stripeCreatePaymentMethod = (data) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		const { stripe, card, user, customerID, billingAddress } = data;

		const { name, email } = user;

		// Create payment method
		stripe
			.createPaymentMethod({
				type: 'card',
				card,
				billing_details: {
					name,
					email,
				},
			})
			.then((res) => {
				const { paymentMethod, error } = res;

				console.log(res);

				if (error) {
					dispatch({
						type: 'STRIPE_TRANSACTION_ERROR',
						payload: error.message,
					});

					return;
				}

				if (customerID) {
					console.log(customerID);

					dispatch(attatchPaymentMethod(customerID, paymentMethod.id));

					return { billingAddress: billingAddress, token: paymentMethod.id };
				}

				dispatch(stripeAddCardFirstime(user, paymentMethod.id));
				return { billingAddress: billingAddress, token: paymentMethod.id };
			})
			.then((res) => {
				console.log(res);

				dispatch(saveCards(res));
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'Se ha producido un error. Inténta nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 500);
			});
	};
};

// Get user cards
export const getStripeUserCards = (customerID) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_GET_CARDS_START' });

		const userCardsConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/stripe/v1/listPaymentMethods',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: JSON.stringify({ customer: customerID }),
		};

		AxiosInterceptorRequest(userCardsConfig)
			.then((res) => {
				console.log(res);

				if (res.status !== 201) {
					// if (res.data.error) {
					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténta nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					dispatch({ type: 'STRIPE_TRANSACTION_END' });

					return;
				}

				dispatch({
					type: 'STIPE_TRANSACTION_GET_CARDS_SUCCESS',
					payload: res.data.message.data,
				});
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
				}, 1500);
			})
			.catch((err) => {
				console.log(err);

				dispatch(
					enqueueSnackbar({
						message: 'Se ha producido un error. Inténta nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_END' });
			});
	};
};

// Session verification
export const createStripeVerificationSession = () => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/createVerificationSession`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);

				dispatch({
					type: 'STRIPE_CLIENT_SECRET_SUCCESS',
					payload: data.message,
				});

				// dispatch({ type: 'STRIPE_TRANSACTION_CLIENT_SECRET_SUCCESS' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'STRIPE_TRANSACTION_CLIENT_SECRET_SUCCESS' });
			});
	};
};

export const stripeValidationCompleted = () => {
	return (dispatch) => {
		// dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
		dispatch({ type: 'STRIPE_TRANSACTION_CLIENT_SECRET_SUCCESS' });

		setTimeout(() => {
			dispatch({ type: 'STRIPE_TRANSACTION_END' });
		}, 500);
	};
};

// Add external Stripe Account
export const stripeExternalAccount = (externalAccountData) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/externalAccount`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				account: externalAccountData.account,
				bankToken: externalAccountData.bankToken,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log('ExternalAccount: ', data);

				if (!data.error) {
					dispatch({ type: 'ADD_BANK_ACCOUNT_SUCCESS' });
					dispatch({ type: 'STRIPE_TRANSACTION_END' });

					dispatch(
						enqueueSnackbar({
							message: 'Cuenta agregada correctamente',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'success',
							},
						})
					);

					setTimeout(() => {
						dispatch({ type: 'ADD_BANK_ACCOUNT_END' });
					}, 3000);

					return;
				}

				let erroMsg = '';

				dispatch({ type: 'STRIPE_TRANSACTION_END' });

				if (data.error) {
					erroMsg = 'Se ha producido un error. Inténta nuevamente.';
				}

				dispatch(
					enqueueSnackbar({
						message: erroMsg,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });
			});
	};
};

// Sstripe bank Token
export const stripeBankToken = (bankTokenData) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/createBankToken`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				accountHolderName: bankTokenData.accountHolderName,
				accountNumber: bankTokenData.accountNumber,
				country: bankTokenData.country,
				currency: bankTokenData.currency,
				routingNumber: bankTokenData.routingNumber,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log('BankToken: ', data);

				if (!data.error) {
					const externalAccountData = {
						account: bankTokenData.accountId,
						bankToken: data.message.id,
					};

					dispatch(stripeExternalAccount(externalAccountData));

					setTimeout(() => {
						dispatch({ type: 'ADD_BANK_ACCOUNT_END' });
					}, 3000);

					return;
				}

				let erroMsg = '';

				dispatch({ type: 'STRIPE_TRANSACTION_END' });

				if (data.error) {
					if (data.message.code === 'routing_number_invalid') {
						erroMsg = 'El número de ruta no es válido.';
					} else if (data.message.code === 'account_number_invalid') {
						erroMsg = 'El número de cuenta no es válido.';
					} else {
						erroMsg = 'Se ha producido un error. Inténta nuevamente.';
					}
				}

				dispatch(
					enqueueSnackbar({
						message: erroMsg,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });
			});
	};
};

// Save customer accountId on BDD
export const saveCustomerAccountId = (accountId) => {
	return (dispatch) => {
		const saveCustomerConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'user/addStripeParams',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: {
				accountId,
			},
		};

		AxiosInterceptorRequest(saveCustomerConfig)
			.then((res) => {
				console.log(res);

				dispatch({
					type: 'UPDATE_USER_SUCCESS',
					payload: res.data.data,
				});

				dispatch({ type: 'STRIPE_TRANSACTION_END' });
				dispatch({ type: 'ADD_BANK_ACCOUNT_SUCCESS' });
				setTimeout(() => {
					dispatch({ type: 'ADD_BANK_ACCOUNT_END' });
				}, 3000);

				dispatch(
					enqueueSnackbar({
						message: 'Cuenta agregada correctamente',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);

				dispatch({ type: 'STRIPE_TRANSACTION_END' });
				dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });

				dispatch(
					enqueueSnackbar({
						message: 'Se ha producido un error. Inténta nuevamente.',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			});
	};
};

// Add bank account stripe
export const addStripeBankAccount = (bankData) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		console.log(bankData);

		const holderName = `${bankData.firstName} ${bankData.lastName}`;

		fetch(`${stripeApiUrl}/stripe/v1/createBankToken`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				accountHolderName: holderName,
				accountNumber: bankData.accountNumber,
				country: bankData.accountCountry,
				currency: bankData.currency,
				routingNumber: bankData.routingNumber.toString().toUpperCase(),
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log('BankToken: ', data);

				const {
					error,
					message: { id: bankToken },
				} = data;

				if (error) {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
					dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });

					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténta nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				fetch(`${stripeApiUrl}/stripe/v1/createAccount`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						accountCountry: bankData.accountCountry,
						addressCity: bankData.addressCity,
						addressCountry: bankData.addressCountry,
						addressLine1: bankData.addressLine1,
						addressPostalCode: bankData.addressPostalCode,
						addressState: bankData.addressState,
						bankAccountToken: bankToken,
						dayOfBirth: bankData.dayOfBirth,
						documentId: bankData.documentId,
						email: bankData.email,
						firstName: bankData.firstName,
						lastName: bankData.lastName,
						monthOfBirth: bankData.monthOfBirth,
						yearOfBirth: bankData.yearOfBirth,
					}),
				})
					.then((res) => res.json())
					.then((data) => {
						console.log('AccountCreated: ', data);

						const { error: accountError, message: accountMessage } = data;

						if (accountError) {
							dispatch({ type: 'STRIPE_TRANSACTION_END' });
							dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });

							dispatch(
								enqueueSnackbar({
									message: 'Se ha producido un error. Inténta nuevamente.',
									options: {
										key: new Date().getTime() + Math.random(),
										variant: 'error',
									},
								})
							);
							return;
						}

						dispatch(saveCustomerAccountId(accountMessage.id));
					});
			});
	};
};

// Add new bank account
export const addNewExternalAccount = (bankData) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/createBankToken`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				accountHolderName: bankData.accountHolderName,
				accountNumber: bankData.accountNumber,
				country: bankData.accountCountry,
				currency: bankData.currency,
				routingNumber: bankData.routingNumber.toString().toUpperCase(),
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);

				const { error, message } = data;

				if (error) {
					dispatch({ type: 'STRIPE_TRANSACTION_END' });
					dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR' });

					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténta nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				fetch(`${stripeApiUrl}/stripe/v1/externalAccount`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						account: bankData.accountId,
						bankToken: message.id,
					}),
				})
					.then((res) => res.json())
					.then((respData) => {
						console.log(respData);

						const { error } = respData;

						if (error) {
							dispatch({ type: 'STRIPE_TRANSACTION_END' });

							dispatch(
								enqueueSnackbar({
									message: 'Se ha producido un error. Inténta nuevamente.',
									options: {
										key: new Date().getTime() + Math.random(),
										variant: 'error',
									},
								})
							);

							return;
						}

						dispatch({ type: 'ADD_BANK_ACCOUNT_SUCCESS' });
						dispatch({ type: 'STRIPE_TRANSACTION_END' });

						dispatch(
							enqueueSnackbar({
								message: 'Cuenta agregada correctamente',
								options: {
									key: new Date().getTime() + Math.random(),
									variant: 'success',
								},
							})
						);

						setTimeout(() => {
							dispatch({ type: 'ADD_BANK_ACCOUNT_END' });
						}, 3000);
					});
			});
	};
};

// Get stripe user accounts
export const getStripeUserAccounts = (accountId) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/getStripeUserAccounts`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ accountId }),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);

				const { error, message } = data;

				if (error) {
					dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

					dispatch(
						enqueueSnackbar({
							message: 'Se ha producido un error. Inténta nuevamente.',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				dispatch({
					type: 'STRIPE_TRANSACTION_GET_ACCOUNTS_SUCCESS',
					payload: message.data,
				});

				dispatch({ type: 'STRIPE_TRANSACTION_END' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'STRIPE_TRANSACTION_END' });
			});
	};
};

// Stripe payout
export const stripePayout = (payoutData) => {
	return (dispatch) => {
		dispatch({ type: 'STRIPE_TRANSACTION_START' });

		fetch(`${stripeApiUrl}/stripe/v1/payout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...payoutData,
				amount: payoutData.amount * 100,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);

				const { error, message } = data;

				if (error) {
					let errorMsj = '';

					dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

					if (message.code === 'balance_insufficient') {
						errorMsj = 'Los fondos son insuficientes.';
					} else {
						errorMsj = 'Se ha producido un error. Inténta nuevamente.';
					}

					dispatch(
						enqueueSnackbar({
							message: errorMsj,
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}

				dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
				dispatch({ type: 'TRANSACTION_WITHDRAW_MONEY_IN_SUCCESS' });

				dispatch(
					enqueueSnackbar({
						message: 'Retiro realizado exitosamente',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_END' });
				setTimeout(() => {
					dispatch({ type: 'TRANSACTION_END' });
				}, 1000);

				console.log(message);
				return message;
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'STRIPE_TRANSACTION_END' });
			});
	};
};
