import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';
import CustomInput from '../../../../../layout/CustomInput';
import StepperIndicator from '../../../../../shared/StepperIndicator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	overflow: 'auto',
	maxHeight: 'calc(100vh / 2)',
	minHeight: 'calc(100vh / 3)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
});

const RequestMoneyInput = (props) => {
	// Props destructuring
	const {
		activeStep,
		errors,
		setTransactionData,
		totalSteps,
		transactionData,
	} = props;

	// Component functions
	const handleAmmountChange = (e) => {
		const inputValue = e.target.value;
		const regex = /^\d+(\.\d{0,2})?$/;

		if (inputValue === '' || regex.test(inputValue)) {
			setTransactionData({
				...transactionData,
				amount: inputValue,
			});
		}
	};

	const handleChange = (e) => {
		setTransactionData({
			...transactionData,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2'>
					Ingresa el monto a solicitar
				</SectionTitle>
				<CustomInput
					name='amount'
					type='text'
					inputHintText={'Ej: $ 100.00'}
					value={transactionData.amount}
					onChange={handleAmmountChange}
					isCentered
					hasAdornment={Boolean(transactionData.amount !== '')}
					error={Boolean(errors.amount)}
					errorText={errors.amount}
					inputSize={'small'}
					isNumber
				/>
				<SectionTitle variant='body2'>Comentarios</SectionTitle>
				<CustomInput
					name='comment'
					type='textarea'
					value={transactionData.comment}
					onChange={handleChange}
					inputSize={'small'}
					multiline
					inputHintText={'Ej: Pago de servicios'}
					error={Boolean(errors.comment)}
					errorText={errors.comment}
					marginTop={'0.3rem'}
				/>
				<Box sx={{ height: 30 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

RequestMoneyInput.propTypes = {
	activeStep: PropTypes.number.isRequired,
	errors: PropTypes.object.isRequired,
	setTransactionData: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	transactionData: PropTypes.object.isRequired,
};

export default RequestMoneyInput;
