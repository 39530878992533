const initState = {
	loadingTicketMessages: false,
	ticketsList: [],
	selectedTicket: {},
	ticketHistory: [],
	addMessageError: '',
};

const messagesReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_TICKET_BY_ID_START':
		case 'CREATE_TICKET_START':
		case 'ADD_MESSAGE_ACTION_START':
		case 'GET_TICKETS_BY_USER_ID_START':
			return {
				...state,
				loadingTicketMessages: true,
			};
		// Success
		case 'GET_TICKETS_BY_USER_ID_SUCCESS':
			return {
				...state,
				loadingTicketMessages: false,
				ticketsList: action.payload,
			};
		case 'GET_TICKET_BY_ID_SUCCESS':
		case 'ADD_MESSAGE_TICKET_SUCCESS':
		case 'CHANGE_TICKET_STATUS_ERROR':
			return {
				...state,
				loadingTicketMessages: false,
				ticketHistory: action.payload,
			};
		case 'SET_SELECTED_TICKET':
			return {
				...state,
				selectedTicket: action.payload,
			};
		case 'CREATE_TICKET_SUCCESS':
			return {
				...state,
				selectedTicket: {
					id: action.payload.id,
					subject: action.payload.subject,
					user: action.payload.user,
					status: 'Nuevo',
					created_at: action.payload.createdAt,
					updated_at: action.payload.updatedAt,
				},
			};
		// Error
		case 'GET_TICKETS_BY_USER_ID_ERROR':
		case 'GET_TICKET_BY_ID_ERROR':
		case 'CHANGE_TICKET_STATUS_SUCCESS':
			return {
				...state,
				loadingTicketMessages: false,
			};
		case 'CREATE_TICKET_ERROR':
		case 'ADD_MESSAGE_TICKET_ERROR':
			return {
				...state,
				loading: false,
				addMessageError: action.payload,
			};
		// Clear
		case 'CLEAR_SELECTED_TICKET':
			if (Object.keys(state.selectedTicket)?.length > 0) {
				return {
					...state,
					selectedTicket: {},
					ticketHistory: [],
				};
			} else {
				return {
					...state,
					loadingTicketMessages: false,
				};
			}
		case 'RESET_MESSAGE_ERROR':
			return {
				...state,
				loading: false,
				closeUpdateUserDialog: false,
				addMessageError: '',
			};
		// Default
		default:
			return state;
	}
};

export default messagesReducer;
