import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import {
	AppBar,
	Box,
	Button,
	Dialog,
	IconButton,
	Slide,
	Toolbar,
} from '@mui/material';

// Local Components Imports
import CustomIcon from '../../../../../shared/CustomIcon';
import Logo from '../../../../../shared/Logo';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const PaymentModal = (props) => {
	// Props destructuring
	const { open, handleClose, modalContent } = props;

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={handleClose}
			TransitionComponent={Transition}
			sx={{ maxWidth: '100vw' }}
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar sx={{ bgcolor: '#fff' }}>
					<IconButton
						edge='start'
						color='inherit'
						onClick={handleClose}
						aria-label='close'
					>
						<CustomIcon iconName={'faCircleXmark'} iconColor={'#000'} />
					</IconButton>
					<Box sx={{ ml: 2, flex: 1 }}>
						<Logo logoHeight={'50px'} />
					</Box>
					<Button
						autoFocus
						color='inherit'
						onClick={handleClose}
						variant='outlined'
					>
						Cancelar
					</Button>
				</Toolbar>
			</AppBar>
			{modalContent}
		</Dialog>
	);
};

PaymentModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	modalContent: PropTypes.object,
};

export default PaymentModal;
