// Axios interceptor import
import AxiosInterceptorRequest from '../../fuctions/AxiosInterceptor';

// Notification imports
import { enqueueSnackbar } from './notificationActions';

// Get User's profile.
export const getUserCards = () => {
	return (dispatch) => {
		dispatch({ type: 'USER_CARDS_TRANSACTION_START' });

		const getUserCardsConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `cashin/cards`,
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getUserCardsConfig).then((resp) => {
			if (resp?.status !== 201) {
				dispatch({ type: 'GET_USER_CARDS_ERROR' });

				dispatch(
					enqueueSnackbar({
						message: 'Error al obtener las tarjetas',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				return;
			}

			dispatch({
				type: 'GET_USER_CARDS_SUCCESS',
				payload: resp?.data?.data,
			});
		});
	};
};
