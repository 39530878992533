const initState = {
	nextStep: false,
	cardsList: [],
	loading: false,
	errorText: '',
	session: null,
	userExternalAccounts: [],
};

const stripeReducer = (state = initState, action) => {
	switch (action.type) {
		case 'STRIPE_TRANSACTION_START':
			return {
				...state,
				errorText: '',
			};
		case 'STRIPE_TRANSACTION_SUCCESS':
			return {
				...state,
				nextStep: true,
			};
		case 'STRIPE_GET_CARDS_START':
			return {
				...state,
				loading: true,
			};
		case 'STRIPE_TRANSACTION_GET_ACCOUNTS_SUCCESS':
			return {
				...state,
				userExternalAccounts: action.payload || [],
			};
		case 'STIPE_TRANSACTION_GET_CARDS_SUCCESS':
			return {
				...state,
				cardsList: action.payload || [],
				loading: false,
			};
		case 'STRIPE_CLIENT_SECRET_SUCCESS':
			return {
				...state,
				session: action.payload,
			};
		case 'STRIPE_TRANSACTION_ERROR':
			return {
				...state,
				errorText: action.payload,
			};
		case 'STRIPE_TRANSACTION_END':
			return {
				...state,
				loading: false,
				nextStep: false,
			};
		default:
			return state;
	}
};

export default stripeReducer;
