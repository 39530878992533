import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Styled Components
const StepsContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '0.5rem',
	width: '100%',
	backgroundColor: '#fff',
	height: '40px',
	zIndex: '3',
	position: (props) => (props.bottom ? 'absolute' : 'none'),
	bottom: (props) => (props.bottom ? `${props.bottom}px` : 'none'),
});

const Step = styled(Box)({
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const StepIndicator = styled(Box)({
	width: (props) => (props.activestep === props.stepcount ? '10px' : '9px'),
	height: (props) => (props.activestep === props.stepcount ? '10px' : '9px'),
	borderRadius: '50%',
	backgroundColor: (props) =>
		props.activestep === props.stepcount ? 'var(--primary)' : '#000',
	opacity: (props) => (props.activestep === props.stepcount ? '1' : '0.25'),
});

const StepperIndicator = (props) => {
	// Props destructuring
	const { activeStep, bottom, totalSteps } = props;

	return (
		<StepsContainer bottom={bottom}>
			{totalSteps.map((step) => (
				<Step key={step}>
					<StepIndicator activestep={activeStep} stepcount={step} />
				</Step>
			))}
		</StepsContainer>
	);
};

StepperIndicator.propTypes = {
	activeStep: PropTypes.number.isRequired,
	totalSteps: PropTypes.array.isRequired,
	bottom: PropTypes.number,
};

export default StepperIndicator;
