import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, CircularProgress, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '3rem',
	marginBottom: '3rem',
});

const CustomLoadingText = styled(Typography)({
	color: (props) => `${props.textcolor} !important`,
	marginTop: '1rem',
});

const Loading = (props) => {
	// Props destructuring
	const { loadingText, textColor } = props;

	return (
		<Wrapper>
			<CircularProgress sx={{ mb: 2 }} />
			<CustomLoadingText variant='body2' align='center' textcolor={textColor}>
				{loadingText}
			</CustomLoadingText>
		</Wrapper>
	);
};

Loading.defaultProps = {
	loadingText: 'Cargando...',
	textColor: 'var(--dark-text)',
};

Loading.propTypes = {
	loadingText: PropTypes.string,
	textColor: PropTypes.string,
};

export default Loading;
