// Axios imports
import axios from 'axios';

const client = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	json: true,
});

const AxiosInterceptorRequest = async ({ ...options }) => {
	client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
		'accessToken'
	)}`;

	try {
		const response = await client(options);
		return response;
	} catch (error) {
		// console.log(error);

		// if (error?.response?.status !== 403) { // caducado o no forbiden
		// if (error?.response?.status === 500 && error?.response?.status !== 401) {
		// 	localStorage.removeItem('accessToken');
		// 	window.location.href = '/sesion-otro-dispositivo';
		// }

		// if (error?.response?.status === 401) {
		// 	localStorage.removeItem('accessToken');
		// 	window.location.href = '/usuario-inactivo';
		// }

		return error;
	}
};

export default AxiosInterceptorRequest;
