import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Grid } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomBtn from '../layout/CustomBtn';

// Styled Components
const BtnsContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingBottom: (props) => props.paddingvertical,
	width: (props) => props.btnsfullwidth === 'true' && '100% !important',
	paddingLeft: (props) =>
		props.btnsfullwidth === 'true' ? '1rem !important' : '0',
	paddingRight: (props) =>
		props.btnsfullwidth === 'true' ? '1rem !important' : '0',
});

const BtnsGrid = styled(Grid)({
	maxWidth: '400px',
});

const TwoBtnsGrid = (props) => {
	// Props destructuring
	const {
		leftBtnAction,
		leftBtnText,
		leftBtnType,
		leftIcon,
		leftLoadingText,
		isLeftBtnDisabled,
		isRightBtnDisabled,
		rightBtnAction,
		rightBtnText,
		rightBtnType,
		rightLoadingText,
		paddingY,
		btnsFullWidth,
	} = props;

	return (
		<BtnsContainer paddingvertical={paddingY} btnsfullwidth={btnsFullWidth}>
			<BtnsGrid container spacing={2}>
				<Grid item xs={6}>
					<CustomBtn
						btnText={leftBtnText}
						btnType={leftBtnType}
						isDisabled={isLeftBtnDisabled}
						btnHoverColor='transparent'
						btnTextHoverColor='var(--secondary)'
						btnLoadingText={leftLoadingText}
						fontSize='1.2rem'
						onClick={leftBtnAction}
						variant='outlined'
						btnColor='transparent'
						startIcon={leftIcon}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomBtn
						btnText={rightBtnText}
						btnType={rightBtnType}
						isDisabled={isRightBtnDisabled}
						btnLoadingText={rightLoadingText}
						fontSize='1.2rem'
						onClick={rightBtnAction}
					/>
				</Grid>
			</BtnsGrid>
		</BtnsContainer>
	);
};

TwoBtnsGrid.propTypes = {
	leftBtnAction: PropTypes.func,
	leftBtnText: PropTypes.string,
	leftBtnType: PropTypes.string,
	leftIcon: PropTypes.string,
	leftLoadingText: PropTypes.string,
	rightBtnAction: PropTypes.func,
	rightBtnText: PropTypes.string,
	rightBtnType: PropTypes.string,
	rightLoadingText: PropTypes.string,
	isLeftBtnDisabled: PropTypes.bool,
	isRightBtnDisabled: PropTypes.bool,
	paddingY: PropTypes.string,
	btnsFullWidth: PropTypes.string,
};

export default TwoBtnsGrid;
