import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomDialog from '../../../../../layout/CustomDialog';
import TwoBtnsGrid from '../../../../../shared/TwoBtnsGrid';
import WithdrawMoneyStepOne from './WithdrawMoneyStepOne';
import WithdrawMoneyStepThree from './WithdrawMoneyStepThree';

// Redux Actions Imports
import {
	changeInnerRoute,
	clearInnerRoute,
} from '../../../../../store/actions/innerRouterActions';
import {
	updateUserBalance,
	getUserAccount,
	getUserTransactions,
	getWithdrawMoneyValidationCode,
	getSwitchService,
} from '../../../../../store/actions/transactionsActions';
import {
	getUserAccounts,
	removeUserAccounts,
	showHideAddAccountForm,
} from '../../../../../store/actions/bankAccountsActions';

// Validation
import WithdrawValidation from './Validation/WithdrawValidation';
import WithdrawMoneyStepTwo from './WithdrawMoneyStepTwo';
import { getStripeUserAccounts } from '../../../../../store/actions/stripeActions';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
	minHeight: '12rem',
});

const WithdrawMoney = (props) => {
	// Props destructuring
	const {
		balance,
		changeInnerRoute,
		clearInnerRoute,
		configurationByCountry,
		getUserAccount,
		getUserAccounts,
		getStripeUserAccounts,
		getSwitchService,
		getUserTransactions,
		getWithdrawMoneyValidationCode,
		openDialog,
		removeUserAccounts,
		setOpenDialog,
		showAddAccountForm,
		showHideAddAccountForm,
		updateUserBalance,
		userBankAccounts,
		userExternalAccounts,
		userProfile,
		withdrawStepStatus,
		withdrawValidationCode,
	} = props;

	// Component state
	const [activeStep, setActiveStep] = useState(0);
	const [transactionData, setTransactionData] = useState({
		amount: '',
		bankAccountSelected: '',
		idAccount: '',
		bankAccount: '',
		bankAccountNumber: '',
		bankAccountType: '',
		country: '',
		message: '',
	});
	const [aditionalData, setAditionalData] = useState({
		commission: '% 0.00',
		commissionValue: 0,
		commissionAmount: '$ 0.00',
		feeValue: 0,
		feeWithdraw: '$ 0.00',
		total: '$ 0.00',
	});
	const [maxWithdraw, setMaxWithdraw] = useState(0);
	const [minWithdraw, setMinWithdraw] = useState(0);
	const [commissionConfig, setCommissionConfig] = useState(1);

	const [errors, setErrors] = useState({});

	// Component hooks
	useEffect(() => {
		if (openDialog) {
			getUserAccounts();
		}
	}, [
		openDialog,
		getUserAccounts,
		getStripeUserAccounts,
		userProfile.accountId,
	]);

	useEffect(() => {
		if (openDialog && configurationByCountry?.percentWithdraw) {
			setMaxWithdraw(configurationByCountry.maxWithdraw);
			setMinWithdraw(configurationByCountry.minWithdraw);
			setCommissionConfig(configurationByCountry.commissionDirection);

			setAditionalData({
				...aditionalData,
				commission: ` ${configurationByCountry.percentWithdraw.toFixed(2)} %`,
				commissionValue: configurationByCountry?.percentWithdraw / 100,
				feeValue: configurationByCountry?.feeWithdraw,
				feeWithdraw: `$ ${configurationByCountry?.feeWithdraw.toFixed(2)}`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationByCountry, openDialog]);

	useEffect(() => {
		if (showAddAccountForm) {
			setOpenDialog(false);
		}
	}, [showAddAccountForm, setOpenDialog]);

	useEffect(() => {
		if (withdrawStepStatus) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withdrawStepStatus]);

	useEffect(() => {
		if (withdrawValidationCode !== '') {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [withdrawValidationCode]);

	const totalSteps = [0, 1, 2];

	const withdrawSteps = [
		{
			title: 'Retirar dinero',
			content: (
				<Wrapper>
					<WithdrawMoneyStepOne
						activeStep={activeStep}
						aditionalData={aditionalData}
						commissionConfig={commissionConfig}
						errors={errors}
						setTransactionData={setTransactionData}
						setAditionalData={setAditionalData}
						totalSteps={totalSteps}
						transactionData={transactionData}
						userBankAccounts={userBankAccounts}
						userExternalAccounts={userExternalAccounts}
						showHideAddAccountForm={showHideAddAccountForm}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Validando transacción',
			content: (
				<Wrapper>
					<WithdrawMoneyStepTwo
						activeStep={activeStep}
						totalSteps={totalSteps}
						withdrawValidationCode={withdrawValidationCode}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Confirmación de retiro',
			content: (
				<Wrapper>
					<WithdrawMoneyStepThree
						activeStep={activeStep}
						totalSteps={totalSteps}
					/>
				</Wrapper>
			),
		},
	];

	// Component functions
	const resetState = () => {
		setTransactionData({
			amount: '',
			bankAccountSelected: '',
			idAccount: '',
			bankAccount: '',
			bankAccountNumber: '',
			bankAccountType: '',
			country: '',
			message: '',
		});
		setAditionalData({
			commission: '% 0.00',
			commissionValue: 0,
			commissionAmount: '$ 0.00',
			feeValue: 0,
			feeWithdraw: '$ 0.00',
			total: '$ 0.00',
		});
		setErrors({});
		setActiveStep(0);
	};

	const handleClose = () => {
		setOpenDialog(false);
		clearInnerRoute();
		changeInnerRoute('account');
		resetState();

		if (activeStep !== 0) {
			getUserAccount();
			getUserTransactions();
			updateUserBalance();
		}
		removeUserAccounts();
	};

	const handleNext = () => {
		if (activeStep === 0) {
			if (
				Object.keys(
					WithdrawValidation(maxWithdraw, minWithdraw, balance, transactionData)
				).length === 0
			) {
				setErrors({});

				getWithdrawMoneyValidationCode();

				return;
			}

			setErrors(
				WithdrawValidation(maxWithdraw, minWithdraw, balance, transactionData)
			);
		} else if (activeStep === 1) {
			const commonData = {
				name: userProfile.firstName,
				lastname: userProfile.lastName,
				email: userProfile.email,
				identification: userProfile.documentId,
				bankAccount: transactionData.bankAccount,
				bankAccountNumber: transactionData.bankAccountNumber,
				idAccount: transactionData.idAccount,
				transactionValue: parseFloat(
					parseFloat(transactionData.amount).toFixed(2)
				),
				validationCode: Number(withdrawValidationCode),
			};

			const amazonasPayoutData = {
				...commonData,
				bankAccountType: transactionData.bankAccountType,
				country: 'EC',
				country_code: 180,
				description: transactionData.bankAccount,
				identificationType: 'CC',
				message: transactionData.message,
			};

			const stripePayoutData = {
				...commonData,
				account: userProfile.accountId,
				amount: transactionData.amount,
				bankDestination: transactionData.idAccount,
				currency: 'usd',
				description: transactionData.message, // Se guarda en comentarios
				destination: userProfile.accountId,
			};

			const dataToSend = {
				idAccount: transactionData.idAccount,
				amazonasPayoutData,
				stripePayoutData,
			};

			getSwitchService(dataToSend);
		} else if (activeStep === 2) {
			handleClose();
		}
	};

	const handleBack = () => {
		activeStep !== 0
			? setActiveStep((prevActiveStep) => prevActiveStep - 1)
			: handleClose();
	};

	const content = withdrawSteps[activeStep];

	return (
		<CustomDialog
			openDialog={openDialog}
			content={content}
			minHeight={'25rem'}
			onClose={handleClose}
			buttons={
				<TwoBtnsGrid
					leftBtnText={activeStep === 1 ? 'Atras' : 'Cancelar'}
					isLeftBtnDisabled={Boolean(activeStep === 2)}
					leftLoadingText={'Cancelar'}
					leftBtnAction={handleBack}
					rightBtnText={activeStep === 2 ? 'Aceptar' : 'Siguiente'}
					rightBtnAction={handleNext}
					isRightBtnDisabled={
						userBankAccounts.length <= 1 && userExternalAccounts.length === 0
					}
					rightLoadingText={'Siguiente'}
					rightBtnType={'button'}
					leftBtnType={'button'}
					paddingY={'0.5rem'}
					btnsFullWidth={'true'}
				/>
			}
		/>
	);
};

WithdrawMoney.defaultProps = {
	configurationByCountry: {},
	userProfile: {},
};

WithdrawMoney.propTypes = {
	balance: PropTypes.number.isRequired,
	changeInnerRoute: PropTypes.func.isRequired,
	clearInnerRoute: PropTypes.func.isRequired,
	configurationByCountry: PropTypes.object,
	getUserAccount: PropTypes.func.isRequired,
	getUserAccounts: PropTypes.func.isRequired,
	getStripeUserAccounts: PropTypes.func.isRequired,
	getSwitchService: PropTypes.func.isRequired,
	getUserTransactions: PropTypes.func.isRequired,
	getWithdrawMoneyValidationCode: PropTypes.func.isRequired,
	openDialog: PropTypes.bool.isRequired,
	removeUserAccounts: PropTypes.func.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	showAddAccountForm: PropTypes.bool.isRequired,
	showHideAddAccountForm: PropTypes.func.isRequired,
	updateUserBalance: PropTypes.func.isRequired,
	userBankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	userExternalAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	userProfile: PropTypes.object,
	withdrawStepStatus: PropTypes.bool.isRequired,
	withdrawValidationCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
	const { userProfile } = state.user;
	const { userBankAccounts, showAddAccountForm } = state.bankAccounts;
	const {
		balance,
		configurationByCountry,
		withdrawStepStatus,
		withdrawValidationCode,
	} = state.transactions;
	const { userExternalAccounts } = state.stripe;

	return {
		balance,
		configurationByCountry,
		showAddAccountForm,
		userBankAccounts,
		userExternalAccounts,
		userProfile,
		withdrawStepStatus,
		withdrawValidationCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		getUserAccount: () => dispatch(getUserAccount()),
		getUserAccounts: () => dispatch(getUserAccounts()),
		getStripeUserAccounts: (accountId) =>
			dispatch(getStripeUserAccounts(accountId)),
		getSwitchService: (idAccount) => dispatch(getSwitchService(idAccount)),
		getUserTransactions: () => dispatch(getUserTransactions()),
		getWithdrawMoneyValidationCode: () =>
			dispatch(getWithdrawMoneyValidationCode()),
		removeUserAccounts: () => dispatch(removeUserAccounts()),
		showHideAddAccountForm: () => dispatch(showHideAddAccountForm()),
		updateUserBalance: () => dispatch(updateUserBalance()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawMoney);
