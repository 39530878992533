import PropTypes from 'prop-types';

// MUI Imports
import { Box, CircularProgress, Typography } from '@mui/material';

// Styled Components imports
import { styled } from 'styled-components';

// Local Components imports
import Logo from './Logo';

// Styled Components
const LoadingContainer = styled(Box)({
	width: '100%',
	height: (props) => props.height,
	minHeight: (props) => props.height,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});

const LoadingText = styled(Typography)({
	marginTop: '0.5rem !important',
});

const PageLoading = (props) => {
	// Props destructuring
	const { hasLoadingText, height, loadingText, spinnerSize } = props;

	return (
		<LoadingContainer height={height}>
			<CircularProgress size={spinnerSize} />
			<Logo logoHeight='60px' />
			{hasLoadingText && (
				<LoadingText variant='body2' color='text.secondary'>
					{loadingText}
				</LoadingText>
			)}
		</LoadingContainer>
	);
};

PageLoading.defaultProps = {
	height: '100%',
	spinnerSize: 40,
	loadingText: 'Cargando...',
	hasLoadingText: true,
};

PageLoading.propTypes = {
	height: PropTypes.string,
	loadingText: PropTypes.string,
	hasLoadingText: PropTypes.bool,
	spinnerSize: PropTypes.number,
};

export default PageLoading;
