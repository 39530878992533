import { combineReducers } from 'redux';
import appOptionsReducer from './appOptionsReducer';
import authReducer from './authReducer';
import bankAccountsReducer from './bankAccountsReducer.js';
import innerRouterReducer from './innerRouterReducer';
import kycReducer from './kycReducer';
import messagesReducer from './messagesReducer';
import notificationsReducer from './notificationsReducer';
import stripeReducer from './stripeReducer.js';
import transactionsReducer from './transactionsReducer';
import userCardsReducer from './userCardsReducer.js';
import userReducer from './userReducer';
import { SIGNOUT } from '../actions/actions';

const appReducer = combineReducers({
	appOptions: appOptionsReducer,
	auth: authReducer,
	bankAccounts: bankAccountsReducer,
	innerRouter: innerRouterReducer,
	kyc: kycReducer,
	messages: messagesReducer,
	notifications: notificationsReducer,
	stripe: stripeReducer,
	transactions: transactionsReducer,
	user: userReducer,
	userCards: userCardsReducer,
});

const rootReducer = (state, action) => {
	if (action.type === SIGNOUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
