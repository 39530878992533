import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Redux Imports
import { connect } from 'react-redux';

// Material UI Imports
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Phone input CSS
import 'react-phone-input-2/lib/style.css';

// CSS Imports
import './App.css';
import './App-desktop.css';
import './App-mobile.css';
import './Modified-mui.css';

// Theme
import theme from './layout/Theme';

// Router
import Router from './routes/Router';
import BottomNavBar from './layout/BottomNavBar';
import MenuBar from './layout/MenuBar';

// Local Imports
import UpdateDialog from './layout/UpdateDialog';
import useNotifier from './layout/useNotifier';

function App(props) {
	// Props destructuring
	const { accessToken, innerRoute, userProfile } = props;

	// Component route
	const actualRoute = innerRoute[innerRoute.length - 1];

	// Component Hooks
	const location = useLocation();
	useNotifier();

	// Force reload on mount
	useEffect(() => {
		// Check if the page was reloaded before to avoid infinite loop
		if (!sessionStorage.getItem('reloaded')) {
			sessionStorage.setItem('reloaded', 'true');
			window.location.reload(true);
		}
	}, []);

	return (
		<LocalizationProvider adapterLocale='es' dateAdapter={AdapterMoment}>
			<ThemeProvider theme={theme}>
				<>
					{/* Menu Bar */}
					{accessToken &&
						location.pathname !== '/bienvenida' &&
						userProfile !== null && <MenuBar pageTitle='Utransfer' />}

					{/* App router */}
					<Router />

					{/* Bottom Navigation */}
					{accessToken &&
						location.pathname !== '/bienvenida' &&
						userProfile !== null &&
						actualRoute !== 'message' && <BottomNavBar />}
					{/* <UpdateDialog /> */}
				</>
			</ThemeProvider>
		</LocalizationProvider>
	);
}

App.propTypes = {
	accessToken: PropTypes.string,
	innerRoute: PropTypes.array,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { accessToken } = state.auth;
	const { innerRoute } = state.innerRouter;
	const { userProfile } = state.user;

	return {
		accessToken,
		innerRoute,
		userProfile,
	};
};

export default connect(mapStateToProps)(App);
