import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// SafeArea Capacitor Plugin
import { SafeArea } from 'capacitor-plugin-safe-area';

// Device Detect
import { isIOS } from 'react-device-detect';

// Redux Actions Imports
import { removeSnackbar } from '../store/actions/notificationActions';

let displayed = [];

const useNotifier = () => {
	const dispatch = useDispatch();
	const notifications = useSelector(
		(store) => store.notifications.notifications || []
	);

	// Component State
	const [safeArea, setSafeArea] = useState({});

	useEffect(() => {
		SafeArea.getSafeAreaInsets().then(({ insets }) => {
			setSafeArea(insets);
		});
	}, []);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)];
	};

	useEffect(() => {
		notifications.forEach(
			({ key, message, options = {}, dismissed = false }) => {
				if (dismissed) {
					// dismiss snackbar using notistack
					closeSnackbar(key);
					return;
				}
				// do nothing if snackbar is already displayed
				if (displayed.includes(key)) return;

				// display snackbar using notistack
				enqueueSnackbar(message, {
					key,
					...options,
					SnackbarProps: {
						style: {
							top: isIOS ? `${safeArea?.top}px` : '14px',
						},
					},
					onClose: (event, reason, myKey) => {
						if (options.onClose) {
							options.onClose(event, reason, myKey);
						}
					},
					onExited: (event, myKey) => {
						// remove this snackbar from redux store
						dispatch(removeSnackbar(myKey));
						removeDisplayed(myKey);
					},
				});
				// keep track of snackbars that we've displayed
				storeDisplayed(key);
			}
		);
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch, safeArea]);
};

export default useNotifier;
