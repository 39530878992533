import React, { useEffect, useState } from 'react';

// MUI Imports
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Imports
import CustomBtn from './CustomBtn';
import Logo from './../shared/Logo';

// Styled Components
const CustomDialog = styled(Dialog)({
	'.MuiPaper-root': {
		borderRadius: '15px',
		maxHeight: '500px',
		maxWidth: '450px !important',
		minWidth: '450px !important',
		overflow: 'hidden',

		'@media (max-width: 768px)': {
			flexDirection: 'column',
			minWidth: '90vw !important',
			maxHeight: '80vh !important',
			minHeight: '60vh !important',
		},
	},
});

const CustomDialogTitle = styled(DialogTitle)({
	backgroundColor: 'var(--primary) !important',
	color: '#fff !important',
	paddingBottom: '0.5rem !important',
	height: '32px !important',
});

const UpdatesContainer = styled(Box)({
	paddingLeft: '1rem',
	marginTop: '1rem',

	'@media (max-width: 768px)': {
		paddingLeft: '0.5rem',
	},
});

const UpdateContainer = styled(Box)({
	display: 'flex',
});

const UpdateDialog = () => {
	// Component state
	const [updateAvailable, setUpdateAvailable] = useState(false);

	// Component hooks
	useEffect(() => {
		// Function to handle the updatefound event and set the updateAvailable flag
		const onSWUpdate = (registration) => {
			setUpdateAvailable(true);
		};

		if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
			// Register the service worker and listen for updatefound event
			navigator.serviceWorker
				.register('/service-worker.js')
				.then((registration) => {
					registration.addEventListener('updatefound', () =>
						onSWUpdate(registration)
					);
				});

			// Check for updates every 5 minutes
			const checkForUpdatesInterval = setInterval(
				() => {
					navigator.serviceWorker.getRegistration().then((registration) => {
						if (registration) {
							registration.update().catch((error) => {
								console.error('Error when updating the service worker:', error);
							});
						}
					});
				},
				5 * 60 * 1000
			); // 5 minutes interval in milliseconds

			return () => {
				// Clear the interval when the component unmounts
				clearInterval(checkForUpdatesInterval);
			};
		}
	}, []);

	// Component functions
	const handleRefresh = async () => {
		if ('serviceWorker' in navigator && navigator.serviceWorker?.controller) {
			const registration = await navigator.serviceWorker.getRegistration();
			if (registration?.waiting) {
				registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			}
			setUpdateAvailable(false);

			// Clean up LocalStorage
			localStorage.clear();

			// Clean up all caches
			if (caches?.keys) {
				const cacheNames = await caches.keys();
				await Promise.all(
					cacheNames.map(async (cacheName) => {
						await caches.delete(cacheName);
					})
				);
			}

			window.location.reload();
		}
	};

	const updatesList = [
		'Estamos emocionados de contarte que hemos lanzado una nueva versión de nuestra aplicación. ',
		'Hemos añadido características, mejorado la velocidad y la usabilidad para ofrecerte la mejor experiencia posible. 😊👍🏻',
	];

	return (
		<CustomDialog
			open={updateAvailable}
			aria-labelledby='app-update-dialog-title'
			aria-describedby='app-update-dialog-description'
		>
			<CustomDialogTitle id='app-update-dialog-title'>
				Nueva actualización disponible
			</CustomDialogTitle>
			<DialogContent dividers>
				<Logo logoHeight='50px' />

				<DialogContentText id='app-update-dialog-description' mt={2}>
					Estamos mejorando tu experiencia de usuario y por eso tenemos una
					nueva actualización.
				</DialogContentText>
				<UpdatesContainer>
					{updatesList.map((update, key) => (
						<UpdateContainer key={update}>
							<Typography
								sx={{
									lineHeight: 1.2,
									mb: 1.5,
									mr: 1,
									color: 'var(--primary)',
								}}
							>
								-
							</Typography>
							<Typography
								sx={{ lineHeight: 1.2, mb: 1.5, color: 'var(--secondary)' }}
							>
								{update}
							</Typography>
						</UpdateContainer>
					))}
				</UpdatesContainer>
				<DialogContentText sx={{ mt: 1 }}>
					Por favor recarga la página dando click a continuación para mantenerte
					al día con estas actualizaciones.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<CustomBtn
					btnText='Actualizar la app'
					btnType='button'
					width='180px'
					onClick={handleRefresh}
				/>
			</DialogActions>
		</CustomDialog>
	);
};

export default UpdateDialog;

// // React imports
// import React, { useEffect, useState } from 'react';

// // MUI Imports
// import {
// 	Box,
// 	Dialog,
// 	DialogActions,
// 	DialogContent,
// 	DialogContentText,
// 	DialogTitle,
// 	Typography,
// } from '@mui/material';

// // Styled Components imports
// import styled from 'styled-components';

// // Local Imports
// import CustomBtn from './CustomBtn';
// import Logo from './../shared/Logo';

// // Styled Components
// const CustomDialog = styled(Dialog)({
// 	'.MuiPaper-root': {
// 		borderRadius: '15px',
// 		maxHeight: '500px',
// 		maxWidth: '450px !important',
// 		minWidth: '450px !important',
// 		overflow: 'hidden',

// 		'@media (max-width: 768px)': {
// 			flexDirection: 'column',
// 			minWidth: '90vw !important',
// 			maxHeight: '80vh !important',
// 			minHeight: '60vh !important',
// 		},
// 	},
// });

// const CustomDialogTitle = styled(DialogTitle)({
// 	backgroundColor: 'var(--primary) !important',
// 	color: '#fff !important',
// 	paddingBottom: '0.5rem !important',
// 	height: '32px !important',
// });

// const UpdatesContainer = styled(Box)({
// 	paddingLeft: '1rem',
// 	marginTop: '1rem',

// 	'@media (max-width: 768px)': {
// 		paddingLeft: '0.5rem',
// 	},
// });

// const UpdateContainer = styled(Box)({
// 	display: 'flex',
// });

// const UpdateDialog = () => {
// 	// Component state
// 	const [updateAvailable, setUpdateAvailable] = useState(false);

// 	// Component hooks
// 	useEffect(() => {
// 		// Function to handle the updatefound event and set the updateAvailable flag
// 		const onSWUpdate = (registration) => {
// 			setUpdateAvailable(true);
// 		};

// 		if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
// 			// Register the service worker and listen for updatefound event
// 			navigator.serviceWorker
// 				.register('/service-worker.js')
// 				.then((registration) => {
// 					registration.addEventListener('updatefound', () =>
// 						onSWUpdate(registration)
// 					);
// 				});

// 			// Check for updates every 5 minutes
// 			const checkForUpdatesInterval = setInterval(
// 				() => {
// 					navigator.serviceWorker.getRegistration().then((registration) => {
// 						if (registration) {
// 							registration.update().catch((error) => {
// 								console.error('Error when updating the service worker:', error);
// 							});
// 						}
// 					});
// 				},
// 				5 * 60 * 1000
// 			); // 5 minutes interval in milliseconds

// 			return () => {
// 				// Clear the interval when the component unmounts
// 				clearInterval(checkForUpdatesInterval);
// 			};
// 		}
// 	}, []);

// 	// Component functions
// 	const handleRefresh = async () => {
// 		if ('serviceWorker' in navigator && navigator.serviceWorker?.controller) {
// 			const registration = await navigator.serviceWorker.getRegistration();
// 			if (registration?.waiting) {
// 				registration.waiting.postMessage({ type: 'SKIP_WAITING' });
// 			}
// 			setUpdateAvailable(false);

// 			// Clean up LocalStorage
// 			localStorage.clear();

// 			// Clean up all caches
// 			if (caches?.keys) {
// 				const cacheNames = await caches.keys();
// 				await Promise.all(
// 					cacheNames.map(async (cacheName) => {
// 						await caches.delete(cacheName);
// 					})
// 				);
// 			}

// 			window.location.reload();
// 		}
// 	};

// 	const updatesList = [
// 		'Estamos emocionados de contarte que hemos lanzado una nueva versión de nuestra aplicación. ',
// 		'Hemos añadido características, mejorado la velocidad y la usabilidad para ofrecerte la mejor experiencia posible. 😊👍🏻',
// 	];

// 	return (
// 		<CustomDialog
// 			open={updateAvailable}
// 			aria-labelledby='app-update-dialog-title'
// 			aria-describedby='app-update-dialog-description'
// 		>
// 			<CustomDialogTitle id='app-update-dialog-title'>
// 				Nueva actualización disponible
// 			</CustomDialogTitle>
// 			<DialogContent dividers>
// 				<Logo logoHeight='50px' />

// 				<DialogContentText id='app-update-dialog-description' mt={2}>
// 					Estamos mejorando tu experiencia de usuario y por eso tenemos una
// 					nueva actualización.
// 				</DialogContentText>
// 				<UpdatesContainer>
// 					{updatesList.map((update, key) => (
// 						<UpdateContainer key={update}>
// 							<Typography
// 								sx={{
// 									lineHeight: 1.2,
// 									mb: 1.5,
// 									mr: 1,
// 									color: 'var(--primary)',
// 								}}
// 							>
// 								-
// 							</Typography>
// 							<Typography
// 								sx={{ lineHeight: 1.2, mb: 1.5, color: 'var(--secondary)' }}
// 							>
// 								{update}
// 							</Typography>
// 						</UpdateContainer>
// 					))}
// 				</UpdatesContainer>
// 				<DialogContentText sx={{ mt: 1 }}>
// 					Por favor recarga la página dando click a continuación para mantenerte
// 					al día con estas actualizaciones.
// 				</DialogContentText>
// 			</DialogContent>
// 			<DialogActions>
// 				<CustomBtn
// 					btnText='Actualizar la app'
// 					btnType='button'
// 					width='180px'
// 					onClick={handleRefresh}
// 				/>
// 			</DialogActions>
// 		</CustomDialog>
// 	);
// };

// export default UpdateDialog;
