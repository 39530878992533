import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomSelectInputSearch from '../../../../../layout/CustomSelectInputSearch';
import CustomInput from '../../../../../layout/CustomInput';
import StepperIndicator from '../../../../../shared/StepperIndicator';

// Fuctions imports
import { textTruncator } from '../../../../../fuctions/textTruncator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
});

const AddAccountWrapper = styled(Box)({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '0.25rem !important',
});

const AddAccountText = styled(Typography)({
	color: 'var(--secondary) !important',
	fontSize: '0.8rem !important',
	lineHeight: '1rem !important',
	fontWeight: '400 !important',

	'@media (max-width: 280px)': {
		fontSize: '0.8rem !important',
	},

	'@media only screen and (min-width: 281px) and (max-width: 320px)': {
		fontSize: '0.7rem !important',
	},
});

const AddAccountBtn = styled(Button)({
	paddingTop: '0rem !important',
	paddingBottom: '0rem !important',

	'@media (max-width: 360px)': {
		fontSize: '0.8rem !important',
	},
});

const ButtonWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});

const WithdrawMoneyStepOne = (props) => {
	// Props destructuring
	const {
		activeStep,
		aditionalData,
		commissionConfig,
		errors,
		setAditionalData,
		setTransactionData,
		showHideAddAccountForm,
		totalSteps,
		transactionData,
		userBankAccounts,
		userExternalAccounts, //TODO: para que sirve esto ??
	} = props;

	// Component state
	const [userAccountsList, setUserAccountsList] = useState([]);
	const [userExternalAccountsList, setUserExternalAccountsList] = useState([]);

	// Component hooks
	useEffect(() => {
		setUserAccountsList(
			userBankAccounts
				.filter((account) => account.bank !== 'Utransfer')
				.map((account) => ({
					id: account.id,
					label: `Cta. XXXXXX${account.accountNumber.slice(
						-4
					)} - ${textTruncator(account.bank)}`,
					bankAccount: account.bank,
					bankAccountNumber: account.accountNumber,
					bankAccountType: account.accountType,
					country: account.country,
				}))
		);
	}, [userBankAccounts]);

	// useEffect(() => {
	// 	setUserExternalAccountsList(
	// 		userExternalAccounts.map((account) => ({
	// 			id: account.id,
	// 			label: `Cta.Stp. XXXXXX${account.last4} - ${textTruncator(
	// 				account.bank_name
	// 			)}`,
	// 			bankAccount: account.bank_name,
	// 			bankAccountNumber: account.last4,
	// 			bankAccountType: 'Stripe',
	// 			country: account.country,
	// 		}))
	// 	);
	// }, [userExternalAccounts]);

	// Component functions
	const handleAmmountChange = (e) => {
		const inputValue = e.target.value;
		const regex = /^\d+(\.\d{0,2})?$/;

		if (inputValue === '' || regex.test(inputValue)) {
			setTransactionData({
				...transactionData,
				amount: inputValue,
			});
		}

		// Verificar si el valor ingresado es un número válido
		const parsedValue = parseFloat(inputValue);
		if (!isNaN(parsedValue)) {
			const commission = parsedValue * aditionalData.commissionValue;
			const formattedCommission = '$ ' + commission.toFixed(2);
			const total =
				parsedValue +
				commission * commissionConfig +
				aditionalData.feeValue * commissionConfig;
			const formattedTotal = `$ ${total.toFixed(2)}`;

			setAditionalData({
				...aditionalData,
				commissionAmount: formattedCommission,
				total: formattedTotal,
			});
		} else {
			setAditionalData({
				...aditionalData,
				commissionAmount: '$ 0.00',
				total: '$ 0.00',
			});
		}
	};

	const hangeComentChange = (e) => {
		const inputValue = e.target.value;

		setTransactionData({
			...transactionData,
			message: inputValue,
		});
	};

	const handleBankAccountChange = (value) => {
		setTransactionData({
			...transactionData,
			bankAccountSelected: value.label,
			idAccount: value.id,
			bankAccount: value.bankAccount,
			bankAccountType: value.bankAccountType,
			bankAccountNumber: value.bankAccountNumber,
			country: value.country,
		});
	};

	const handleAddBankAccount = () => {
		showHideAddAccountForm();
	};

	return (
		<>
			<Wrapper>
				{userAccountsList.length === 0 ? (
					// && userExternalAccountsList === 0
					<ButtonWrapper>
						<SectionTitle variant='body2' sx={{ mb: 1, textAlign: 'center' }}>
							Todavía no has registrado cuentas para realizar retiros
						</SectionTitle>
						<Button
							variant='outlined'
							color='secondary'
							sx={{ borderRadius: '0.5rem', margin: '0 auto' }}
							onClick={handleAddBankAccount}
						>
							Agregar cuenta
						</Button>
					</ButtonWrapper>
				) : (
					<>
						<SectionTitle variant='body2'>Monto a retirar</SectionTitle>
						<CustomInput
							name='amount'
							type='text'
							inputHintText={'Ej: $ 100.00'}
							value={transactionData.amount}
							onChange={handleAmmountChange}
							isCentered
							hasAdornment={Boolean(transactionData.amount !== '')}
							error={Boolean(errors.amount)}
							errorText={errors.amount}
							inputSize={'small'}
							isNumber
							marginTop={'0.25rem'}
						/>
						<SectionTitle variant='body2'>Comentarios</SectionTitle>
						<CustomInput
							name='comment'
							type='textarea'
							value={transactionData.message}
							onChange={hangeComentChange}
							inputSize={'small'}
							multiline
							inputHintText={'Ej: Pago de servicios'}
							error={Boolean(errors.message)}
							errorText={errors.message}
							marginTop={'0.25rem'}
						/>
						<CustomSelectInputSearch
							options={[...userAccountsList]}
							// options={[...userAccountsList, ...userExternalAccountsList]}
							title={'Selecciona tu cuenta'}
							handleChange={handleBankAccountChange}
							value={transactionData.bankAccountSelected}
							error={Boolean(errors.bankAccountSelected)}
							errorText={errors.bankAccountSelected}
						/>
						<AddAccountWrapper>
							<AddAccountText variant='h5'>
								¿Deseas agregar otra cuenta?
							</AddAccountText>
							<AddAccountBtn
								color='secondary'
								variant='text'
								size='small'
								onClick={handleAddBankAccount}
							>
								Haz click aquí
							</AddAccountBtn>
						</AddAccountWrapper>
						<List sx={{ pt: 0 }}>
							<Divider component='li' sx={{ marginBottom: '0.25rem' }} />
							<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
								<ListItemText primary={'Comisión'} sx={{ my: 0 }} />
								<ListItemText
									primary={aditionalData.commission}
									sx={{ textAlign: 'end', my: 0 }}
								/>
							</ListItem>
							<ListItem alignItems='flex-start' sx={{ padding: 0, my: 0 }}>
								<ListItemText primary={'Valor comisión'} sx={{ my: 0 }} />
								<ListItemText
									primary={aditionalData.commissionAmount}
									sx={{ textAlign: 'end', my: 0 }}
								/>
							</ListItem>
							<ListItem alignItems='flex-start' sx={{ padding: 0, my: 0 }}>
								<ListItemText primary={'Tarifa por retiro'} sx={{ my: 0 }} />
								<ListItemText
									primary={aditionalData.feeWithdraw}
									sx={{ textAlign: 'end', my: 0 }}
								/>
							</ListItem>
							<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
								<ListItemText primary={'Total'} sx={{ my: 0 }} />
								<ListItemText
									primary={aditionalData.total}
									sx={{ textAlign: 'end', my: 0 }}
								/>
							</ListItem>
							<Divider component='li' sx={{ mt: 1 }} />
						</List>
					</>
				)}
				<Box sx={{ height: 20 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

WithdrawMoneyStepOne.propTypes = {
	activeStep: PropTypes.number.isRequired,
	aditionalData: PropTypes.object.isRequired,
	commissionConfig: PropTypes.number,
	errors: PropTypes.object.isRequired,
	setAditionalData: PropTypes.func.isRequired,
	setTransactionData: PropTypes.func.isRequired,
	showHideAddAccountForm: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	transactionData: PropTypes.object.isRequired,
	userBankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	userExternalAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WithdrawMoneyStepOne;
