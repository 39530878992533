const initState = {
	accountTypes: [],
	banksList: [],
	countries: [],
	loading: false,
	loadingAddBankAccount: false,
	isBankAccountAdded: false,
	userBankAccounts: [],
	showAddAccountForm: false,
};

const bankAccountsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'BANK_ACCOUNTS_START':
			return {
				...state,
				loading: true,
			};
		case 'SHOW_HIDE_ADD_ACCOUNT_FORM':
			return {
				...state,
				showAddAccountForm: !state.showAddAccountForm,
			};
		case 'HIDE_ADD_ACCOUNT_FORM':
			return {
				...state,
				showAddAccountForm: false,
			};
		case 'GET_BANK_LIST_START':
		case 'ADD_BANK_ACCOUNT_START':
			return {
				...state,
				loadingAddBankAccount: true,
			};
		case 'GET_BANK_ACCOUNTS_SUCCESS':
			return {
				...state,
				loading: false,
				userBankAccounts: action.payload,
			};
		case 'REMOVE_USER_ACCOUNTS':
			return {
				...state,
				userBankAccounts: [],
			};
		case 'GET_ACCOUNT_TYPES_SUCCESS':
			return {
				...state,
				...state,
				accountTypes: action.payload,
			};
		case 'GET_COUNTRIES_SUCCESS':
			return {
				...state,
				countries: action.payload,
			};
		case 'GET_BANKS_LIST_SUCCESS':
			return {
				...state,
				banksList: action.payload,
				loadingAddBankAccount: false,
			};
		case 'ADD_BANK_ACCOUNT_SUCCESS':
			return {
				...state,
				loadingAddBankAccount: false,
				isBankAccountAdded: true,
			};
		case 'ADD_BANK_ACCOUNT_ERROR':
			return {
				...state,
				loadingAddBankAccount: false,
			};
		case 'GET_BANK_ACCOUNTS_ERROR':
		case 'GET_ACCOUNT_TYPES_ERROR':
		case 'GET_COUNTRIES_LIST_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'GET_BANKS_LIST_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'ADD_BANK_ACCOUNT_END':
			return {
				...state,
				isBankAccountAdded: false,
			};
		case 'SIGNOUT':
			return {
				...state,
				accountTypes: [],
				banksList: [],
				userBankAccounts: [],
			};

		default:
			return state;
	}
};

export default bankAccountsReducer;
