import PropTypes from 'prop-types';

const AddBankAccountValidation = (values = {}) => {
	let errors = {};

	// Country validation
	if (values.bankCountry === '') {
		errors.bankCountry = 'El país es requerido';
		return errors;
	}
	//TODO: alignet
	// Bank account validation
	// if (values.bankName === '') {
	// 	errors.bankName = 'La cuenta bancaria es requerida';
	// }
	//TODO: stripe
	// Routing number validation
	// if (values.routingNumber === '') {
	// 	errors.routingNumber = 'El número de enrutamiento es requerido';
	// } else if (values.routingNumber.length < 6) {
	// 	errors.routingNumber = 'El número de enrutamiento es muy corto';
	// } else if (values.routingNumber.length > 20) {
	// 	errors.routingNumber = 'El número de enrutamiento es muy largo';
	// }

	// Bank account number validation
	if (values.accountNumber === '') {
		errors.accountNumber = 'El número de cuenta es requerido';
	} else if (values.accountNumber.length < 6) {
		errors.accountNumber = 'El número de cuenta es muy corto';
	} else if (values.accountNumber.length > 20) {
		errors.accountNumber = 'El número de cuenta es muy largo';
	} else if (!/^[0-9]+$/.test(values.accountNumber)) {
		errors.accountNumber = 'El número de cuenta debe ser numérico';
	}
	//TODO: alignet
	// Bank account type validation
	if (values.typeAccountText === '') {
		errors.typeAccountText = 'El tipo de cuenta es requerido';
	}

	return errors;
};

AddBankAccountValidation.propTypes = {
	values: PropTypes.object.isRequired,
};

export default AddBankAccountValidation;
