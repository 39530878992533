import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Tab, Tabs, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import StepperIndicator from '../../../../../shared/StepperIndicator';
import CustomTabPanel from '../../../Components/CustomTabPanel';
import CustomInput from '../../../../../layout/CustomInput';
import Balance from '../../../../../shared/Balance';
import UserCards from '../MyCards/Components/UserCards';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
	paddingBottom: '0.5rem',
});

const SectionTitle = styled(Typography)({
	width: '100%',
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
	textAlign: 'left',
});

const TabWrapper = styled(Box)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});

const ErrorText = styled(Typography)({
	fontSize: '0.8rem !important',
	color: 'var(--error) !important',
});

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const SendMoneyStepThree = (props) => {
	// Props destructuring
	const {
		activeStep,
		amount,
		errors,
		handleClose,
		setPaymentMethod,
		setSavedCardDetails,
		totalSteps,
	} = props;

	// Component state
	const [value, setValue] = useState(0);

	// Component functions
	const handleChange = (event, newValue) => {
		setValue(newValue);

		if (newValue === 0) {
			setPaymentMethod('myBalance');
		} else {
			setPaymentMethod('myCards');
		}
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2'>Monto a enviar</SectionTitle>
				<CustomInput
					name='amount'
					type='text'
					inputHintText={'Ej: $ 100.00'}
					value={amount}
					isCentered
					hasAdornment={Boolean(amount !== '')}
					inputSize={'small'}
					isNumber
					isDisabled={true}
				/>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='basic tabs example'
				>
					<Tab label='Usar mi saldo' {...a11yProps(0)} />
					<Tab label='Tarjetas registradas' {...a11yProps(1)} />
				</Tabs>
				<CustomTabPanel value={value} index={0} pt={1}>
					<TabWrapper>
						<Balance />
					</TabWrapper>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1} pt={1}>
					<TabWrapper>
						<SectionTitle variant='body2' sx={{ mb: 0.5 }}>
							Elije una de tus tarjetas
						</SectionTitle>
						<Wrapper>
							{errors.card && <ErrorText>{errors.card}</ErrorText>}
							<UserCards
								isPayment
								setSavedCardDetails={setSavedCardDetails}
								closeModal={handleClose}
							/>
						</Wrapper>
					</TabWrapper>
				</CustomTabPanel>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

SendMoneyStepThree.propTypes = {
	activeStep: PropTypes.number.isRequired,
	amount: PropTypes.string.isRequired,
	errors: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	setPaymentMethod: PropTypes.func.isRequired,
	setSavedCardDetails: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	requestMoneyData: PropTypes.object,
};

export default SendMoneyStepThree;
