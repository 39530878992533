import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// SafeArea Capacitor Plugin
import { SafeArea } from 'capacitor-plugin-safe-area';

// Device Detect
import { isIOS } from 'react-device-detect';

// Styled Components imports
import styled from 'styled-components';
import ActionLoading from './ActionLoading';
import AddBankAccount from '../pages/Account/InnerRoutes/UtransferServices/BankAccounts/AddBankAccount';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	height: (props) =>
		isIOS
			? `calc(100vh - 184px - ${props.topsafearea}px)`
			: `calc(100vh - 184px)`,
	paddingTop: (props) =>
		isIOS ? `calc(56px + ${props.topsafearea}px)` : '56px',

	'@media (min-width: 767px) and (max-width: 1024px)': {
		display: 'grid',
		gridTemplateColumns: 'minmax(0, 325px) minmax(0, 1fr)',
		gridTemplateRows: '1fr',
		height: (props) =>
			isIOS
				? `calc(100vh - 56px - ${props.topsafearea}px)`
				: `calc(100vh - 56px)`,
	},

	'@media (min-width: 1025px)': {
		display: 'grid',
		gridTemplateColumns: 'minmax(0, 325px) minmax(0, 4fr) minmax(0, 325px)',
		gridTemplateRows: '1fr',
		height: (props) =>
			isIOS
				? `calc(100vh - 56px - ${props.topsafearea}px)`
				: `calc(100vh - 56px)`,
	},
});

const LayoutWrapper = (props) => {
	// Props destructuring
	const {
		accessToken,
		children,
		loading,
		loadingAddBankAccount,
		loadingKyc,
		loadingLogout,
		loadingTicketMessages,
		loadingUser,
	} = props;

	// Component State
	const [safeArea, setSafeArea] = useState({});

	// Component Hooks
	useEffect(() => {
		SafeArea.getSafeAreaInsets().then(({ insets }) => {
			setSafeArea(insets);
		});
	}, []);

	if (!accessToken) return <Navigate replace to='/bienvenida' />;

	return (
		<Wrapper topsafearea={safeArea?.top}>
			{children}
			<ActionLoading
				open={
					loading ||
					loadingAddBankAccount ||
					loadingTicketMessages ||
					loadingUser ||
					loadingLogout ||
					loadingKyc
				}
				loadingText={
					loading || loadingAddBankAccount
						? 'Procesando transacción...'
						: loadingTicketMessages
						? 'Cargando mensajes...'
						: loadingUser
						? 'Cargando usuario...'
						: loadingLogout
						? 'Cerrando sesión...'
						: loadingKyc
						? 'Validando datos...'
						: 'Cargando...'
				}
				textColor={'#fff'}
			/>
			<AddBankAccount />
		</Wrapper>
	);
};

LayoutWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	accessToken: PropTypes.string,
	loading: PropTypes.bool,
	loadingAddBankAccount: PropTypes.bool,
	loadingKyc: PropTypes.bool,
	loadingLogout: PropTypes.bool,
	loadingTicketMessages: PropTypes.bool,
	loadingUser: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const { accessToken, loadingLogout } = state.auth;
	const { loading } = state.transactions;
	const { loading: loadingKyc } = state.kyc;
	const { loading: loadingUser } = state.user;
	const { loadingAddBankAccount } = state.bankAccounts;
	const { loadingTicketMessages } = state.messages;

	return {
		accessToken,
		loading,
		loadingAddBankAccount,
		loadingKyc,
		loadingLogout,
		loadingTicketMessages,
		loadingUser,
	};
};

export default connect(mapStateToProps)(LayoutWrapper);
