import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';

// import { Browser } from '@capacitor/browser';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import CustomInput from '../../../../../layout/CustomInput';
import StepperIndicator from '../../../../../shared/StepperIndicator';
import PaymentModal from './PaymentModal';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
});

const DepositMoneyStepOne = (props) => {
	// Props destructuring
	const {
		activeStep,
		aditionalData,
		commissionConfig,
		errors,
		accountNumber,
		setActiveStep,
		setAditionalData,
		setTransactionData,
		totalSteps,
		transactionData,
		userProfile,
	} = props;

	const [openModal, setOpenModal] = useState(false);
	const [modalContent, setModalContent] = useState(<Box />);

	// Component hooks
	useEffect(() => {
		if (userProfile?.id) {
			setTransactionData({
				...transactionData,
				shippingFirstName: userProfile?.firstName,
				shippingLastName: userProfile?.lastName,
				shippingEmail: userProfile?.email,
				shippingPhone: userProfile?.phoneNumber,
				userId: userProfile?.id.toString(),
				shippingAddress: userProfile?.shippingAddress,
				shippingZIP: userProfile?.shippingZIP,
				shippingCity: userProfile?.shippingCity,
				shippingState: userProfile?.shippingState,
				shippingCountry: userProfile?.residenceCountry,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userProfile]);

	// Component functions
	const handleAmmountChange = (e) => {
		const inputValue = e.target.value;
		const regex = /^\d+(\.\d{0,2})?$/;

		if (inputValue === '' || regex.test(inputValue)) {
			setTransactionData({
				...transactionData,
				amount: inputValue,
			});
		}

		// Verificar si el valor ingresado es un número válido
		const parsedValue = parseFloat(inputValue);
		if (!isNaN(parsedValue)) {
			const commission = parsedValue * aditionalData.commissionValue;
			const formattedCommission = '$ ' + commission.toFixed(2);
			const total = parsedValue + commission * commissionConfig;
			const formattedTotal = '$ ' + total.toFixed(2);

			setAditionalData({
				...aditionalData,
				commissionAmount: formattedCommission,
				total: formattedTotal,
			});
		} else {
			setAditionalData({
				...aditionalData,
				commissionAmount: '$ 0.00',
				total: '$ 0.00',
			});
		}
	};

	const handleChange = (e) => {
		setTransactionData({ ...transactionData, [e.target.name]: e.target.value });
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setModalContent(<Box />);
	};

	const handleDepositMoneyIn = async () => {
		// const userId = transactionData.userId;
		// const amount = transactionData.amount;
		// const commission = aditionalData.commissionValue;
		// const commissionAmount = aditionalData.commissionAmount.slice(2);
		// const value = aditionalData.total.slice(2);
		// const description = transactionData.description;
		// const shippingFirstName = transactionData.shippingFirstName;
		// const shippingLastName = transactionData.shippingLastName;
		// const shippingEmail = transactionData.shippingEmail;
		// const shippingPhone = transactionData.shippingPhone;
		// const shippingAddress = transactionData.shippingAddress;
		// const shippingZIP = transactionData.shippingZIP;
		// const shippingCity = transactionData.shippingCity;
		// const shippingState = transactionData.shippingState;
		// const shippingCountry = transactionData.shippingCountry;

		// await Browser.open({
		// 	url: `${process.env.REACT_APP_ALIGNET}?
		// 	userId=${userId}&
		// 	userAccountId=${accountNumber}&
		// 	amount=${amount}&
		// 	commission=${commission}&
		// 	commissionAmount=${commissionAmount}&
		// 	value=${value}&
		// 	description=${description}&
		// 	shippingFirstName=${shippingFirstName}&
		// 	shippingLastName=${shippingLastName}&
		// 	shippingEmail=${shippingEmail}&
		// 	shippingPhone=${shippingPhone}&
		// 	shippingAddress=${shippingAddress}&
		// 	shippingZIP=${shippingZIP}&
		// 	shippingCity=${shippingCity}&
		// 	shippingState=${shippingState}&
		// 	shippingCountry=${shippingCountry}`,
		// });

		// setTimeout(() => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		// }, 1500);
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2' sx={{ mb: 0.5 }}>
					Monto a depositar en mi cuenta
				</SectionTitle>
				<CustomInput
					name='amount'
					type='text'
					inputHintText={'Ej: $ 100.00'}
					value={transactionData.amount}
					onChange={handleAmmountChange}
					hasAdornment={Boolean(transactionData.amount)}
					error={Boolean(errors.amount)}
					errorText={errors.amount}
					inputSize={'small'}
					isCentered
					isNumber
					marginTop={'0.3rem'}
				/>
				<SectionTitle variant='body2'>Comentarios</SectionTitle>
				<CustomInput
					name='description'
					type='textarea'
					value={transactionData.description}
					onChange={handleChange}
					error={Boolean(errors.description)}
					errorText={errors.description}
					inputSize={'small'}
					multiline
					inputHintText={'Ej: Despósito de fondos'}
					marginTop={'0.3rem'}
				/>
				<List>
					<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
						<ListItemText primary={'Comisión'} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.commission}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
					<ListItem alignItems='flex-start' sx={{ padding: 0, my: 0 }}>
						<ListItemText primary={'Valor comisión'} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.commissionAmount}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
					<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
						<ListItemText primary={'Total a recibir '} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.total}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
					<Divider component='li' sx={{ mt: 1 }} />
				</List>
				<button
					onClick={handleDepositMoneyIn}
					id='deposit-money-btn'
					style={{ display: 'none' }}
				>
					Enviar dinero
				</button>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={72}
			/>
			<PaymentModal
				open={openModal}
				handleClose={handleCloseModal}
				modalContent={modalContent}
			/>
		</>
	);
};

DepositMoneyStepOne.defaultProps = {
	activeStep: 0,
	totalSteps: [0],
	userProfile: {},
	accountNumber: '',
};

DepositMoneyStepOne.propTypes = {
	activeStep: PropTypes.number.isRequired,
	aditionalData: PropTypes.object.isRequired,
	commissionConfig: PropTypes.number,
	errors: PropTypes.object.isRequired,
	accountNumber: PropTypes.string.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	setAditionalData: PropTypes.func.isRequired,
	setErrors: PropTypes.func.isRequired,
	setTransactionData: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	transactionData: PropTypes.object.isRequired,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { userProfile } = state.user;
	const { accountNumber } = state.transactions.userAccount;

	return {
		userProfile,
		accountNumber,
	};
};

export default connect(mapStateToProps)(DepositMoneyStepOne);
