import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// Local Components Imports
import SendMoney from './SendMoney/SendMoney';
import DepositMoney from './DespositMoney/DepositMoney';
import RequestMoney from './RequestMoney/RequestMoney';
import WithdrawMoney from './WithdrawMoney/WithdrawMoney';
import QRScanner from './QRScanner/QRScanner';
import AlertDialog from '../../../../shared/AlertDialog';

// Redux actions imports
import {
	backToPreviousInnerRoute,
	changeInnerRoute,
	clearInnerRoute,
} from '../../../../store/actions/innerRouterActions';
import { getConfigurationByCountry } from '../../../../store/actions/transactionsActions';
import { getCountries } from '../../../../store/actions/appOptionsActions';
import NoCountryConfiguration from '../../../../shared/NoCountryConfiguration';

const AllTransactionsComponent = (props) => {
	// Props destructuring
	const {
		actualRoute,
		backToPreviousInnerRoute,
		changeInnerRoute,
		clearInnerRoute,
		countries,
		getConfigurationByCountry,
		getCountries,
		innerRoute,
		showNoOperationsDialog,
		userProfile,
	} = props;

	// Component state
	const [openSendMoneyDialog, setOpenSendMoneyDialog] = useState(false);
	const [openDepositMoneyDialog, setOpenDepositMoneyDialog] = useState(false);
	const [openRequestMoneyDialog, setOpenRequestMoneyDialog] = useState(false);
	const [openWithdrawMoneyDialog, setOpenWithdrawMoneyDialog] = useState(false);
	const [openQRScanner, setOpenQRScanner] = useState(false);
	const [openAlertDialog, setOpenAlertDialog] = useState(false);
	const [noOperationsAllowedDialog, setNoOperationsAllowedDialog] = useState({
		title: '',
		dilogIcon: '',
		dialogInfo: '',
		primaryBtnTitle: '',
		route: '',
	});
	const [openNoOperationsDialog, setOpenNoOperationsDialog] = useState(false);

	// Component hooks
	const memoizedActualRoute = useMemo(() => actualRoute, [actualRoute]);
	const navigate = useNavigate();

	const memoizedSendMoneyDialog = useMemo(
		() => (
			<SendMoney
				openDialog={openSendMoneyDialog}
				setOpenDialog={setOpenSendMoneyDialog}
			/>
		),
		[openSendMoneyDialog]
	);

	const memoizedDepositMoneyDialog = useMemo(
		() => (
			<DepositMoney
				openDialog={openDepositMoneyDialog}
				setOpenDialog={setOpenDepositMoneyDialog}
			/>
		),
		[openDepositMoneyDialog]
	);

	const memoizedRequestMoneyDialog = useMemo(
		() => (
			<RequestMoney
				openDialog={openRequestMoneyDialog}
				setOpenDialog={setOpenRequestMoneyDialog}
			/>
		),
		[openRequestMoneyDialog]
	);

	const memoizedWithdrawMoneyDialog = useMemo(
		() => (
			<WithdrawMoney
				openDialog={openWithdrawMoneyDialog}
				setOpenDialog={setOpenWithdrawMoneyDialog}
			/>
		),
		[openWithdrawMoneyDialog]
	);

	const memoizedQRScannerDialog = useMemo(
		() => (
			<QRScanner
				actualRoute={memoizedActualRoute}
				openDialog={openQRScanner}
				setOpenDialog={setOpenQRScanner}
			/>
		),
		[memoizedActualRoute, openQRScanner]
	);

	useEffect(() => {
		getCountries();
	}, [getCountries]);

	useEffect(() => {
		if (
			openSendMoneyDialog ||
			openDepositMoneyDialog ||
			openRequestMoneyDialog ||
			openWithdrawMoneyDialog
		) {
			if (countries.length > 0 && userProfile?.residenceCountry) {
				const country = countries.find(
					(country) => country.name === userProfile.residenceCountry
				);

				getConfigurationByCountry(country.id);
			}
		}
	}, [
		countries,
		getConfigurationByCountry,
		openDepositMoneyDialog,
		openRequestMoneyDialog,
		openSendMoneyDialog,
		openWithdrawMoneyDialog,
		userProfile,
	]);

	useEffect(() => {
		if (showNoOperationsDialog) {
			setOpenNoOperationsDialog(true);
		}
	}, [showNoOperationsDialog]);

	const accessToken = localStorage.getItem('accessToken');

	const closeAllDialogs = () => {
		setOpenSendMoneyDialog(false);
		setOpenDepositMoneyDialog(false);
		setOpenRequestMoneyDialog(false);
		setOpenWithdrawMoneyDialog(false);
		setOpenQRScanner(false);
	};

	useEffect(() => {
		if (!accessToken) {
			closeAllDialogs();
		}
	}, [accessToken]);

	const validateCountryOfResidence = () => {
		const transactionsRoutes = [
			'sendMoney',
			'depositMoney',
			'requestMoney',
			'withdrawMoney',
			'qrScanner',
		];

		const isTransactionRoute = transactionsRoutes.some((route) =>
			innerRoute.includes(route)
		);

		if (
			(!userProfile?.residenceCountry || !userProfile?.shippingAddress) &&
			isTransactionRoute
		) {
			setNoOperationsAllowedDialog({
				title: 'Datos de usuario',
				dilogIcon: 'faUserClock',
				dialogInfo:
					'Por favor debes completar tus datos personales para poder realizar esta transacción',
				primaryBtnTitle: 'Actualizar datos',
				route: 'shippingAddress',
			});
			setOpenAlertDialog(true);
			return true;

			// KYC verification aproved before transactions
		} else if (userProfile?.statusKycVerify !== 2 && isTransactionRoute) {
			setNoOperationsAllowedDialog({
				title: 'Verificación de usuario (KYC)',
				dilogIcon: 'faUserShield',
				dialogInfo:
					'Por favor debes completar el proceso de verificación de usario (KYC) con tus documentos de identidad para poder realizar esta transacción',
				primaryBtnTitle: 'Validar KYC',
				route: 'kyc',
			});
			setOpenAlertDialog(true);
			return true;
		} else return false;
	};

	const routeActions = {
		sendMoney: setOpenSendMoneyDialog,
		depositMoney: setOpenDepositMoneyDialog,
		requestMoney: setOpenRequestMoneyDialog,
		withdrawMoney: setOpenWithdrawMoneyDialog,
		qrScanner: setOpenQRScanner,
	};
	const dialogsToClose = [
		setOpenSendMoneyDialog,
		setOpenDepositMoneyDialog,
		setOpenRequestMoneyDialog,
		setOpenWithdrawMoneyDialog,
		setOpenQRScanner,
		setOpenAlertDialog,
		setOpenNoOperationsDialog,
	];

	useEffect(() => {
		if (validateCountryOfResidence()) return;
		const actionFunction = routeActions[memoizedActualRoute];
		if (actionFunction) {
			actionFunction(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedActualRoute, userProfile]);

	const handleAlertClose = () => {
		backToPreviousInnerRoute();

		dialogsToClose.forEach((dialog) => {
			dialog(false);
		});

		setTimeout(() => {
			setNoOperationsAllowedDialog({
				title: '',
				dilogIcon: '',
				dialogInfo: '',
				primaryBtnTitle: '',
				route: '',
			});
		}, 500);
	};

	const handleConfirmationDialog = (route) => {
		handleAlertClose();
		clearInnerRoute();
		navigate('/cuenta');
		changeInnerRoute('account');

		setTimeout(() => {
			changeInnerRoute(route);
		}, 200);
	};

	return (
		<>
			{memoizedSendMoneyDialog}
			{memoizedDepositMoneyDialog}
			{memoizedRequestMoneyDialog}
			{memoizedWithdrawMoneyDialog}
			{memoizedQRScannerDialog}
			<AlertDialog
				dilogType={'transactions'}
				openDialog={openAlertDialog}
				onClose={handleAlertClose}
				title={noOperationsAllowedDialog.title}
				dialogInfo={noOperationsAllowedDialog.dialogInfo}
				hasTwoBtns={false}
				primaryBtnTitle={noOperationsAllowedDialog.primaryBtnTitle}
				primaryBtnIcon={'faCheck'}
				dilogIcon={noOperationsAllowedDialog.dilogIcon}
				handleConfirmation={() => {
					handleConfirmationDialog(noOperationsAllowedDialog.route);
				}}
			/>
			<NoCountryConfiguration
				openAlertDialog={openNoOperationsDialog}
				handleAlertClose={handleAlertClose}
			/>
		</>
	);
};

AllTransactionsComponent.propTypes = {
	actualRoute: PropTypes.string.isRequired,
	backToPreviousInnerRoute: PropTypes.func.isRequired,
	changeInnerRoute: PropTypes.func.isRequired,
	clearInnerRoute: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
	getConfigurationByCountry: PropTypes.func.isRequired,
	getCountries: PropTypes.func.isRequired,
	innerRoute: PropTypes.array.isRequired,
	showNoOperationsDialog: PropTypes.bool.isRequired,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { countries } = state.appOptions;
	const { innerRoute } = state.innerRouter;
	const { userProfile } = state.user;
	const { showNoOperationsDialog } = state.transactions;

	return {
		countries,
		innerRoute,
		showNoOperationsDialog,
		userProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		backToPreviousInnerRoute: () => dispatch(backToPreviousInnerRoute()),
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		getConfigurationByCountry: (country) =>
			dispatch(getConfigurationByCountry(country)),
		getCountries: () => dispatch(getCountries()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AllTransactionsComponent);
