import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

const CustomFormInput = styled(FormControl)({
	borderRadius: 10,
	marginTop: (props) =>
		props.margintop ? `${props.margintop} !important` : '0.5rem',
	marginBottom: (props) =>
		props.error ? '0px !important' : '0.85rem !important',

	'& .MuiFormLabel-root': {
		color: (props) =>
			props.error ? 'var(--error) !important' : 'rgba(0,0,0,0.7) !important',
	},

	'& label.Mui-focused custom-input-label': {
		paddingRight: 5,
	},

	'& .MuiOutlinedInput-root': {
		backgroundColor: '#fff',
		borderRadius: 10,
		color: 'rgba(0,0,0,0.7) !important',

		'& fieldset': {
			borderColor: 'var(--secondary)',
			borderWidth: 2,
			borderRadius: 10,
			transition: '0.3s ease',
		},
		'&:hover fieldset': {
			borderColor: 'var(--secondary)',
		},
		'&.Mui-focused fieldset': {
			borderColor: 'var(--secondary)',
		},
	},

	'@media (max-width: 768px)': {
		marginBottom: (props) =>
			props.error ? '0px !important' : '0.85rem !important',
		marginTop: (props) =>
			props.margintop ? `${props.margintop} !important` : '0.5rem',
	},
});

const ErrorText = styled('div')({
	fontWeight: '400',
	fontSize: '0.75rem',
	lineHeight: '1.66',
	letterSpacing: '0.03333em',
	textAlign: 'left',
	marginRight: '14px',
	marginLeft: '14px',
	color: 'var(--error)',
});

const CustomInput = (props) => {
	// Props destructuring
	const {
		autoComplete,
		endIcon,
		error,
		errorText,
		hasEndIcon,
		hasAdornment,
		iconBtnAction,
		isCentered,
		isDisabled,
		inputHintText,
		inputSize,
		isNumber,
		marginTop,
		multiline,
		name,
		onChange,
		onMouseDown,
		rows,
		type,
		value,
	} = props;

	return (
		<CustomFormInput
			variant='outlined'
			fullWidth
			margin='dense'
			size={inputSize}
			error={error}
			disabled={isDisabled}
			margintop={marginTop}
		>
			<InputLabel htmlFor={inputHintText}>{inputHintText}</InputLabel>
			<OutlinedInput
				id={inputHintText}
				type={type}
				name={name}
				value={value}
				inputMode={isNumber ? 'numeric' : 'text'}
				onChange={onChange}
				multiline={multiline}
				rows={rows}
				error={error}
				autoComplete={autoComplete}
				startAdornment={
					hasAdornment ? (
						<InputAdornment position='start'>$</InputAdornment>
					) : null
				}
				inputProps={{
					sx: {
						textAlign: isCentered ? 'center' : 'left',
					},
					...((type === 'number' && {
						maxLength: 13,
						step: '1',
					}) ||
						{}),
				}}
				endAdornment={
					hasEndIcon && (
						<InputAdornment position='end'>
							{iconBtnAction ? (
								<IconButton
									aria-label={`toggle ${inputHintText}`}
									onClick={iconBtnAction}
									onMouseDown={onMouseDown}
									edge='end'
								>
									{endIcon}
								</IconButton>
							) : (
								endIcon
							)}
						</InputAdornment>
					)
				}
				label={inputHintText}
			/>
			{errorText && <ErrorText>{errorText}</ErrorText>}
		</CustomFormInput>
	);
};

CustomInput.defaultProps = {
	isNumber: false,
};

CustomInput.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	inputHintText: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	autoComplete: PropTypes.string,
	endIcon: PropTypes.node,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	hasEndIcon: PropTypes.bool,
	hasAdornment: PropTypes.bool,
	iconBtnAction: PropTypes.func,
	isCentered: PropTypes.bool,
	isDisabled: PropTypes.bool,
	multiline: PropTypes.bool,
	onMouseDown: PropTypes.func,
	rows: PropTypes.number,
	marginTop: PropTypes.string,
	inputSize: PropTypes.string,
	isNumber: PropTypes.bool,
};

export default CustomInput;
