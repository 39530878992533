import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import CustomDialog from '../../../../../layout/CustomDialog';
import RequestMoneyStepOne from './RequestMoneyStepOne';
import RequestMoneyStepOneValidation from './Validation/RequestMoneyStepOneValidation';
import RequestMoneyStepTwo from './RequestMoneyStepTwo';
import TwoBtnsGrid from '../../../../../shared/TwoBtnsGrid';

// Redux Actions Imports
import {
	changeInnerRoute,
	clearInnerRoute,
} from '../../../../../store/actions/innerRouterActions';
import {
	requestMoney,
	updateUserBalance,
} from '../../../../../store/actions/transactionsActions';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
	minHeight: '12rem',
});

const RequestMoney = (props) => {
	// Props destructuring
	const {
		changeInnerRoute,
		clearInnerRoute,
		configurationByCountry,
		openDialog,
		requestMoney,
		requestMoneyTokenIn,
		setOpenDialog,
		updateUserBalance,
		userProfile,
	} = props;

	// Component state
	const [activeStep, setActiveStep] = useState(0);
	const [transactionData, setTransactionData] = useState({
		amount: '',
		comment: '',
	});
	const [requestMoneyUrl, setRequestMoneyUrl] = useState('');

	const [maxSend, setMaxSend] = useState(0);
	const [minSend, setMinSend] = useState(0);

	const [errors, setErrors] = useState({});

	const totalSteps = [0, 1];

	// Component Hooks
	useEffect(() => {
		if (requestMoneyTokenIn !== '') {
			const baseUrl = process.env.REACT_APP_BASE_URL;

			setRequestMoneyUrl(`${baseUrl}/bienvenida/rmt=${requestMoneyTokenIn}`);

			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [requestMoneyTokenIn]);

	console.log(requestMoneyUrl);

	useEffect(() => {
		if (openDialog && configurationByCountry.minSend) {
			// if (openDialog && configurationByCountry.percentTransfer) {
			setMaxSend(configurationByCountry.maxSend);
			setMinSend(configurationByCountry.minSend);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationByCountry, openDialog]);

	console.log(maxSend);
	console.log(configurationByCountry);

	// Component functions
	const sendMoneySteps = [
		{
			title: 'Solicitar dinero',
			content: (
				<Wrapper>
					<RequestMoneyStepOne
						activeStep={activeStep}
						errors={errors}
						setTransactionData={setTransactionData}
						totalSteps={totalSteps}
						transactionData={transactionData}
					/>
					<Box />
				</Wrapper>
			),
		},
		{
			title: 'Solicitar dinero',
			content: (
				<Wrapper>
					<RequestMoneyStepTwo
						activeStep={activeStep}
						requestMoneyTokenIn={requestMoneyTokenIn}
						requestMoneyUrl={requestMoneyUrl}
						totalSteps={totalSteps}
						userProfile={userProfile}
					/>
					<Box />
				</Wrapper>
			),
		},
	];

	const resetState = () => {
		setTransactionData({
			amount: '',
			comment: '',
		});
		setActiveStep(0);
		setErrors({});
		setRequestMoneyUrl('');
	};

	const handleClose = () => {
		setOpenDialog(false);
		clearInnerRoute();
		changeInnerRoute('account');
		resetState();
		if (activeStep === 1) updateUserBalance();
	};

	const handleNext = () => {
		if (activeStep !== totalSteps.length - 1 && activeStep !== 1) {
			if (
				Object.keys(
					RequestMoneyStepOneValidation(maxSend, minSend, transactionData)
				).length === 0
			) {
				setErrors({});

				requestMoney(transactionData);

				return;
			}

			setErrors(
				RequestMoneyStepOneValidation(maxSend, minSend, transactionData)
			);
		} else if (activeStep === totalSteps.length - 1) {
			handleClose();
		}
	};

	const handleBack = () => {
		activeStep !== 0
			? setActiveStep((prevActiveStep) => prevActiveStep - 1)
			: handleClose();
	};

	const content = sendMoneySteps[activeStep];

	return (
		<CustomDialog
			openDialog={openDialog}
			content={content}
			minHeight={'11rem'}
			onClose={handleClose}
			buttons={
				<TwoBtnsGrid
					leftBtnText={activeStep === 0 ? 'Cancelar' : 'Atrás'}
					leftBtnAction={handleBack}
					isLeftBtnDisabled={Boolean(activeStep === 1)}
					leftLoadingText={'Cancelar'}
					rightBtnText={activeStep === 0 ? 'Generar QR' : 'Aceptar'}
					rightBtnAction={handleNext}
					rightBtnType={'button'}
					leftBtnType={'button'}
					paddingY={'0.5rem'}
					btnsFullWidth={'true'}
				/>
			}
		/>
	);
};

RequestMoney.defaultProps = {
	configurationByCountry: {},
};

RequestMoney.propTypes = {
	configurationByCountry: PropTypes.object,
	changeInnerRoute: PropTypes.func,
	clearInnerRoute: PropTypes.func,
	openDialog: PropTypes.bool,
	requestMoney: PropTypes.func,
	requestMoneyTokenIn: PropTypes.string,
	setOpenDialog: PropTypes.func,
	updateUserBalance: PropTypes.func,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { configurationByCountry, requestMoneyTokenIn } = state.transactions;
	const { userProfile } = state.user;

	return {
		configurationByCountry,
		requestMoneyTokenIn,
		userProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		clearInnerRoute: () => dispatch(clearInnerRoute()),
		requestMoney: (transactionData) => dispatch(requestMoney(transactionData)),
		updateUserBalance: () => dispatch(updateUserBalance()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestMoney);
