const initState = {
	loading: false,
};

const kcyReducer = (state = initState, action) => {
	switch (action.type) {
		case 'KYC_VALIDATION_START':
			return {
				...state,
				loading: true,
			};
		case 'KYC_VALIDATION_SUCCESS':
		case 'KYC_VALIDATION_ERROR':
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default kcyReducer;
