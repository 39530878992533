import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import Logo from '../../../../../shared/Logo';
import StepperIndicator from '../../../../../shared/StepperIndicator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
	paddingBottom: '0.5rem',
});

const DepositMoneyStepTwo = (props) => {
	// Props destructuring
	const { activeStep, totalSteps } = props;

	return (
		<>
			<Wrapper>
				<Logo vertical logoHeight={'130px'} />
				<Typography variant='h5' align='center' sx={{ mb: 1 }}>
					Procesando tu depósito
				</Typography>

				<Typography variant='body1' align='center'>
					Estamos procesando tu depósito, en unos minutos recibirás un correo
					electrónico con la confirmación de tu depósito.
				</Typography>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={72}
			/>
		</>
	);
};

DepositMoneyStepTwo.propTypes = {
	activeStep: PropTypes.number.isRequired,
	totalSteps: PropTypes.array.isRequired,
};

export default DepositMoneyStepTwo;
