import PropTypes from 'prop-types';
import * as FaIcons from 'react-icons/fa';
import * as Fa6Icons from '@fortawesome/free-solid-svg-icons';

// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomIcon = (props) => {
	// Props destructuring
	const { iconColor, iconName, iconSize } = props;

	const FaIcon = FaIcons[iconName];
	const Fa6Icon = Fa6Icons[iconName];

	if (Fa6Icon) {
		return (
			<FontAwesomeIcon
				icon={Fa6Icon}
				style={{
					fontSize: iconSize,
					color: iconColor,
				}}
			/>
		);
	}

	if (FaIcon) {
		return <FaIcon style={{ fontSize: iconSize, color: iconColor }} />;
	}

	if (!FaIcon) {
		return <FaIcons.FaQuestion />;
	}
};

CustomIcon.defaultProps = {
	iconSize: '1rem',
};

CustomIcon.propTypes = {
	iconName: PropTypes.string.isRequired,
	iconColor: PropTypes.string,
	iconSize: PropTypes.string,
};

export default CustomIcon;
