import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Button, Typography } from '@mui/material';

// QR Code Imports
import QRCode from 'qrcode.react';

// Capacitor Imports
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import StepperIndicator from '../../../../../shared/StepperIndicator';
import CustomIcon from '../../../../../shared/CustomIcon';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	overflow: 'auto',
	maxHeight: 'calc(100vh / 2)',
	minHeight: 'calc(100vh / 2.5)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
	textAlign: 'center',
});

const QRWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	marginTop: '1.5rem',
});

const BtnContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});

const ShareBtn = styled(Button)({
	borderRadius: '0.5rem !important',
	marginTop: '1.5rem !important',
	marginBottom: '1rem !important',
});

const RequestMoneyStepTwo = (props) => {
	// Props destructuring
	const {
		activeStep,
		requestMoneyTokenIn,
		requestMoneyUrl,
		totalSteps,
		userProfile,
	} = props;

	// Component functions
	const handleShareNative = async () => {
		try {
			await Share.share({
				title: 'Solicitud de pago',
				text: `Hola, soy ${userProfile.firstName} ${userProfile.lastName}.%0A%0AHe generado una solicitud de pago que podrás encontrar en este enlace:%0A%0A`,
				url: requestMoneyUrl,
			});
		} catch (error) {
			// console.log(error);
		}
	};

	const handleShareWeb = () => {
		const message = `Hola, soy ${userProfile.firstName} ${userProfile.lastName}.%0A%0AHe generado una solicitud de pago que podrás encontrar en este enlace:%0A%0A${requestMoneyUrl}`;

		const whatsappLink = `https://api.whatsapp.com/send?text=${message}`;

		window.open(whatsappLink, '_blank');
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2'>
					Muestra o comparte este código QR para completar esta transacción
				</SectionTitle>
				<QRWrapper>
					<QRCode value={requestMoneyTokenIn} size={170} level='Q' />
				</QRWrapper>
				<BtnContainer>
					<ShareBtn
						variant='outlined'
						color='secondary'
						endIcon={<CustomIcon iconName='faShareFromSquare' />}
						onClick={
							Capacitor.isNativePlatform() ? handleShareNative : handleShareWeb
						}
					>
						Compartir
					</ShareBtn>
				</BtnContainer>
				<Box sx={{ height: 30 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

RequestMoneyStepTwo.propTypes = {
	activeStep: PropTypes.number.isRequired,
	requestMoneyTokenIn: PropTypes.string.isRequired,
	requestMoneyUrl: PropTypes.string.isRequired,
	totalSteps: PropTypes.array.isRequired,
	userProfile: PropTypes.object,
};

export default RequestMoneyStepTwo;
