// Axios import
import AxiosInterceptorRequest from '../../fuctions/AxiosInterceptor';

// REST Get document type
export const getDocumentTypes = () => {
	return (dispatch) => {
		dispatch({ type: 'GET_OPTIONS_START' });

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/catalogue/category/TYPE_DOCUMENT',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(config).then((response) => {
			const data = response.data.data;
			const error = data.error;

			if (data.length > 0) {
				const data = response.data.data.sort((a, b) =>
					a.label.localeCompare(b.label)
				);

				dispatch({
					type: 'GET_DOCUMENT_TYPES_SUCCESS',
					payload: data,
				});

				return;
			}

			if (error) {
				// console.log('Error en tipos de documento.');
				// dispatch(
				// 	showNotification({
				// 		variant: 'error',
				// 		msgNotification: 'Error en tipos de documento.',
				// 	})
				// );
			}
		});
	};
};

// REST Get countries
export const getCountries = () => {
	return (dispatch) => {
		dispatch({ type: 'GET_OPTIONS_START' });

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/catalogue/countries',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(config)
			.then((response) => {
				const data = response.data.data.sort((a, b) =>
					a.name.localeCompare(b.name)
				);

				dispatch({
					type: 'GET_COUNTRIES_SUCCESS',
					payload: data,
				});
			})
			.catch((err) => {
				console.log(err);

				dispatch({ type: 'GET_COUNTRIES_ERROR' });

				// dispatch(
				// 	showNotification({
				// 		variant: 'error',
				// 		msgNotification: 'Error al obtener paises.',
				// 	})
				// );
			});
	};
};
