// Intercetor Import
import AxiosInterceptorRequest from '../../fuctions/AxiosInterceptor';
import { enqueueSnackbar } from './notificationActions';

// Stripe Api Url
const stripeApiUrl = process.env.REACT_APP_BACKEND_URL;

// Get User balance
export const getUserBalance = () => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_BALANCE_START' });

		const getUserBalanceConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'user/accounts/balance',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getUserBalanceConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 200) {
				dispatch({
					type: 'TRANSACTION_BALANCE_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al obtener el saldo',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'TRANSACTION_BALANCE_SUCCESS',
					payload: resp.data.data.accountBalance,
				});
			}
		});
	};
};

// Find User By Phone Number
export const getUserByPhoneNumber = (searchData) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getUserByPhoneNumberConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/user/find',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: searchData,
		};

		AxiosInterceptorRequest(getUserByPhoneNumberConfig)
			.then((resp) => {
				console.log(resp);

				if (resp.status !== 200) {
					dispatch({
						type: 'TRANSACTION_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: 'No se encontró el usuario',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 200) {
					dispatch({
						type: 'TRANSACTION_GET_USER_BY_PHONE_NUMBER_SUCCESS',
						payload: resp.data.data,
					});
				}
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'TRANSACTION_GET_USER_BY_PHONE_NUMBER_END' });
				}, 1000);
			});
	};
};

// Get user by email
export const getUserByEmail = (searchData) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getUserByEmailConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/user/find',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: searchData,
		};

		AxiosInterceptorRequest(getUserByEmailConfig)
			.then((resp) => {
				console.log(resp);

				if (resp.status !== 200) {
					dispatch({
						type: 'TRANSACTION_GET_USER_BY_EMAIL_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: 'No se encontró el usuario',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 200) {
					dispatch({
						type: 'TRANSACTION_GET_USER_BY_EMAIL_SUCCESS',
						payload: resp.data.data,
					});
				}
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'TRANSACTION_GET_USER_BY_EMAIL_END' });
				}, 1000);
			});
	};
};

// Get User Transactions
export const getUserTransactions = () => {
	return (dispatch) => {
		dispatch({ type: 'GET_USER_TRANSACTIONS_START' });

		const getUserTransactionsConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/user/transactions',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getUserTransactionsConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 200) {
				dispatch({
					type: 'GET_USER_TRANSACTIONS_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al cargar las transacciones',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'GET_USER_TRANSACTIONS_SUCCESS',
					payload: resp?.data?.data,
				});
			}
		});
	};
};

// Update User Balance
export const updateUserBalance = () => {
	return (dispatch) => {
		dispatch({
			type: 'TRANSACTIONS_UPDATE_USER_BALANCE_SUCCESS',
		});

		setTimeout(() => {
			dispatch({ type: 'UPDATE_USER_BALANCE_END' });
		}, 3000);
	};
};

// Get User Account
export const getUserAccount = () => {
	return (dispatch) => {
		dispatch({ type: 'GET_USER_ACCOUNT_START' });

		const getUserTransactionsConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/user/accounts/bank',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getUserTransactionsConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 200) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al obtener tu cuenta',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}
			// TODO: preguntar por que resp.data.data[0] toma siempre la primera posición
			if (resp.status === 200) {
				dispatch({
					type: 'GET_USER_ACCOUNT_SUCCESS',
					payload: resp?.data?.data[0],
				});
			}
		});
	};
};

// Send Money In
export const sendMoneyIn = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		console.log(data);

		const sendMoneyConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashin/send',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: { ...data, amount: Number(data.amount) },
		};

		AxiosInterceptorRequest(sendMoneyConfig)
			.then((resp) => {
				console.log(resp);

				if (resp.status !== 200) {
					dispatch({
						type: 'TRANSACTION_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: resp?.response?.data?.message || 'Transacción fallida',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 200) {
					dispatch({
						type: 'TRANSACTION_SEND_MONEY_IN_SUCCESS',
						payload: resp?.data?.data,
					});

					dispatch(
						enqueueSnackbar({
							message: 'Transacción exitosa',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'success',
							},
						})
					);
				}
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'TRANSACTION_END' });
				}, 1000);
			});
	};
};
// TODO: DONDE SE USA ESTA FUNCION
// Charge Money to card
export const chargeSendMoneyToCard = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		console.log(data);

		const paymentInfo = {
			amount: data.amount * 100,
			currency: 'usd',
			customer: data.customer,
			paymentMethod: data.paymentMethodId,
			description: data.comment,
		};

		const chargeSendMoneyToCardStripeConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/stripe/v1/chargeSavedCard',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: JSON.stringify({
				amount: paymentInfo.amount,
				currency: paymentInfo.currency,
				customer: paymentInfo.customer,
				paymentMethod: paymentInfo.paymentMethod,
				description: paymentInfo.description,
			}),
		};

		AxiosInterceptorRequest(chargeSendMoneyToCardStripeConfig).then((res) => {
			const dataResponse = res.data;

			console.log(data);
			console.log('Data: ', dataResponse);

			const { error, message } = dataResponse;

			if (error) {
				const erroMsg =
					message.code === 'amount_too_small'
						? 'El monto mínimo es $0.50. Inténtalo nuevamente.'
						: message.decline_code === 'insufficient_funds'
							? 'Fondos insuficientes. Prueba otra tarjeta.'
							: 'Se ha producido un error. Inténtalo nuevamente.';

				dispatch(
					enqueueSnackbar({
						message: erroMsg,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);

				dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

				return;
			}

			const sendData = {
				userIdTo: data.userIdTo,
				comment: data.comment,
				// qrToken: '',
				amount: Number(data.amount),
				commission: data.commission,
				commissionAmount: data.commissionAmount,
				totalValue: data.totalValue,
				// paymentMethod: 'userCards',
				isCard: data.isCard,
			};

			const chargeSendMoneyToCardConfig = {
				method: 'post',
				maxBodyLength: Infinity,
				url: '/cashin/send',
				headers: {
					'Content-Type': 'application/json',
					Accept: '*/*',
				},
				data: sendData,
			};

			AxiosInterceptorRequest(chargeSendMoneyToCardConfig)
				.then((resp) => {
					console.log(resp);

					if (resp.status !== 200) {
						dispatch({
							type: 'TRANSACTION_ERROR',
						});

						dispatch(
							enqueueSnackbar({
								message: resp?.response?.data?.data || 'Transacción fallida',
								options: {
									key: new Date().getTime() + Math.random(),
									variant: 'error',
								},
							})
						);
					}

					if (resp.status === 200) {
						dispatch({
							type: 'TRANSACTION_SEND_MONEY_IN_SUCCESS',
							payload: resp?.data?.data,
						});

						dispatch(
							enqueueSnackbar({
								message: 'Transacción exitosa',
								options: {
									key: new Date().getTime() + Math.random(),
									variant: 'success',
								},
							})
						);
					}
				})
				.then(() => {
					setTimeout(() => {
						dispatch({ type: 'TRANSACTION_END' });
					}, 1000);
				});
		});
		// .then(() => {
		// 	const sendData = {
		// 		userIdTo: data.userIdTo,
		// 		comment: data.comment,
		// 		// qrToken: '',
		// 		amount: Number(data.amount),
		// 		commission: data.commission,
		// 		commissionAmount: data.commissionAmount,
		// 		totalValue: data.totalValue,
		// 		// paymentMethod: 'userCards',
		// 		isCard: data.isCard,
		// 	};

		// 	const chargeSendMoneyToCardConfig = {
		// 		method: 'post',
		// 		maxBodyLength: Infinity,
		// 		url: '/cashin/send',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			Accept: '*/*',
		// 		},
		// 		data: sendData,
		// 	};

		// 	AxiosInterceptorRequest(chargeSendMoneyToCardConfig)
		// 		.then((resp) => {
		// 			console.log(resp);

		// 			if (resp.status !== 200) {
		// 				dispatch({
		// 					type: 'TRANSACTION_ERROR',
		// 				});

		// 				dispatch(
		// 					enqueueSnackbar({
		// 						message: resp?.response?.data?.data || 'Transacción fallida',
		// 						options: {
		// 							key: new Date().getTime() + Math.random(),
		// 							variant: 'error',
		// 						},
		// 					})
		// 				);
		// 			}

		// 			if (resp.status === 200) {
		// 				dispatch({
		// 					type: 'TRANSACTION_SEND_MONEY_IN_SUCCESS',
		// 					payload: resp?.data?.data,
		// 				});

		// 				dispatch(
		// 					enqueueSnackbar({
		// 						message: 'Transacción exitosa',
		// 						options: {
		// 							key: new Date().getTime() + Math.random(),
		// 							variant: 'success',
		// 						},
		// 					})
		// 				);
		// 			}
		// 		})
		// 		.then(() => {
		// 			setTimeout(() => {
		// 				dispatch({ type: 'TRANSACTION_END' });
		// 			}, 1000);
		// 		});
		// });
	};
};

// Withdraw Money validation code
export const getWithdrawMoneyValidationCode = () => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getWithdrawMoneyValidationCodeConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashout/transactionCode',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getWithdrawMoneyValidationCodeConfig).then(
			(resp) => {
				console.log(resp);

				if (resp.status !== 200) {
					dispatch({
						type: 'TRANSACTION_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: 'Transacción fallida',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 200) {
					dispatch({
						type: 'TRANSACTION_WITHDRAW_MONEY_VALIDATION_CODE_SUCCESS',
						payload: resp?.data?.data,
					});

					dispatch(
						enqueueSnackbar({
							message: 'Validación exitosa',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'success',
							},
						})
					);
				}
			}
		);
	};
};

// Withdraw Money
export const payoutAmazonasMoney = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });
		// TODO: en esta funcion se elimina lo desarrollado para stripe
		const withdrawMoneyConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			// url: '/v1/http/createPayOuts',
			url: '/cashout/insertTransaction',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: data,
		};

		AxiosInterceptorRequest(withdrawMoneyConfig)
			.then((resp) => {
				// console.log(resp);

				if (resp.status !== 200 && resp?.response?.status !== 500) {
					dispatch({
						type: 'TRANSACTION_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: 'Transacción fallida',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 200) {
					dispatch({
						type: 'TRANSACTION_WITHDRAW_MONEY_IN_SUCCESS',
						payload: resp?.data?.data,
					});

					dispatch(
						enqueueSnackbar({
							message: 'Transacción exitosa',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'success',
							},
						})
					);
				}
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'TRANSACTION_END' });
				}, 1000);
			});
	};
};

// Withdraw Money
export const payoutStripe = (transactionData) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		console.log(transactionData);

		const tranferData = {
			amount: transactionData.amount * 100,
			currency: transactionData.currency,
			destination: transactionData.destination,
			description: transactionData.description,
		};

		fetch(`${stripeApiUrl}/stripe/v1/createTransferToUser`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(tranferData),
		})
			.then((res) => res.json())
			.then((data) => {
				const { error, message } = data;

				console.log(data);

				if (error) {
					let errorMsj = '';

					dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

					if (message.code === 'balance_insufficient') {
						errorMsj = 'Los fondos son insuficientes.';
					}
					// else {
					// 	errorMsj = 'Se ha producido un error. Inténta nuevamente.';
					// }

					dispatch(
						enqueueSnackbar({
							message: errorMsj,
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);

					return;
				}
			})
			.then(() => {
				const payoutData = {
					account: transactionData.account,
					amount: transactionData.amount * 100,
					currency: 'usd',
					description: transactionData.description,
					destination: transactionData.bankId,
					method: 'standard',
					statement_descriptor: 'Acreditacion Utransfer',
				};

				fetch(`${stripeApiUrl}/stripe/v1/payout`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payoutData),
				})
					.then((res) => res.json())
					.then((data) => {
						console.log(data);

						const { error, message } = data;

						if (error) {
							let errorMsj = '';
							let variant = '';

							dispatch({ type: 'STRIPE_TRANSACTION_ERROR' });

							if (message.code === 'balance_insufficient') {
								// errorMsj = 'Transacción se está procesando.';
								variant = 'success';
								errorMsj = 'Los fondos son insuficientes.';
							}
							// else {
							// 	errorMsj = 'Se ha producido un error. Inténta nuevamente.';
							// 	variant = 'error';
							// }

							dispatch(
								enqueueSnackbar({
									message: errorMsj,
									options: {
										key: new Date().getTime() + Math.random(),
										variant: variant,
										// variant: 'error',
									},
								})
							);

							return;
						}
					})
					.then(() => {
						const withdrawData = {
							idAccount: transactionData.idAccount,
							bankAccount: transactionData.bankAccount,
							bankAccountNumber: transactionData.bankAccountNumber,
							isStripeAccount: 1,
							bankAccountType: 'Ahorro',
							country: 'EC',
							country_code: 180,
							description: transactionData.description,
							stripeTransactionId: 'transacción de stripe',
							email: transactionData.email,
							identification: transactionData.documentId,
							identificationType: 'CC',
							lastname: transactionData.lastname,
							name: transactionData.name,
							transactionValue: transactionData.transactionValue,
						};

						console.log(withdrawData);

						const withdrawMoneyConfig = {
							method: 'post',
							maxBodyLength: Infinity,
							url: '/cashin/createPayOut',
							headers: {
								'Content-Type': 'application/json',
								Accept: '*/*',
							},
							data: withdrawData,
						};

						AxiosInterceptorRequest(withdrawMoneyConfig)
							.then((resp) => {
								console.log(resp);

								if (resp.status !== 200) {
									dispatch({
										type: 'TRANSACTION_ERROR',
									});

									dispatch(
										enqueueSnackbar({
											message:
												resp?.response?.data?.message || 'Transacción fallida',
											options: {
												key: new Date().getTime() + Math.random(),
												variant: 'error',
											},
										})
									);
								}

								if (resp.status === 200) {
									dispatch({ type: 'STRIPE_TRANSACTION_SUCCESS' });
									dispatch({
										type: 'TRANSACTION_WITHDRAW_MONEY_IN_SUCCESS',
										payload: resp?.data?.data,
									});

									dispatch(
										enqueueSnackbar({
											message: 'Retiro realizado exitosamente',
											options: {
												key: new Date().getTime() + Math.random(),
												variant: 'success',
											},
										})
									);

									dispatch({ type: 'STRIPE_TRANSACTION_END' });
								}
							})
							.then(() => {
								setTimeout(() => {
									dispatch({ type: 'TRANSACTION_END' });
								}, 1000);
							});
					})
					.catch((err) => {
						console.log(err);
						dispatch({ type: 'STRIPE_TRANSACTION_END' });
					});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'STRIPE_TRANSACTION_END' });
			});
	};
};

// Switch service getter
export const getSwitchService = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getSwitchServiceConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashout/bestProcessor',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: { idAccount: data.idAccount },
		};

		AxiosInterceptorRequest(getSwitchServiceConfig).then((res) => {
			console.log(res);

			const { service } = res.data.data;

			if (service === 'STRIPE') {
				dispatch(
					payoutStripe({
						...data.stripePayoutData,
						bankId: res.data.data.bankId,
					})
				);
			} else if (service === 'BANCO AMAZONAS') {
				dispatch(payoutAmazonasMoney(data.amazonasPayoutData));
			}
		});
	};
};

// Request Money
export const requestMoney = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const requestMoneyConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashin/qr',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: { ...data, amount: Number(data.amount) },
		};

		AxiosInterceptorRequest(requestMoneyConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 201) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: resp?.response?.data?.data || 'Transacción fallida',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 201) {
				dispatch({
					type: 'TRANSACTION_REQUEST_MONEY_SUCCESS',
					payload: resp?.data?.data,
				});

				dispatch(
					enqueueSnackbar({
						message: 'Solicitud exitosa',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'success',
						},
					})
				);
			}
		});
	};
};

// Get Request Money Token
export const getRequestMoneyToken = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_REQUEST_MONEY_TOKEN',
			payload: data,
		});
	};
};

// Send Money Out by QR
export const getRequestMoneyData = (token) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getMoneyOutByQRConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashin/qr/validate',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: token,
		};

		AxiosInterceptorRequest(getMoneyOutByQRConfig).then((resp) => {
			// console.log(resp);

			if (
				resp.status !== 200 &&
				resp?.response?.status !== 422 &&
				resp?.response?.status !== 500
			) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Transacción fallida',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp?.response?.status === 422) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: resp?.response?.data?.data,
						// message: resp.response.data.data || 'Transacción fallida',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'TRANSACTION_GET_MONEY_REQUEST_SUCCESS',
					payload: {
						...resp.data.data,
						qrToken: token.qrToken,
					},
				});
			}
		});
	};
};

// Clean Sen Money Out by QR
export const cleanRequestMoneyData = () => {
	return (dispatch) => {
		dispatch({ type: 'CLEAN_REQUEST_MONEY_DATA' });

		setTimeout(() => {
			dispatch({ type: 'TRANSACTION_END' });
		}, 1500);
	};
};

// Get Configuration by country
export const getConfigurationByCountry = (country) => {
	return (dispatch) => {
		dispatch({ type: 'GET_CONFIGURATION_START' });

		const getConfigurationByCountryConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `/backoffice/config/transaction/country/${country}`,
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getConfigurationByCountryConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 200) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Pendiente configurar datos personales',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'GET_CONFIGURATION_BY_COUNTRY_SUCCESS',
					payload: resp.data.data,
				});
			}
		});
	};
};

// Reset transactions modal
export const resetNoCountryConfiguration = () => {
	return (dispatch) => {
		dispatch({
			type: 'RESET_NO_COUNTRY_CONFIGURATION',
		});
	};
};

// Save card
export const saveCards = (data) => {
	return (dispatch) => {
		dispatch({ type: 'TRANSACTION_START' });

		const getUserByPhoneNumberConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/cashin/cards',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: data,
		};

		AxiosInterceptorRequest(getUserByPhoneNumberConfig).then((resp) => {
			console.log(resp);

			if (resp.status !== 200) {
				dispatch({
					type: 'TRANSACTION_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al guardar la tarjeta',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'SAVE_CARD_SUCCESS',
				});
			}
		});
	};
};
