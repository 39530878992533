import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Imports
import CustomIcon from '../shared/CustomIcon';

// Styled Components
const InnerDialog = styled(Dialog)({
	'.MuiPaper-root': {
		borderRadius: '15px',
		maxHeight: '650px',
		maxWidth: '450px !important',
		minWidth: '450px !important',
		overflow: 'hidden',

		'@media (max-width: 768px)': {
			flexDirection: 'column',
			minWidth: '90vw !important',
			maxHeight: '90vh !important',
		},
	},
});

const CustomDialogTitle = styled(DialogTitle)({
	backgroundColor: 'var(--primary) !important',
	color: '#fff !important',
	padding: '1rem !important',
	paddingBottom: '0.5rem !important',
	display: 'flex !important',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '32px !important',
});

const LoadingContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '120px',
});

const ConentContainer = styled(Box)({
	// paddingLeft: '1rem',
	// marginTop: '0.5rem',
	height: '100%',

	'@media (max-width: 768px)': {
		// paddingLeft: '0.5rem',
	},
});

const CustomDialog = (props) => {
	// Props destructuring
	const { buttons, content, minHeight, onClose, openDialog, loading } = props;

	return (
		<InnerDialog
			open={openDialog}
			onClose={onClose}
			aria-labelledby='dialog-title'
			aria-describedby='dialog-description'
		>
			<CustomDialogTitle id='dialog-title' noWrap>
				{content.title}
				{onClose ? (
					<IconButton
						aria-label='close'
						onClick={onClose}
						sx={{ width: '20px', height: '20px', p: 2 }}
					>
						<CustomIcon
							iconName='faXmark'
							iconColor='#fff'
							iconSize={'1.2rem'}
						/>
					</IconButton>
				) : null}
			</CustomDialogTitle>
			<DialogContent dividers sx={{ minHeight: minHeight }}>
				{loading ? (
					<LoadingContainer>
						<CircularProgress />
						<DialogContentText sx={{ mt: 2 }}>Cargando...</DialogContentText>
					</LoadingContainer>
				) : (
					<>
						{content.subtitle && (
							<DialogContentText id='dialog-description'>
								{content.subtitle}
							</DialogContentText>
						)}
						<ConentContainer>{content.content}</ConentContainer>
					</>
				)}
			</DialogContent>
			<DialogActions>{buttons}</DialogActions>
		</InnerDialog>
	);
};

CustomDialog.propTypes = {
	openDialog: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	content: PropTypes.object.isRequired,
	buttons: PropTypes.node.isRequired,
	loading: PropTypes.bool,
	minHeight: PropTypes.string,
};

export default CustomDialog;
