import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components

const CustomTabPanel = (props) => {
	// Props destructuring
	const { children, value, index, px, pt, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ px, pt }}>{children}</Box>}
		</div>
	);
};

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
	px: PropTypes.string,
	pt: PropTypes.number,
};

CustomTabPanel.defeultProps = {
	px: '20px',
	pt: 11,
};

export default CustomTabPanel;
