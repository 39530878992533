// functions imports
import AxiosInterceptorRequest from '../../fuctions/AxiosInterceptor';

// Notification imports
import { enqueueSnackbar } from './notificationActions';

// Show/Hide Add Account Form
export const showHideAddAccountForm = () => {
	return (dispatch) => {
		dispatch({
			type: 'SHOW_HIDE_ADD_ACCOUNT_FORM',
		});
	};
};

// Show/Hide Add Account Form
export const hideAddAccountForm = () => {
	return (dispatch) => {
		dispatch({
			type: 'HIDE_ADD_ACCOUNT_FORM',
		});
	};
};

// REST Get user bank accounts
export const getUserAccounts = () => {
	return (dispatch) => {
		dispatch({ type: 'BANK_ACCOUNTS_START' });

		const getUserAccountsConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/user/accounts/bank',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getUserAccountsConfig).then((resp) => {
			if (resp.status !== 200 && resp?.response?.status !== 500) {
				dispatch({
					type: 'GET_BANK_ACCOUNTS_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al cargar las cuentas',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}
			if (resp.status === 200) {
				dispatch({
					type: 'GET_BANK_ACCOUNTS_SUCCESS',
					payload: resp.data.data,
				});
			}
		});
	};
};

// Remove user accounts from state
export const removeUserAccounts = () => {
	return (dispatch) => {
		dispatch({
			type: 'REMOVE_USER_ACCOUNTS',
		});
	};
};

// REST Get account types
export const getAccountTypes = () => {
	return (dispatch) => {
		const getAccountTypesConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: '/catalogue/category/TYPE_BANK_ACCOUNT',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getAccountTypesConfig).then((resp) => {
			if (resp.status !== 200 && resp?.response?.status !== 500) {
				dispatch({
					type: 'GET_ACCOUNT_TYPES_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al cargar los tipos de cuenta',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}
			if (resp.status === 200) {
				dispatch({
					type: 'GET_ACCOUNT_TYPES_SUCCESS',
					payload: resp.data.data,
				});
			}
		});
	};
};

// REST Get banks list
export const getBanksList = (country) => {
	return (dispatch) => {
		dispatch({ type: 'GET_BANK_LIST_START' });
		const getBanksListConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `/catalogue/bank/country/${country}`,
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getBanksListConfig).then((resp) => {
			if (resp.status !== 200 && resp?.response?.status !== 500) {
				dispatch({
					type: 'GET_BANKS_LIST_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al cargar los bancos',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				dispatch({
					type: 'GET_BANKS_LIST_SUCCESS',
					payload: resp.data.data,
				});
			}
		});
	};
};

// REST Get Countries list
export const getCountries = () => {
	return (dispatch) => {
		const getCountriesListConfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `/catalogue/countries`,
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
		};

		AxiosInterceptorRequest(getCountriesListConfig).then((resp) => {
			if (resp.status !== 200 && resp?.response?.status !== 500) {
				dispatch({
					type: 'GET_COUNTRIES_LIST_ERROR',
				});

				dispatch(
					enqueueSnackbar({
						message: 'Error al cargar los paises',
						options: {
							key: new Date().getTime() + Math.random(),
							variant: 'error',
						},
					})
				);
			}

			if (resp.status === 200) {
				// debugger
				console.log(typeof resp.data.data);
				dispatch({
					type: 'GET_COUNTRIES_SUCCESS',
					payload: resp.data.data,
				});
			}
		});
	};
};
// REST Add bank account
export const addBankAccount = (data) => {
	return (dispatch) => {
		dispatch({ type: 'ADD_BANK_ACCOUNT_START' });

		const addBankAccountConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: '/user/accounts/bank',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			data: data,
		};

		AxiosInterceptorRequest(addBankAccountConfig)
			.then((resp) => {
				if (resp.status !== 201 && resp?.response?.status !== 500) {
					dispatch({
						type: 'ADD_BANK_ACCOUNT_ERROR',
					});

					dispatch(
						enqueueSnackbar({
							message: 'Error al agregar la cuenta',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'error',
							},
						})
					);
				}

				if (resp.status === 201) {
					dispatch({
						type: 'ADD_BANK_ACCOUNT_SUCCESS',
					});

					dispatch(
						enqueueSnackbar({
							message: 'Cuenta agregada correctamente',
							options: {
								key: new Date().getTime() + Math.random(),
								variant: 'success',
							},
						})
					);
				}
			})
			.then(() => {
				setTimeout(() => {
					dispatch({ type: 'ADD_BANK_ACCOUNT_END' });
				}, 3000);
			});
	};
};
