import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import CustomInput from '../../../../../layout/CustomInput';
import StepperIndicator from '../../../../../shared/StepperIndicator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0rem !important',
});

const SendMoneyStepTwo = (props) => {
	// Props destructuring
	const {
		activeStep,
		aditionalData,
		commissionConfig,
		errors,
		getUserToSendMoneyData,
		requestMoneyData,
		searchUserBy,
		searchByEmail,
		setAditionalData,
		setTransactionData,
		totalSteps,
		transactionData,
	} = props;

	// Component functions
	const handleAmmountChange = (e) => {
		const inputValue = e.target.value;

		setData(inputValue);
	};

	const setData = (value) => {
		const regex = /^\d+(\.\d{0,2})?$/;

		if (value === '' || regex.test(value)) {
			setTransactionData({
				...transactionData,
				amount: value,
			});
		}

		// Verificar si el valor ingresado es un número válido
		const parsedValue = parseFloat(value);

		if (!isNaN(parsedValue)) {
			const commission = parsedValue * aditionalData.commissionValue;
			const formattedCommission = '$ ' + commission.toFixed(2);
			const total = parsedValue + commission * commissionConfig;
			const formattedTotal = '$ ' + total.toFixed(2);

			setAditionalData({
				...aditionalData,
				commissionAmount: formattedCommission,
				total: formattedTotal,
			});
		} else {
			setAditionalData({
				...aditionalData,
				commissionAmount: '$ 0.00',
				total: '$ 0.00',
			});
		}
	};

	useEffect(() => {
		if (
			Object.keys(requestMoneyData).length !== 0 &&
			aditionalData?.commissionValue
		) {
			setData(requestMoneyData.amount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aditionalData.commissionValue, requestMoneyData]);

	const handleChange = (e) => {
		setTransactionData({
			...transactionData,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2'>¿Quién recibe el pago?</SectionTitle>
				<List sx={{ py: 0 }}>
					<ListItem alignItems='flex-start' sx={{ padding: 0, mb: 1 }}>
						<ListItemText
							primary={
								requestMoneyData.userName ||
								`${getUserToSendMoneyData.firstName} ${getUserToSendMoneyData.lastName}`
							}
							secondary={
								searchUserBy === 'phoneNumber'
									? requestMoneyData.phoneNumber ||
									  getUserToSendMoneyData.phoneNumber
									: searchByEmail.email
							}
							sx={{ my: 0 }}
						/>
					</ListItem>
				</List>
				<SectionTitle variant='body2'>Monto</SectionTitle>
				<CustomInput
					name='amount'
					type='text'
					inputHintText={'Ej: $ 100.00'}
					value={transactionData.amount}
					onChange={handleAmmountChange}
					isCentered
					hasAdornment={Boolean(transactionData.amount !== '')}
					error={Boolean(errors.amount)}
					errorText={errors.amount}
					inputSize={'small'}
					isNumber
					isDisabled={Boolean(requestMoneyData.amount)}
				/>
				<SectionTitle variant='body2'>Comentarios</SectionTitle>
				<CustomInput
					name='comment'
					type='textarea'
					value={requestMoneyData.comment || transactionData.comment}
					onChange={handleChange}
					inputSize={'small'}
					multiline
					inputHintText={'Ej: Pago de servicios'}
					error={Boolean(errors.comment)}
					errorText={errors.comment}
					isDisabled={Boolean(requestMoneyData.comment)}
				/>
				<List>
					<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
						<ListItemText primary={'Comisión'} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.commission}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
					<ListItem alignItems='flex-start' sx={{ padding: 0, my: 0 }}>
						<ListItemText primary={'Valor comisión'} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.commissionAmount}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
					<ListItem alignItems='flex-start' sx={{ padding: 0 }}>
						<ListItemText primary={'Total'} sx={{ my: 0 }} />
						<ListItemText
							primary={aditionalData.total}
							sx={{ textAlign: 'end', my: 0 }}
						/>
					</ListItem>
				</List>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={73}
			/>
		</>
	);
};

SendMoneyStepTwo.propTypes = {
	activeStep: PropTypes.number.isRequired,
	aditionalData: PropTypes.object.isRequired,
	commissionConfig: PropTypes.number,
	errors: PropTypes.object.isRequired,
	getUserToSendMoneyData: PropTypes.object.isRequired,
	requestMoneyData: PropTypes.object,
	searchByEmail: PropTypes.object.isRequired,
	searchUserBy: PropTypes.string.isRequired,
	setAditionalData: PropTypes.func.isRequired,
	setTransactionData: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
	transactionData: PropTypes.object.isRequired,
};

export default SendMoneyStepTwo;
