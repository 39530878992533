import PropTypes from 'prop-types';

const RequestMoneyStepOneValidation = (maxSend, minSend, values = {}) => {
	let errors = {};

	// Amount validation
	if (values.amount === '') {
		errors.amount = 'El monto es requerido';
	} else {
		const amountFloat = parseFloat(values.amount);
		if (isNaN(amountFloat)) {
			errors.amount = 'El monto debe ser un número válido';
		} else if (amountFloat < minSend) {
			errors.amount = `El monto mínimo es de $ ${minSend.toFixed(2)}`;
		} else if (amountFloat > maxSend) {
			errors.amount = `El monto máximo es de $ ${maxSend.toFixed(2)}`;
		} else {
			const decimalCount = (values.amount.split('.')[1] || '').length;
			if (decimalCount > 2) {
				errors.amount = 'El monto debe tener hasta dos decimales';
			}
		}
	}

	// Description validation
	if (values.comment.length > 0 && values.comment.length < 4) {
		errors.comment = 'La descripción es muy corta';
	} else if (values.comment.length > 100) {
		errors.comment = 'La descripción es muy larga';
	}

	return errors;
};

RequestMoneyStepOneValidation.propTypes = {
	values: PropTypes.object.isRequired,
};

export default RequestMoneyStepOneValidation;
