const initState = {
	balance: 0.0,
	depositData: {},
	getUserToSendMoneyData: {},
	getUserToSendMoneyDataStatus: false,
	loading: false,
	loadingBalance: false,
	loadingTransactions: false,
	loadingUser: false,
	proceedDeposit: false,
	reloadUserBalance: false,
	userAccount: {},
	userTransactions: [],
	withdrawStepStatus: false,
	requestMoneyData: {},
	requestMoneyDataCleaned: false,
	requestMoneyTokenIn: '',
	requestMoneyTokenOut: '',
	configurationByCountry: {},
	showNoOperationsDialog: false,
	withdrawValidationCode: '',
	swithService: '',
};

const transactionsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'TRANSACTION_START':
		case 'STRIPE_TRANSACTION_START':
			return {
				...state,
				loading: true,
			};
		case 'TRANSACTION_BALANCE_START':
			return {
				...state,
				loadingBalance: true,
			};
		case 'GET_USER_TRANSACTIONS_START':
			return {
				...state,
				loadingTransactions: true,
			};
		case 'GET_USER_ACCOUNT_START':
			return {
				...state,
				loadingUser: true,
			};
		case 'TRANSACTIONS_DEPOSIT_MONEY_SUCCESS':
			return {
				...state,
				loading: false,
				depositData: action.payload,
			};
		case 'STRIPE_TRANSACTION_DEPOSIT_MONEY_SUCCESS':
		case 'STRIPE_TRANSACTION_CREACTE_USER_SUCCESS':
		case 'STRIPE_TRANSACTION_CARD_ADD_SUCCESS':
		case 'STRIPE_TRANSACTION_CLIENT_SECRET_SUCCESS':
		case 'STRIPE_TRANSACTION_ERROR':
		case 'STRIPE_TRANSACTION_END':
		case 'SAVE_CARD_SUCCESS':
			return {
				...state,
				loading: false,
			};
		case 'TRANSACTION_GET_USER_BY_PHONE_NUMBER_SUCCESS':
		case 'TRANSACTION_GET_USER_BY_EMAIL_SUCCESS':
			return {
				...state,
				loading: false,
				getUserToSendMoneyData: action.payload,
				getUserToSendMoneyDataStatus: true,
			};
		case 'TRANSACTION_GET_USER_BY_PHONE_NUMBER_ERROR':
		case 'TRANSACTION_GET_USER_BY_EMAIL_ERROR':
			return {
				...state,
				loading: false,
				getUserToSendMoneyData: {},
				getUserToSendMoneyDataStatus: false,
			};
		case 'TRANSACTION_GET_USER_BY_PHONE_NUMBER_END':
		case 'TRANSACTION_GET_USER_BY_EMAIL_END':
			return {
				...state,
				getUserToSendMoneyDataStatus: false,
			};
		case 'TRANSACTION_SEND_MONEY_IN_SUCCESS':
			return {
				...state,
				loading: false,
				getUserToSendMoneyDataStatus: true,
				requestMoneyTokenOut: '',
			};
		case 'TRANSACTION_WITHDRAW_MONEY_VALIDATION_CODE_SUCCESS':
			return {
				...state,
				loading: false,
				withdrawValidationCode: `${action.payload}`,
			};
		case 'TRANSACTION_WITHDRAW_MONEY_IN_SUCCESS':
			return {
				...state,
				loading: false,
				withdrawStepStatus: true,
			};
		case 'TRANSACTION_REQUEST_MONEY_SUCCESS':
			return {
				...state,
				loading: false,
				requestMoneyTokenIn: action.payload,
			};
		case 'SET_REQUEST_MONEY_TOKEN':
			return {
				...state,
				loading: false,
				requestMoneyTokenOut: action.payload,
			};
		case 'TRANSACTION_ERROR':
		case 'TRANSACTIONS_END':
			return {
				...state,
				loading: false,
			};
		case 'TRANSACTION_GET_MONEY_REQUEST_SUCCESS':
			return {
				...state,
				loading: false,
				requestMoneyData: action.payload,
			};
		case 'GET_CONFIGURATION_BY_COUNTRY_SUCCESS':
			return {
				...state,
				loading: false,
				configurationByCountry: action.payload,
				showNoOperationsDialog: Boolean(!action.payload),
			};
		case 'RESET_NO_COUNTRY_CONFIGURATION':
			return {
				...state,
				showNoOperationsDialog: false,
			};
		case 'CLEAN_REQUEST_MONEY_DATA':
			return {
				...state,
				loading: false,
				requestMoneyData: {},
				requestMoneyTokenIn: '',
				requestMoneyTokenOut: '',
				requestMoneyDataCleaned: true,
			};
		case 'TRANSACTION_END':
			return {
				...state,
				getUserToSendMoneyDataStatus: false,
				getUserToSendMoneyData: {},
				withdrawStepStatus: false,
				requestMoneyDataCleaned: false,
			};
		case 'TRANSACTION_BALANCE_SUCCESS':
			return {
				...state,
				loadingBalance: false,
				balance: action.payload,
			};
		case 'GET_USER_ACCOUNT_SUCCESS':
			return {
				...state,
				loadingUser: false,
				userAccount: action.payload,
			};
		case 'TRANSACTIONS_UPDATE_USER_BALANCE_SUCCESS':
			return {
				...state,
				reloadUserBalance: true,
			};
		case 'UPDATE_USER_BALANCE_END':
			return {
				...state,
				reloadUserBalance: false,
			};
		case 'GET_USER_TRANSACTIONS_SUCCESS':
			return {
				...state,
				userTransactions: action.payload,
				loadingTransactions: false,
			};
		case 'GET_USER_TRANSACTIONS_ERROR':
			return {
				...state,
				loadingTransactions: false,
			};
		case 'SIGNOUT':
			return {
				...state,
				balance: 0.0,
				depositData: {},
				getUserToSendMoneyData: {},
				getUserToSendMoneyDataStatus: false,
				loading: false,
				loadingBalance: false,
				loadingTransactions: false,
				loadingUser: false,
				proceedDeposit: false,
				reloadUserBalance: false,
				userAccount: {},
				userTransactions: [],
				withdrawStepStatus: false,
				requestMoneyData: {},
				requestMoneyTokenIn: '',
				requestMoneyTokenOut: '',
				configurationByCountry: {},
				showNoOperationsDialog: false,
				withdrawValidationCode: '',
			};
		default:
			return state;
	}
};

export default transactionsReducer;
