import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

const theme = createTheme({
	palette: {
		type: 'light',
		mode: 'light',
		background: {
			default: '#fff',
		},
		primary: {
			main: '#FF7015',
		},
		secondary: {
			main: '#4D77CA',
		},
		error: {
			main: '#FA1D21',
		},
		transparent: {
			main: 'transparent',
		},
		white: {
			main: '#ffffff',
		},
	},
	typography: {
		fontFamily: 'Publica Play, sans-serif',
		color: '#555555',
		h1: { color: '#333333' },
		h2: { color: '#333333' },
		h3: { color: '#555555' },
		h4: { color: '#555555' },
		h5: { color: '#555555' },
		h6: { color: '#555555' },
		subtitle1: { color: '#333333' },
		subtitle2: { color: '#555555' },
		body1: { color: '#555555' },
		body2: { color: '#555555' },
		p: { color: '#555555' },
		button: { textTransform: 'none' },
	},
	esES,
});

export default theme;
