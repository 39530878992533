import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Redux Imports
import { connect } from 'react-redux';

//MUI Imports
import { BottomNavigation, Paper } from '@mui/material';

// Device Detect
import { isIOS } from 'react-device-detect';

// Styled Components imports
import { styled } from 'styled-components';

// Local Imports
import BottomNav from './BottomNav';

// Redux Actions Imports
import { getUserAccount } from '../store/actions/transactionsActions';
import { removeUserAccounts } from '../store/actions/bankAccountsActions';

// Styled components
const BottomContainer = styled(Paper)({
	position: 'fixed',
	bottom: 0,
	left: 0,
	right: 0,
	borderTopLeftRadius: '20px !important',
	borderTopRightRadius: '20px !important',

	'@media (min-width: 768px)': {
		display: 'none',
	},
});

const CustomBottonNav = styled(BottomNavigation)({
	backgroundColor: 'var(--secondary) !important',
	height: `${isIOS ? '85px' : '75px'} !important`,
	borderTopLeftRadius: '20px !important',
	borderTopRightRadius: '20px !important',
	paddingTop: '3px',

	'@media (max-width: 320px)': {
		height: '65px !important',
	},

	'@media (min-width: 321px) and (max-width: 360px)': {
		height: `${isIOS ? '75px' : '70px'} !important`,
	},
});

const BottomNavBar = (props) => {
	// Props destructuring
	const { getUserAccount, innerRoute, removeUserAccounts } = props;

	// Component route
	const actualRoute = innerRoute[innerRoute.length - 1];

	// Component Hooks
	const location = useLocation();

	useEffect(() => {
		if (actualRoute !== 'billetera') {
			removeUserAccounts();
		}
	}, [actualRoute, removeUserAccounts]);

	useEffect(() => {
		getUserAccount();
	}, [getUserAccount]);

	// Component variables
	const routeSplit = location.pathname.split('/');

	const navMenu = [
		{
			navRoute: '/inicio',
			navIcon: 'FaHome',
			navTitle: 'Inicio',
		},
		{
			navRoute: '/historial',
			navIcon: 'faListCheck',
			navTitle: 'Historial',
		},
		{
			navRoute: '',
			navIcon: 'faMoneyBillTransfer',
			navTitle: 'transacciones',
		},
		{
			navRoute: '/cuenta',
			navIcon: 'FaUser',
			navTitle: 'Mi perfil',
		},
		{
			navRoute: '/billetera',
			navIcon: 'FaWallet',
			navTitle: 'Cuentas',
		},
	];

	return (
		<BottomContainer elevation={3}>
			<CustomBottonNav showLabels>
				{navMenu.map((nav, key) => (
					<BottomNav
						activeRoute={routeSplit[1]}
						navIcon={nav.navIcon}
						navTitle={nav.navTitle}
						navRoute={nav.navRoute}
						index={key}
						key={nav.navTitle}
					/>
				))}
			</CustomBottonNav>
		</BottomContainer>
	);
};

BottomNavBar.propTypes = {
	getUserAccount: PropTypes.func.isRequired,
	innerRoute: PropTypes.array.isRequired,
	removeUserAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	const { innerRoute } = state.innerRouter;
	return {
		innerRoute,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserAccount: () => dispatch(getUserAccount()),
		removeUserAccounts: () => dispatch(removeUserAccounts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavBar);
