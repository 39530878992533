// Change inner route
export const changeInnerRoute = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CHANGE_ROUTE',
			payload: data,
		});
	};
};

// Back to previous inner route
export const backToPreviousInnerRoute = () => {
	return (dispatch) => {
		dispatch({
			type: 'BACK_TO_PREVIOUS_ROUTE',
		});
	};
};

// Clear inner route
export const clearInnerRoute = () => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_ROUTE',
		});
	};
};
