import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components imports
import StepperIndicator from '../../../../../../shared/StepperIndicator';
import CustomInput from '../../../../../../layout/CustomInput';
import CustomSelectInputSearch from '../../../../../../layout/CustomSelectInputSearch';

// Fuctions imports
import { textTruncator } from '../../../../../../fuctions/textTruncator';

// Styled Components
const Wrapper = styled(Box)({
	width: '100%',
	maxHeight: 'calc(100vh / 2)',
});

const SectionTitle = styled(Typography)({
	fontSize: '1rem !important',
	fontWeight: '400 !important',
	color: 'rgba(0, 0, 0, 0.6) !important',
	marginTop: '0.5rem !important',
});

const AddBankAccountStepOne = (props) => {
	// Props destructuring
	const {
		accountTypes,
		activeStep,
		bankData,
		bankList,
		errors,
		setBankData,
		totalSteps,
	} = props;

	// Component state
	const [bankListModified, setBankListModified] = useState([]);
	const [accountTypesModified, setAccountTypesModified] = useState([]);

	// Component hooks
	useEffect(() => {
		if (bankData.bankCountry !== '') {
			setBankListModified(
				bankList
					.filter((bank) => bank.id !== 1)
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((bank) => ({ label: textTruncator(bank.name), id: bank.id }))
			);

			setAccountTypesModified(
				accountTypes
					.filter((accountType) => accountType.data.cod_amazonas !== 0)
					.map((accountType) => ({
						label: accountType.label,
						id: accountType.id,
					}))
			);
		}
	}, [accountTypes, bankData, bankList]);

	// Compoent Functions
	const handleBankCountryChange = (value) => {
		setBankData({
			...bankData,
			bankCountry: value.label,
			bankCountryCode: value.id,
		});
	};

	const handleBankChange = (value) => {
		setBankData({
			...bankData,
			bankName: value.label,
			bankId: value.id,
		});
	};

	const handleChange = (e) => {
		setBankData({
			...bankData,
			[e.target.name]: e.target.value,
		});
	};

	const handleAccountTypeChange = (value) => {
		setBankData({
			...bankData,
			typeAccount: value.id,
			typeAccountText: value.label,
		});
	};

	console.log(bankData);

	// TODO: Lista provisional de paises (retirar cuando se implemente el endpoint)
	const countries = [{ label: 'Ecuador', id: 180 }];

	return (
		<>
			<Wrapper>
				<SectionTitle variant='body2' sx={{ mb: 1.5 }}>
					Cuenta de otros bancos
				</SectionTitle>

				<CustomSelectInputSearch
					options={countries}
					title={'Selecciona un país'}
					handleChange={handleBankCountryChange}
					value={bankData.bankCountry}
					error={Boolean(errors.bankCountry)}
					errorText={errors.bankCountry}
				/>
				<CustomSelectInputSearch
					options={bankListModified}
					title={'Selecciona un banco'}
					handleChange={handleBankChange}
					value={bankData.bankName}
					isDisabled={Boolean(bankData.bankCountry === '')}
					error={Boolean(errors.bankName)}
					errorText={errors.bankName}
				/>
				<CustomInput
					name='accountNumber'
					type='text'
					inputHintText='Número de cuenta'
					value={bankData.accountNumber}
					onChange={handleChange}
					inputSize='small'
					isDisabled={Boolean(bankData.bankCountry === '')}
					error={Boolean(errors.accountNumber)}
					errorText={errors.accountNumber}
					marginTop={'5px'}
				/>
				<CustomSelectInputSearch
					options={accountTypesModified}
					title={'Tipo de cuenta'}
					handleChange={handleAccountTypeChange}
					value={bankData.typeAccountText}
					isDisabled={Boolean(bankData.bankCountry === '')}
					error={Boolean(errors.typeAccountText)}
					errorText={errors.typeAccountText}
				/>
				<Box sx={{ height: 40 }} />
			</Wrapper>
			<StepperIndicator
				activeStep={activeStep}
				totalSteps={totalSteps}
				bottom={72}
			/>
		</>
	);
};

AddBankAccountStepOne.propTypes = {
	accountTypes: PropTypes.array.isRequired,
	activeStep: PropTypes.number.isRequired,
	bankData: PropTypes.object.isRequired,
	bankList: PropTypes.array.isRequired,
	errors: PropTypes.object.isRequired,
	setBankData: PropTypes.func.isRequired,
	totalSteps: PropTypes.array.isRequired,
};

export default AddBankAccountStepOne;
