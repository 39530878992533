import PropTypes from 'prop-types';

const WithdrawValidation = (
	maxWithdraw,
	minWithdraw,
	currentBalance,
	values = {}
) => {
	let errors = {};

	// Amount validation
	if (values.amount === '') {
		errors.amount = 'El monto es requerido';
	} else {
		const amountFloat = parseFloat(values.amount);
		const balanceFloat = parseFloat(currentBalance);

		if (isNaN(amountFloat)) {
			errors.amount = 'El monto debe ser un número válido';
		} else if (amountFloat < minWithdraw) {
			errors.amount = `El monto mínimo es de $ ${minWithdraw.toFixed(2)}`;
		} else if (amountFloat > maxWithdraw) {
			errors.amount = `El monto máximo es de $ ${maxWithdraw.toFixed(2)}`;
		} else if (amountFloat > balanceFloat) {
			errors.amount = `Tu saldo actual ($ ${balanceFloat.toFixed(
				2
			)}) no es suficiente`;
		} else {
			const decimalCount = (values.amount.split('.')[1] || '').length;
			if (decimalCount > 2) {
				errors.amount = 'El monto debe tener hasta dos decimales';
			}
		}
	}

	// Message validation
	if (values.message === '') {
		errors.message = 'El mensaje es requerido';
	} else if (values.message.length < 5) {
		errors.message = 'El mensaje debe tener mínimo 10 caracteres';
	} else if (values.message.length > 100) {
		errors.message = 'El mensaje debe tener máximo 100 caracteres';
	}

	// Bank account validation
	if (values.bankAccountSelected === '') {
		errors.bankAccountSelected = 'La cuenta bancaria es requerida';
	}

	return errors;
};

WithdrawValidation.propTypes = {
	values: PropTypes.shape({
		amount: PropTypes.string.isRequired,
		bankAccountSelected: PropTypes.string.isRequired,
	}).isRequired,
};

export default WithdrawValidation;
