import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Box } from '@mui/material';
import Image from 'mui-image';

// Styled Components imports
// import styled from 'styled-components';

// Styled Components

const Logo = (props) => {
	// Props destructuring
	const { logoHeight, marginBottom, marginTop, vertical } = props;

	return (
		<Box sx={{ marginTop, marginBottom }}>
			<Image
				src={
					vertical
						? '/img/logo-utransfer-sin-fondo.png'
						: '/img/logo-utransfer-horizontal-sin-fondo.png'
				}
				height={logoHeight}
				width='100%'
				fit='contain'
				duration={500}
				showLoading={true}
				errorIcon={true}
			/>
		</Box>
	);
};

Logo.defaultProps = {
	logoHeight: '100%',
	marginTop: '0rem',
	marginBottom: '0rem',
	vertical: false,
};

Logo.propTypes = {
	logoHeight: PropTypes.string,
	marginTop: PropTypes.string,
	marginBottom: PropTypes.string,
	vertical: PropTypes.bool,
};

export default Logo;
