import React from 'react';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Components Imports
import Logo from '../shared/Logo';

// Styled Components
const Wrapper = styled(Box)({
	width: '100vw',
	height: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const SplashScreen = () => {
	return (
		<Wrapper>
			<Logo logoHeight='70px' />
		</Wrapper>
	);
};

export default SplashScreen;
