const initState = {
	cardsList: [],
	loading: false,
};

const userCardsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'USER_CARDS_TRANSACTION_START':
			return {
				...state,
				loading: true,
			};
		case 'GET_USER_CARDS_SUCCESS':
			return {
				...state,
				cardsList: action.payload,
				loading: false,
			};
		case 'GET_USER_CARDS_ERROR':
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default userCardsReducer;
