import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux imports
import { connect } from 'react-redux';

// MUI Imports
import { Box } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';
import AddBankAccountStepOne from './Components/AddBankAccountStepOne';
import AddBankAccountStepTwo from './Components/AddBankAccountStepTwo';
import CustomDialog from '../../../../../layout/CustomDialog';
import TwoBtnsGrid from '../../../../../shared/TwoBtnsGrid';
import AddBankAccountValidation from './Components/AddBankAccountValidation';

// Redux actions imports
import {
	addBankAccount,
	getAccountTypes,
	getBanksList,
	getCountries,
	getUserAccounts,
	showHideAddAccountForm,
} from '../../../../../store/actions/bankAccountsActions';
import {
	backToPreviousInnerRoute,
	changeInnerRoute,
} from '../../../../../store/actions/innerRouterActions';
import AddBankAccountStripe from './Components/AddBankAccountStripe';
import {
	addNewExternalAccount,
	addStripeBankAccount,
	getStripeUserAccounts,
} from '../../../../../store/actions/stripeActions';
import moment from 'moment/moment';

// Styled Components
const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
	minHeight: '12rem',
});

const AddBankAccount = (props) => {
	// Props destructuring
	const {
		accountTypes,
		addBankAccount,
		addNewExternalAccount,
		addStripeBankAccount,
		backToPreviousInnerRoute,
		changeInnerRoute,
		banksList,
		countries,
		getAccountTypes,
		getUserAccounts,
		getStripeUserAccounts,
		getBanksList,
		getCountries,
		innerRoute,
		isBankAccountAdded,
		showAddAccountForm,
		showHideAddAccountForm,
		userProfile,
	} = props;

	const [activeStep, setActiveStep] = useState(0);
	const [bankData, setBankData] = useState({
		accountNumber: '',
		bankCountry: '',
		bankCountryCode: '',
		bankId: '',
		bankName: '',
		typeAccount: '',
		typeAccountText: '',
	});

	const [errors, setErrors] = useState({});

	const actualRoute = innerRoute[innerRoute.length - 1];

	const totalSteps = [0, 1];

	// Component hooks
	useEffect(() => {
		if (isBankAccountAdded) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [isBankAccountAdded]);

	useEffect(() => {
		if (
			bankData.bankCountryCode !== undefined &&
			bankData.bankCountryCode !== ''
		) {
			getAccountTypes();
			getCountries();

			getBanksList(bankData.bankCountryCode); //
		}
	}, [bankData.bankCountryCode, getAccountTypes, getBanksList, getCountries]);

	const withdrawSteps = [
		{
			title: 'Agregar cuenta',
			content: (
				<Wrapper>
					{/* <AddBankAccountStripe
						activeStep={activeStep}
						accountTypes={accountTypes}
						countries={countries}
						bankData={bankData}
						errors={errors}
						setBankData={setBankData}
						totalSteps={totalSteps}
					/> */}
					<AddBankAccountStepOne
						accountTypes={accountTypes}
						activeStep={activeStep}
						bankData={bankData}
						bankList={banksList}
						setBankData={setBankData}
						totalSteps={totalSteps}
						errors={errors}
					/>
				</Wrapper>
			),
		},
		{
			title: 'Agregar cuenta',
			content: (
				<Wrapper>
					<AddBankAccountStepTwo
						activeStep={activeStep}
						totalSteps={totalSteps}
					/>
				</Wrapper>
			),
		},
	];

	// Component functions
	const resetState = () => {
		setBankData({
			accountNumber: '',
			bankCountry: '',
			bankCountryCode: '',
			bankId: '',
			bankName: '',
			typeAccount: '',
			typeAccountText: '',
		});
		setErrors({});
		setActiveStep(0);
	};

	function handleClose() {
		showHideAddAccountForm();
		resetState();
		changeInnerRoute('bank-accounts');

		if (activeStep !== 0) {
			getUserAccounts();
			// getStripeUserAccounts(userProfile.accountId);
		}

		// if (actualRoute !== 'bank-accounts') {
		// 	backToPreviousInnerRoute();
		// }
	}

	const handleNext = () => {
		if (activeStep === 0) {
			if (Object.keys(AddBankAccountValidation(bankData)).length === 0) {
				setErrors({});

				const data = {
					accountNumber: bankData.accountNumber,
					bankId: bankData.bankId,
					typeAccount: bankData.typeAccount,
					country: bankData.bankCountryCode,
				};

				console.log(bankData);
				addBankAccount(data);

				// if (!userProfile.accountId) {
				// 	const addressCountry = countries.find(
				// 		(country) => country.name === userProfile.residenceCountry
				// 	).iso2;
				// 	const date = new Date(userProfile.birthDate * 1000);

				// 	const dob = moment(date).format('YYYY-MM-DD').split('-');

				// 	const data = {
				// 		accountCountry: bankData.bankCountryCode,
				// 		accountNumber: bankData.accountNumber,
				// 		bankId: bankData.bankCountryId,
				// 		addressCity: userProfile.shippingCity,
				// 		addressCountry: addressCountry,
				// 		addressLine1: userProfile.shippingAddress,
				// 		addressPostalCode: userProfile.shippingZIP,
				// 		addressState: userProfile.shippingState,
				// 		currency: 'usd',
				// 		yearOfBirth: dob[0],
				// 		monthOfBirth: dob[1],
				// 		dayOfBirth: dob[2],
				// 		documentId: userProfile.documentId,
				// 		email: userProfile.email,
				// 		firstName: userProfile.firstName,
				// 		lastName: userProfile.lastName,
				// 		routingNumber: bankData.routingNumber,
				// 	};

				// 	addStripeBankAccount(data);
				// } else {
				// 	const data = {
				// 		accountHolderName: `${userProfile.firstName} ${userProfile.lastName}`,
				// 		accountId: userProfile.accountId,
				// 		accountCountry: bankData.bankCountryCode,
				// 		accountNumber: bankData.accountNumber,
				// 		currency: 'usd',
				// 		routingNumber: bankData.routingNumber,
				// 	};

				// 	addNewExternalAccount(data);
				// }

				// if (isBankAccountAdded) {
				// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
				// }

				return;
			}

			setErrors(AddBankAccountValidation(bankData));
		} else if (activeStep === 1) {
			handleClose();
		}
	};

	const handleBack = () => {
		activeStep !== 0
			? setActiveStep((prevActiveStep) => prevActiveStep - 1)
			: handleClose();
	};

	const content = withdrawSteps[activeStep];

	return (
		<CustomDialog
			openDialog={showAddAccountForm}
			content={content}
			minHeight={'10rem'}
			onClose={handleClose}
			buttons={
				<TwoBtnsGrid
					leftBtnText={'Cancelar'}
					isLeftBtnDisabled={activeStep === 0 ? false : true}
					leftLoadingText={'Cancelar'}
					leftBtnAction={handleBack}
					rightBtnText={
						activeStep === 0 ? 'Siguiente' : activeStep === 1 && 'Aceptar'
					}
					rightBtnAction={handleNext}
					rightBtnType={'button'}
					leftBtnType={'button'}
					paddingY={'0.5rem'}
					btnsFullWidth={'true'}
				/>
			}
		/>
	);
};

AddBankAccount.propTypes = {
	accountTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
	addBankAccount: PropTypes.func.isRequired,
	addNewExternalAccount: PropTypes.func.isRequired,
	backToPreviousInnerRoute: PropTypes.func.isRequired,
	banksList: PropTypes.arrayOf(PropTypes.object).isRequired,
	changeInnerRoute: PropTypes.func.isRequired,
	getCountries: PropTypes.func.isRequired,
	getAccountTypes: PropTypes.func.isRequired,
	getBanksList: PropTypes.func.isRequired,
	getUserAccounts: PropTypes.func.isRequired,
	getStripeUserAccounts: PropTypes.func.isRequired,
	innerRoute: PropTypes.array.isRequired,
	isBankAccountAdded: PropTypes.bool.isRequired,
	showHideAddAccountForm: PropTypes.func.isRequired,
	showAddAccountForm: PropTypes.bool.isRequired,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const {
		accountTypes,
		banksList,
		countries,
		isBankAccountAdded,
		showAddAccountForm,
	} = state.bankAccounts;
	const { innerRoute } = state.innerRouter;
	const { userProfile } = state.user;

	return {
		accountTypes,
		banksList,
		countries,
		innerRoute,
		isBankAccountAdded,
		showAddAccountForm,
		userProfile,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		addBankAccount: (bankData) => dispatch(addBankAccount(bankData)),
		addNewExternalAccount: (data) => dispatch(addNewExternalAccount(data)),
		backToPreviousInnerRoute: () => dispatch(backToPreviousInnerRoute()),
		changeInnerRoute: (data) => dispatch(changeInnerRoute(data)),
		getAccountTypes: () => dispatch(getAccountTypes()),
		getBanksList: (country) => dispatch(getBanksList(country)),
		getCountries: () => dispatch(getCountries()),
		getUserAccounts: () => dispatch(getUserAccounts()),
		getStripeUserAccounts: (accountId) =>
			dispatch(getStripeUserAccounts(accountId)),
		showHideAddAccountForm: () => dispatch(showHideAddAccountForm()),
		addStripeBankAccount: (data) => dispatch(addStripeBankAccount(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount);
