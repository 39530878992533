import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Redux Imports
import { connect } from 'react-redux';

// MUI imports
import {
	AppBar,
	Box,
	Container,
	Grid,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material';

// SafeArea Capacitor Plugin
import { SafeArea } from 'capacitor-plugin-safe-area';

// Device Detect
import { isIOS } from 'react-device-detect';

// Styled Components imports
import styled from 'styled-components';

// Redux Actions Imports
import { backToPreviousInnerRoute } from '../store/actions/innerRouterActions';

import CustomIcon from '../shared/CustomIcon';
import Logo from '../shared/Logo';

// Styled Components
const TopBar = styled(AppBar)({
	height: (props) =>
		isIOS
			? `calc(56px + ${props.topsafearea}px) !important`
			: '56px !important',
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: '#fff !important',
	paddingTop: (props) =>
		isIOS ? `${props.topsafearea}px !important` : '0px !important',
});

const PageTitle = styled(Typography)({
	fontSize: '1.3rem !important',
	fontWeight: '400 !important',
});

const BackArrowPlaceholder = styled(Box)({
	width: '20.797',
	height: '20.797',
	padding: '0.5rem',
});

const MenuBar = (props) => {
	// Props destructuring
	const { backToPreviousInnerRoute, innerRoute } = props;

	// Component State
	const [safeArea, setSafeArea] = useState({});

	// Component Hooks
	const navigate = useNavigate();

	useEffect(() => {
		SafeArea.getSafeAreaInsets().then(({ insets }) => {
			setSafeArea(insets);
		});
	}, []);

	// Component Functions
	const backHistory = () => {
		if (innerRoute.length === 0) {
			return navigate('/bienvenida');
		}

		backToPreviousInnerRoute();
		setTimeout(() => {
			document.documentElement.scrollTo(0, 0);
		}, 150);
	};

	return (
		<TopBar position='fixed' topsafearea={safeArea?.top}>
			<Container maxWidth='lg'>
				<Toolbar variant='dense' sx={{ px: 0 }}>
					<Grid container spacing={2} justifyContent='space-between'>
						<Grid
							item
							xs={2}
							alignItems='center'
							justifyContent='center'
							display='flex'
							style={{ paddingLeft: 0 }}
							className='menu-icon-grid'
						>
							<section
								style={{
									marginLeft: '1rem',
								}}
							>
								{innerRoute.length === 1 ? (
									<BackArrowPlaceholder />
								) : (
									<IconButton
										edge='start'
										aria-label='back'
										sx={{ color: 'var(--secondary)' }}
										onClick={backHistory}
									>
										<CustomIcon iconName='FaChevronLeft' iconSize='1.3rem' />
									</IconButton>
								)}
							</section>
						</Grid>
						<Grid
							item
							xs={8}
							alignItems='center'
							justifyContent='center'
							display='flex'
							md='auto'
						>
							<PageTitle variant='h1' component='h1'>
								<Logo logoHeight='50px' />
							</PageTitle>
						</Grid>
						<Grid
							item
							xs={2}
							alignItems='center'
							justifyContent='center'
							display='flex'
							style={{ paddingLeft: 0 }}
							className='menu-icon-grid'
						>
							<section
								style={{
									marginLeft: '2rem',
								}}
							>
								<IconButton
									edge='start'
									aria-label='menu'
									style={{ color: 'var(--primary)' }}
									onClick={() => {}}
								>
									{/* <CustomIcon iconName='faEllipsisV' iconSize='1.3rem' /> */}
								</IconButton>
							</section>
						</Grid>
					</Grid>
				</Toolbar>
			</Container>
		</TopBar>
	);
};

MenuBar.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	innerRoute: PropTypes.arrayOf(PropTypes.string),
	backToPreviousInnerRoute: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { innerRoute } = state.innerRouter;

	return {
		innerRoute,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		backToPreviousInnerRoute: () => dispatch(backToPreviousInnerRoute()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
