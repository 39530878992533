import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux imports
import { connect } from 'react-redux';

// MUI Imports
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
} from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local components
import Loading from '../../../../../../shared/Loading';
import CustomIcon from '../../../../../../shared/CustomIcon';

// Redux actions imports
import { getStripeUserCards } from '../../../../../../store/actions/stripeActions';
import CustomBtn from '../../../../../../layout/CustomBtn';
import { changeInnerRoute } from '../../../../../../store/actions/innerRouterActions';
import { getUserCards } from '../../../../../../store/actions/userCardsActions';

// Styled Components
const CustomList = styled(List)({
	margin: '0.5rem 0 !important',
	border: '1px solid #D9D9D9',
	borderRadius: '0.7rem',
	padding: '0 !important',
});

const NoCardsListItem = styled(ListItem)({
	padding: '1rem',
	textAlign: 'center',
});

const BtnWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	margin: '1rem 0',
});

const CustomListItemButton = styled(ListItemButton)((props) => ({
	padding: '0.5rem !important',
	borderTopLeftRadius: props.$position === 'top' && '0.6rem !important',
	borderTopRightRadius: props.$position === 'top' && '0.6rem !important',
	borderBottomLeftRadius: props.$position === 'bottom' && '0.6rem !important',
	borderBottomRightRadius: props.$position === 'bottom' && '0.6rem !important',

	'@media (min-width: 768px)': {
		padding: '0.25rem 0.5rem !important',
	},
}));

const UserCards = (props) => {
	// Props destructuring
	const {
		cardsList,
		changeInnerRoute,
		closeModal,
		loading,
		getStripeUserCards,
		getUserCards,
		isEdit,
		isPayment,
		setSavedCardDetails,
		updateCards,
		userProfile,
	} = props;

	// Component State
	const [selectedCardIndex, setSelectedCardIndex] = useState(null);

	// Component hooks
	// useEffect(() => {
	// 	getUserCards();
	// }, [getUserCards]);

	useEffect(() => {
		const customerID = userProfile?.customerId || '';
		if (customerID !== '') {
			getStripeUserCards(customerID);
		}
	}, [getStripeUserCards, userProfile]);

	useEffect(() => {
		if (updateCards) {
			getStripeUserCards(userProfile?.customerId);
		}
	}, [updateCards, getStripeUserCards, userProfile]);

	console.log(cardsList);

	// Component functions
	const setCardIcon = (cardBrand) => {
		if (cardBrand === 'visa') return 'FaCcVisa';

		if (cardBrand === 'mastercard') return 'FaCcMastercard';

		if (cardBrand === 'discover') return 'FaCcDiscover';

		if (cardBrand === 'amex') return 'FaCcAmex';

		if (cardBrand === 'diners') return 'FaCcDinersClub';

		if (cardBrand === 'jcb') return 'FaCcJcb';

		return 'FaCreditCard';
	};

	const addCardBtnAction = () => {
		closeModal();

		setTimeout(() => {
			changeInnerRoute('my-cards');
		}, 250);
	};

	const selectCardActions = (index, cardDetails) => {
		if (isPayment) {
			setSelectedCardIndex(index === selectedCardIndex ? null : index);
			setSavedCardDetails(cardDetails);
		}
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : cardsList.length === 0 ? (
				<>
					<CustomList>
						<Box as='li'>
							<NoCardsListItem as='div'>
								No tienes tarjetas registradas aún.
							</NoCardsListItem>
						</Box>
					</CustomList>
					{isPayment && (
						<BtnWrapper>
							<CustomBtn
								btnText='Agregar tarjeta'
								btnColor='var(--secondary)'
								btnType='button'
								width={'200px'}
								startIcon='faAdd'
								onClick={addCardBtnAction}
								btnHoverColor={'var(--secondary)'}
							/>
						</BtnWrapper>
					)}
				</>
			) : (
				<>
					<CustomList>
						{cardsList.map((card, key) => {
							return (
								<Box as='li' key={key}>
									<ListItem
										disablePadding
										as='div'
										secondaryAction={
											selectedCardIndex !== null &&
											selectedCardIndex === key ? (
												<IconButton
													edge='end'
													aria-label='ir-a-opcion'
													sx={{
														right: '0.2rem',
														width: '2rem',
														height: '2rem',
													}}
													onClick={() => selectCardActions(key)}
												>
													<CustomIcon
														iconName={isEdit ? 'faEdit' : 'faSquareCheck'}
														iconColor='var(--primary)'
													/>
												</IconButton>
											) : null
										}
									>
										<CustomListItemButton
											$position={
												key === 0
													? 'top'
													: key === cardsList.length - 1
													? 'bottom'
													: ''
											}
											selected={
												selectedCardIndex !== null && selectedCardIndex === key
											}
											onClick={() => selectCardActions(key, card)}
										>
											<ListItemAvatar>
												<Avatar
													sx={{
														bgcolor: 'transparent !important',
														minWidth: '45px !important',
													}}
												>
													<CustomIcon
														iconName={setCardIcon(card?.card?.brand)}
														iconColor='#333'
														iconSize={'1.75rem'}
													/>
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={`XXXX XXXX XXXX ${card?.card?.last4}`}
											/>
										</CustomListItemButton>
									</ListItem>
									{key === cardsList.length - 1 ? null : (
										<Divider variant='middle' />
									)}
								</Box>
							);
						})}
					</CustomList>
					{isPayment && (
						<BtnWrapper>
							<CustomBtn
								btnText='Agregar tarjeta'
								btnColor='var(--secondary)'
								btnType='button'
								width={'200px'}
								startIcon='faAdd'
								onClick={addCardBtnAction}
								btnHoverColor={'var(--secondary)'}
							/>
						</BtnWrapper>
					)}
				</>
			)}
		</>
	);
};

UserCards.propTypes = {
	cardsList: PropTypes.array.isRequired,
	changeInnerRoute: PropTypes.func.isRequired,
	closeModal: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	getStripeUserCards: PropTypes.func.isRequired,
	getUserCards: PropTypes.func.isRequired,
	isEdit: PropTypes.bool,
	isPayment: PropTypes.bool,
	setSavedCardDetails: PropTypes.func,
	userProfile: PropTypes.object.isRequired,
	updateCards: PropTypes.bool,
};

UserCards.defaultProps = {};

const mapStateToProps = (state) => {
	const { cardsList, loading } = state.stripe;
	const { userProfile } = state.user;
	// const { cardsList, loading } = state.userCards;

	return {
		cardsList,
		loading,
		userProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeInnerRoute: (route) => dispatch(changeInnerRoute(route)),
		getStripeUserCards: (customerId) =>
			dispatch(getStripeUserCards(customerId)),
		getUserCards: () => dispatch(getUserCards()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCards);
