import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Box, CircularProgress, Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Redux Actions Imports
import { getUserBalance } from '../store/actions/transactionsActions';

// Styled Components
const BalanceWrapper = styled(Box)({
	minWidth: '7rem !important',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: '0.5rem',
	padding: '0.25rem 1.5rem',
	borderRadius: '0.8rem ',
	backgroundColor: '#fff !important',
	border: '1px solid var(--secondary)',
});

const ActualBalance = styled(Typography)({
	fontSize: '24px !important',
	fontWeight: '500 !important',
	color: 'var(--primary) !important',
});

const BalanceCaption = styled(Typography)({
	fontSize: '12px !important',
});

const Balance = (props) => {
	// Props destructuring
	const { balance, getUserBalance, loadingBalance, reloadUserBalance } = props;

	// Component hooks
	useEffect(() => {
		getUserBalance();
	}, [getUserBalance]);

	useEffect(() => {
		if (reloadUserBalance) {
			getUserBalance();
		}
	}, [reloadUserBalance, getUserBalance]);

	return (
		<BalanceWrapper>
			{loadingBalance ? (
				<CircularProgress size={29} />
			) : (
				<ActualBalance variant='h4'>
					$ {balance.toFixed(2) || '0.00'}
				</ActualBalance>
			)}
			<BalanceCaption variant='body1'>Tu saldo</BalanceCaption>
		</BalanceWrapper>
	);
};

Balance.propTypes = {
	loadingBalance: PropTypes.bool.isRequired,
	reloadUserBalance: PropTypes.bool.isRequired,
	balance: PropTypes.number,
};

const mapStateToProps = (state) => {
	const { balance, loadingBalance, reloadUserBalance } = state.transactions;

	return {
		balance,
		loadingBalance,
		reloadUserBalance,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserBalance: () => dispatch(getUserBalance()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
